import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";
import {
  getDateFromQuarterAndYear,
  getYearQuarterFromDate,
} from "../utils/DateConverter";
import {
  CustomMetricQueryResponse,
  CustomMetricsProjectType,
  CustomMetricValuesType,
} from "../../../component/CustomMetricDropdown/CustomMetricDropdown";

export const CustomMetricsQuery = createApi({
  reducerPath: "CustomMetricsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCustomMetrics: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: CustomMetricQueryResponse[]) =>
        response.map((item: CustomMetricQueryResponse) => ({
          ...item,
          values: item.values.map((i: CustomMetricValuesType, index: any) => {
            let { year, quarter } = getYearQuarterFromDate(i.timeFrame);
            return {
              ...i,
              id: i.id,
              timeline: {
                year,
                quarter,
              },
              aggregate: {
                overallAggregateValue: i.overallAggregateValue,
                projectMetricValues: i.projectMetricValues,
              },
            };
          }),
        })),
    }),
    addCustomMetricOnly: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}`,
        method: "POST",
        body: getAddCustomMetricsBody(customMetricsData),
      }),
    }),
    updateCustomMetricOnly: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}`,
        method: "POST",
        body: getUpdateCustomMetricsBody(customMetricsData),
      }),
    }),
    addCustomMetrics: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}/${customMetricsData.metric.id}`,
        method: "PUT",
        body: getCustomMetricsBody(customMetricsData, true),
      }),
    }),
    updateCustomMetrics: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}/${customMetricsData.metric.id}`,
        method: "PUT",
        body: getCustomMetricsBody(customMetricsData, false),
      }),
    }),
    removeCustomMetrics: builder.mutation({
      query: (customMetricsData) => ({
        url: `/admin/net-benefits/custom-metrics/tenants/${customMetricsData.tenantId}/${customMetricsData.metric.id}`,
        method: "PUT",
        body: getCustomMetricsRemoveBody(customMetricsData),
      }),
    }),
  }),
});

export const {
  useGetCustomMetricsQuery,
  useAddCustomMetricOnlyMutation,
  useUpdateCustomMetricOnlyMutation,
  useAddCustomMetricsMutation,
  useUpdateCustomMetricsMutation,
  useRemoveCustomMetricsMutation,
} = CustomMetricsQuery;

const getCustomMetricsBody = (customMetricsData: any, isFromAdd: boolean) => {
  let data = customMetricsData.previousData.filter(
    (item: CustomMetricQueryResponse) => item.id === customMetricsData.metric.id
  );
  data = data[0];

  let updatedData: any;
  if (isFromAdd) {
    //For add
    if (data) {
      updatedData = {
        ...data,
        values: [
          ...data?.values,
          {
            id: null,
            timeFrame: getDateFromQuarterAndYear(
              customMetricsData.timeline.year,
              customMetricsData.timeline.quarter
            ),
            overallAggregateValue:
              customMetricsData.aggregate.overallAggregateValue,
            projectMetricValues:
              customMetricsData.aggregate?.projectMetricValues,
          },
        ],
      };
    } else {
      updatedData = {
        ...customMetricsData.metric,
        values: [
          {
            id: null,
            timeFrame: getDateFromQuarterAndYear(
              customMetricsData.timeline.year,
              customMetricsData.timeline.quarter
            ),
            overallAggregateValue:
              customMetricsData.aggregate.overallAggregateValue,
            projectMetricValues:
              customMetricsData.aggregate?.projectMetricValues,
          },
        ],
      };
    }
  } else {
    //For Update
    let updatedValues = data?.values?.map((item: CustomMetricValuesType) =>
      item.id === customMetricsData.id
        ? {
            ...item,
            timeFrame: getDateFromQuarterAndYear(
              customMetricsData.timeline.year,
              customMetricsData.timeline.quarter
            ),
            overallAggregateValue:
              customMetricsData.aggregate.overallAggregateValue,
            projectMetricValues:
              customMetricsData.aggregate?.projectMetricValues,
          }
        : item
    );

    updatedData = {
      ...data,
      values: updatedValues,
    };
  }

  // Replace the old data in previousData with the updated data
  customMetricsData.previousData = customMetricsData?.previousData?.map(
    (item: any) =>
      item.id === customMetricsData.metric.id
        ? {
            ...updatedData,
            // Ensure only timeFrame and aggregate properties are included in the values array
            values: updatedData.values.map((v: any) => ({
              id: v.id,
              timeFrame: v.timeFrame,
              overallAggregateValue: v.overallAggregateValue,
              projectMetricValues: v.projectMetricValues,
            })),
          }
        : item
  );

  // below code forms payload passed to api
  // Ensure only timeFrame and aggregate properties are included in the values array
  updatedData = {
    ...updatedData,
    values: updatedData?.values?.map((v: any) => ({
      id: v.id,
      timeFrame: v.timeFrame,
      overallAggregateValue: v.overallAggregateValue,
      projectMetricValues: getProjectMetricsWithoutProjectName(
        v.projectMetricValues
      ),
    })),
  };

  return updatedData;
};

const getCustomMetricsRemoveBody = (customMetricsData: any) => {
  let data = customMetricsData?.previousData?.filter(
    (item: any) => item.id === customMetricsData?.metric?.id
  );
  data = data[0];
  let updatedValues = data?.values?.filter(
    (item: any) => item.id !== customMetricsData?.id
  );

  let updatedData = {
    ...data,
    values: updatedValues,
  };

  return updatedData;
};

const getAddCustomMetricsBody = (customMetricsData: any) => {
  return customMetricsData?.metric;
};

const getUpdateCustomMetricsBody = (customMetricsData: any) => {
  let data = customMetricsData?.previousData?.filter(
    (item: any) => item.id === customMetricsData?.metric?.id
  );
  data = data[0];
  let updatedData = {
    ...data,
    active: customMetricsData?.metric?.active,
  };

  return updatedData;
};

const getProjectMetricsWithoutProjectName = (
  projectMetrics: CustomMetricsProjectType[]
) => {
  return projectMetrics?.map((item) => {
    const { projectName, ...restValue } = item;
    return restValue;
  });
};
