import React from 'react';
import "../../styles/page/Signals/Content.scss";
import Loader from '../../component/Loader/Loader';
import Text from '../../component/Text/Text';
import { literals } from '../../theme/literals';
import { colors } from '../../theme/colors';
import SimpleLineChart from '../../component/LineChart/SimpleLineChart';
import InterceptResponseRatesChart from './InterceptResponseRatesChart';
import CustomAgGridCRUD from '../../component/CustomAgGridCRUD/CustomAgGridCRUD';
import { contentInNeedGridColumn, contentMostSearcedGridColumn, contentMostSearcedGridField, useContentGridProp } from './gridConfig';
import { useGetContentQuery } from './SignalsQuery';
import { contentEffectivenessGridField } from '../ClientDetails/TenantDetails/gridConfig';
import { useGetContentCSATGraphQuery, useGetInterceptResponseRateGraphQuery } from './SignalsRestQuery';
const contentEffectiveTempData = [
  {
    content: "Baker Type Packer Elements: Data Sheet",
    score: "1.1",
    comments: "Outdated, missing data",
    id: 1,
  },
  {
    content: "Swab Cups Data Sheet",
    score: "1.01",
    comments: "Missing key data, sizing",
    id: 2,
  },
  {
    content: "Reactor System Cleaning",
    score: "2.1",
    comments: "Missing MSDS",
    id: 3,
  },
  {
    content: "KO Drum Field Service Manual",
    score: "2.1",
    comments: "Incorrect revision number",
    id: 4,
  },
  {
    content: "Drain Headers",
    score: "2.2",
    comments: "Incomplete product list",
    id: 5,
  },
  {
    content: "DomBor Valve Spec Sheet",
    score: "2.3",
    comments: "Missing data, PSI rating, duty cycles",
    id: 6,
  },
  {
    content: "DomBor Valve Product Catalog",
    score: "2.3",
    comments: "Outdated - 2022 version",
    id: 7,
  },
  {
    content: "Knife Gate Valve Dimensions",
    score: "2.4",
    comments: "Incorrect revision number 2.3 vs 3.0",
    id: 8,
  },
  {
    content: "Quincy Compressor Service Manual",
    score: "2.5",
    comments: "2023 version",
    id: 9,
  },
  {
    content: "Quincy Compressor Service Manual",
    score: "2.6",
    comments: "Maintenance lockout tagout procedure incorrect",
    id: 10,
  },
];

const mostCommonSearchesTempData = [
  {
    content: "Drain Header spare parts diagram",
    score: "4.2",
    id: 100,
  },
  {
    content: "Keystone Pipe Handling Tools price quote",
    score: "3.5",
    id: 101,
  },
  {
    content: "Safety Clamp field installation guide",
    score: "4.1",
    id: 102,
  },
  {
    content: "Tong Block load capacity",
    score: "4.9",
    id: 103,
  },
  {
    content: "Pipe jack load rating",
    score: "3.6",
    id: 104,
  },
  {
    content: "Tong Safely Handles dimensions",
    score: "4.8",
    id: 105,
  },
  {
    content: "Tong Die Drivers Installation",
    score: "3.9",
    id: 106,
  },
  {
    content: "Cementing and Drilling fluids MSDS",
    score: "3.1",
    id: 107,
  },
  {
    content: "Fluid loss additives MSDS",
    score: "3.5",
    id: 108,
  },
  {
    content: "Fluid Suspension System Pilot program",
    score: "4.2",
    id: 109,
  },
];
const mapGraphData = (data: any) => ({
  value: data.averageValue,
  category:`Q${data.quarter} ${data.year}`
});

const Content = () => {
  const { data: contentData, isLoading: isContentLoading } = useGetContentQuery();
  const { data: contentCsatData, isLoading: isContentGraphLoading } = useGetContentCSATGraphQuery(null);
  const { data: irrData, isLoading: isIrrGraphLoading } = useGetInterceptResponseRateGraphQuery(null);
  
  const contentInNeedGridProp = useContentGridProp({
    isLoading: isContentLoading,
    // data: contentData?.content?.contentEffectiveness,
    data: contentEffectiveTempData,
    column: contentInNeedGridColumn,
    idColumnField: contentEffectivenessGridField.id
  })
  const contentMostSearcedGridProp = useContentGridProp({
    isLoading: isContentLoading,
    data: mostCommonSearchesTempData,
    // data: contentData?.content?.mostCommonSearches,
    column: contentMostSearcedGridColumn,
    idColumnField: contentMostSearcedGridField.id
  })

  const contentCSATGraphData = contentCsatData?.map(mapGraphData) || [];
  const irrGraphData = irrData?.map(mapGraphData) || [];

  const isLoading = (
    isContentLoading ||
    isContentGraphLoading ||
    isIrrGraphLoading
  );
  return (
    <div>
    { isLoading ? (
      <Loader isLoading={isLoading} />
    ) : (
      <div className="transactional-container content-container">
        <div className="top-chart-container">
          <div className="csat-customer-container">
            <div className="left-section">
              <Text
                styleName="Heading7"
                text={literals.contentCsat}
                color={colors.black}
              />
              <div className="csat-graph-container">
                <SimpleLineChart
                  chartID="SimpleLineChart-one"
                  data={contentCSATGraphData}
                  benchmark={false}
                />
              </div>

              <Text
                styleName="Heading7 mt-1rem"
                text={literals.contentEffectiveness}
                color={colors.black}
              />

              <div className='mt-1rem'>
                <CustomAgGridCRUD {...contentInNeedGridProp} />
              </div>
            </div>

            <div className="right-section">
              
            <Text
                styleName="Heading7"
                text={literals.interceptResponseRates}
                color={colors.black}
            />
            <div className="csat-graph-container">
              <InterceptResponseRatesChart
                chartID="BarChart-one"
                data={irrGraphData}
                benchmark={false}
              />
            </div>

            <Text
                styleName="hidden mt-1rem"
                text={'a'}
                color={colors.black}
            />

            <div className='mt-1rem'>
              <CustomAgGridCRUD {...contentMostSearcedGridProp} />
            </div>

            </div>
            
          </div>
        </div>
      </div>
    )}
  </div>
  )
}

export default Content