import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MenuItem, menuItems } from "../../AppRoute/constants";
import Text from "../../component/Text/Text";
import { colors } from "../../theme/colors";
import BlackChevronDown from "../../assets/images/BlackChevronDown.svg";
import ActiveDotImg from "../../assets/images/ActiveDot.svg";
import { UserRole, UserRoleMenu } from "../../page/Admin/utils/globalType";
import "../../styles/feature/Sidebar/Sidebar.scss";
import { RoutePath } from "../../AppRoute/AppRoutePath";
import { literals } from "../../theme/literals";

const getUserRole = (role: UserRole | undefined): UserRole | undefined => {
  return UserRoleMenu[role as UserRole] as UserRole | undefined;
};

interface SidebarProps {
  collapsed: boolean;
  selectedMenu: string;
  setSelectedMenu: (menu: any) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  selectedMenu,
  setSelectedMenu,
}) => {
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const { loginInfo } = useSelector((state: RootState) => state?.auth);
  const location = useLocation();
  const role = getUserRole(loginInfo?.scope as UserRole) as UserRole;
  const toggleMenu = (label: string, isFromArrow: boolean = false) => {
    setOpenMenus((prev) => ({
      ...prev,
      [label]:
        !isFromArrow && label === literals.execution ? true : !prev[label],
    }));
  };

  const renderMenuItem = (item: MenuItem) => {
    const isActive = location.pathname === item.path;
    const isSubmenuActive = selectedMenu === item.path;
    const showActiveLogo = item.activeLogo && (isActive || isSubmenuActive);
    const logoToShow = showActiveLogo
      ? item.activeLogo
      : item.logo
      ? item.logo
      : null;

    return (
      <div
        key={item.label}
        className={`parent-item ${item?.subMenu ? "submenu-wrapper" : ""} ${
          isActive ? "item-active" : ""
        }`}
        onClick={() => {
          setSelectedMenu(item.path);
          if (item.path === RoutePath.tenantProjectReport) {
            toggleMenu(item.label);
          }
        }}
      >
        {item.subMenu ? (
          <>
            <div
              className={`submenu-as-main-div ${
                isSubmenuActive ? "subitem-active" : ""
              } ${!collapsed ? "sidebar-expand" : ""}`}
            >
              <div className="submenu-logo-text-div">
                <Link to={item?.path!}>
                  {collapsed ? (
                    logoToShow
                  ) : (
                    <div className="submenu-main-logo-label-wrapper">
                      {logoToShow}
                      <Text
                        text={item.label}
                        styleName="Heading7"
                        color={
                          isActive || isSubmenuActive
                            ? colors.blackHeading
                            : colors.grayTwo
                        }
                      />
                    </div>
                  )}
                </Link>
              </div>
              {!collapsed && (
                <div
                  className="submenu-dropdown-toggle"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(item.label, true);
                  }}
                >
                  <img src={BlackChevronDown} alt="dropdown" />
                </div>
              )}
            </div>
            {openMenus[item.label] && (
              <div className="submenu show">
                {item.subMenu.map((subItem) => renderSubMenu(subItem))}
              </div>
            )}
          </>
        ) : (
          <Link to={item?.path!} className={isActive ? "active" : ""}>
            {collapsed ? (
              logoToShow
            ) : (
              <div className="logo-label-wrapper">
                {logoToShow}
                <Text
                  text={item.label}
                  styleName="Heading7"
                  color={isActive ? colors.blackHeading : colors.grayTwo}
                />
              </div>
            )}
          </Link>
        )}
      </div>
    );
  };

  const renderSubMenu = (subItem: MenuItem) => {
    const isActive = location.pathname === subItem.path;
    return (
      <div
        key={subItem.path}
        onClick={() => setSelectedMenu(subItem.path)}
        className={`submenu-item-wrapper ${isActive ? "active" : ""}`}
      >
        <Link to={subItem?.path!}>
          <div className="submenu-item">
            {isActive && (
              <img src={ActiveDotImg} alt="circle" className="active" />
            )}
            <Text
              text={subItem.label}
              color={isActive ? colors.blackHeading : colors.grayTwo}
              styleName="Heading7 submenu-item-label"
            />
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      {menuItems[role].map(renderMenuItem)}
    </div>
  );
};

export default Sidebar;
