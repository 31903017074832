import React, { useMemo, useState } from "react";
import BubbleChart from "../../component/BubbleChart/BubbleChart";
import { getCsatTouchpoints } from "./utils/getCsatTouchpoints";
import SimpleLineChart from "../../component/LineChart/SimpleLineChart";
import { getFormattedCsatTrend } from "./utils/getFormattedCsatTrend";
import Text from "../../component/Text/Text";
import { literals } from "../../theme/literals";
import { colors } from "../../theme/colors";
import { useGetTransactionalQuery } from "./SignalsQuery";
import ProgressBar from "../../component/ProgressBar/ProgressBar";
import LineChart from "../../component/LineChart/LineChart";
import trendUp from "../../assets/images/trendUp.jpg";
import trendDown from "../../assets/images/trendDown.png";
import trendNeutral from "../../assets/images/neutral.png";
import Loader from "../../component/Loader/Loader";
import { getCommonWidgetInfo } from "./utils/getCommonWidgetInfo";
import { numberUpto2Decimal } from "../Home/utils/executionProjectUtil";
import FilterDropdown, { ScreenFilterDuration } from "../../component/FilterDropdown/FilterDropdown";
import ChevronRight from "../../assets/images/chevron-right.svg";
import { SelectDropdownOption } from "../Admin/utils/globalType";
import { canSkipQuery, getQueryDateRange, getScreenFilterQueryDate } from "../Version2/Insights/utils/gridUtility";
import { GenericCustomStartEndDate } from "../Admin/utils/globalUtil";
import EmptyStateComp from "../Version2/Home/EmptyStateComp";

const getTrendImage = (trend?: string) => {
  switch (trend) {
    case literals.up:
      return trendUp;
    case literals.down:
      return trendDown;
    default:
      return trendNeutral;
  }
};

const getTrendSign = (trend?: string) => {
  switch (trend) {
    case literals.up:
      return "+";
    case literals.down:
      return "-";
    default:
      return "+";
  }
};

const Transactional = () => {
  const [activeFilter, setActiveFilter] = useState<SelectDropdownOption>(ScreenFilterDuration[2]);
  const [customFilter, setCustomFilter] = useState<GenericCustomStartEndDate>({});
  const { startDate, endDate } = getScreenFilterQueryDate(activeFilter);
  const { data, isFetching } = useGetTransactionalQuery(getQueryDateRange(activeFilter, customFilter, startDate, endDate),
    {
      skip: canSkipQuery(activeFilter, customFilter),
    });

  const formattedCsatTouchpointsData = getCsatTouchpoints(
    data?.transactional?.csatTouchPoints
  );

  const formattedCsatTrendData = getFormattedCsatTrend(
    data?.transactional?.csatTrend
  );

  const showCsatTrendChart = useMemo(
    () =>
      formattedCsatTrendData?.length > 0,
    [formattedCsatTrendData]
  );

  const showCsatTouchpointChart = useMemo(
    () =>
      formattedCsatTouchpointsData?.length > 0,
    [formattedCsatTouchpointsData]
  );

  const {
    graphData: customerEffortScoreGraphData,
    averageScore,
    trend,
    changePercent,
  } = getCommonWidgetInfo(data?.transactional?.customerEffortScore as any);
  const averageScoreValue = numberUpto2Decimal(averageScore);

  const showCustomerEffortScoreChart = useMemo(
    () =>
      customerEffortScoreGraphData?.length > 0,
    [customerEffortScoreGraphData]
  );

  return (
    <div className="transactional-container">
      <div className="nps-csat-container">
        <div className="transactional-link-container">
          <Text
            color={colors.grayFour}
            text={literals.signals}
            styleName="body9 capitalize-first"
          />
          <>
            <img
              src={ChevronRight}
              alt="Arrow Right icon"
              className="arrow-img"
            />
            <Text
              color={colors.Light_Blue_PRI}
              text={literals.transactional}
              styleName="body9"
            />
          </>
        </div>
        <FilterDropdown activeFilter={activeFilter} setActiveFilter={setActiveFilter} setCustomFilter={setCustomFilter} />
      </div>
      {isFetching ? (
        <Loader isLoading={isFetching} />
      ) : <>
        <div className="top-chart-container">
          <div className="csat-customer-container">
            <div className="csat-left-container">
              <Text
                styleName="Heading7"
                text={literals.overallCsatTrend}
                color={colors.black}
              />
              <div className="csat-graph-container">
                {showCsatTrendChart ? <SimpleLineChart
                  chartID="bubble-chart-two"
                  data={formattedCsatTrendData}
                  benchmark={data?.transactional?.csatBenchmark}
                />
                  : <EmptyStateComp containerMinHeight="14rem" />}
              </div>
            </div>
            <div className="customer-effort-score-container">
              <div className="title-container">
                <Text
                  styleName="Heading7"
                  text={literals.customerEffortScore}
                  color={colors.black}
                />
              </div>
              <div className="customer-effort-score">
                {showCustomerEffortScoreChart ?
                  <>
                    <LineChart
                      chartID="line-chart-Four"
                      data={customerEffortScoreGraphData}
                    />
                    <div className="cef-container">
                      <Text
                        color={colors.black}
                        text={averageScoreValue}
                        styleName="Heading1"
                      />
                      <Text
                        color={colors.black}
                        text="/10"
                        styleName="Heading4"
                      />
                    </div>
                    <div className="trend-container-cef">
                      <img
                        src={getTrendImage(trend)}
                        alt="trend"
                        className="trend-img"
                      />
                      <Text
                        styleName="body2"
                        text={`(${getTrendSign(trend)}${changePercent.toFixed(2)}% ${literals.sinceLastMonth})`}
                        color={colors.graySix}
                      />
                    </div>
                    <div className="progress-container">
                      <ProgressBar progress={averageScore * 10} />
                    </div>
                  </>
                  : <EmptyStateComp containerMinHeight="12.5rem" />}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-chart-container">
          <Text
            styleName="Heading7"
            text={literals.csatTouchPoints}
            color={colors.black}
          />
          <div className="csat-touchpoints-container">
            {showCsatTouchpointChart
              ? <BubbleChart
                chartID="bubble-chart"
                data={formattedCsatTouchpointsData} />
              : <EmptyStateComp containerMinHeight="14rem" />}
          </div>
        </div>
      </>}
    </div>
  );
};

export default Transactional;
