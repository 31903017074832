import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";
import { ExecutionDetailsType } from "../ExecutionDetails/ExecutionDetails.type";

export const NetBenefitDetailsQuery = createApi({
  reducerPath: "NetBenefitDetailsQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getNBProjectDetails: builder.query({
      query: () => ({
        url: `/projects/group-by-status`,
        method: "GET",
      }),
      transformResponse: (response: Record<string, ExecutionDetailsType[]>) =>
        Object.values(response).flatMap((projects) => projects),
    }),
    getNBMetricsDetailsByProject: builder.query({
      query: (projectId) => ({
        url: `/net-benefits/metrics${
          projectId ? `?projectId=${projectId}` : ""
        }`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetNBProjectDetailsQuery,
  useLazyGetNBMetricsDetailsByProjectQuery,
} = NetBenefitDetailsQuery;
