import React from "react";
import { literals } from "../../../theme/literals";
import { colors } from "../../../theme/colors";
import EmptyStateDocIcon from "../../../assets/images/empty-state-doc-icon.svg";
import Text from "../../../component/Text/Text";

interface EmptyStateCompProps {
  containerMinHeight?: string;
  imgWidth?: string;
  imgHeight?: string;
}

const EmptyStateComp: React.FC<EmptyStateCompProps> = ({
  containerMinHeight,
  imgWidth = "138px",
  imgHeight = "110px",
}) => (
  <div
    className="no-data-msg-container"
    style={{ minHeight: containerMinHeight || "20rem" }}
  >
    <img
      src={EmptyStateDocIcon}
      alt="no_data"
      style={{ width: imgWidth, height: imgHeight }}
    />
    <Text
      text={literals.noDataAdded}
      styleName="body1"
      color={colors.grayFour}
    />
  </div>
);

export default EmptyStateComp;
