import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTokenMutation } from "./authApiSlice";
import Input from "../../component/Input/Input";
import VistaXMLogo from "../../assets/images/VistaXM_Logo.png";
import "../../styles/page/Login/Login.scss";
import Text from "../../component/Text/Text";
import { colors } from "../../theme/colors";
import { literals } from "../../theme/literals";
import { useDispatch } from "react-redux";
import { setLoginInfo, setToken } from "./authSlice";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { key } from "../../constants/storageKeys";
import { UserRole } from "../Admin/utils/globalType";
import { RoutePath } from "../../AppRoute/AppRoutePath";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [getToken, { isLoading, isSuccess, isError, data }] =
    useGetTokenMutation();
  const token = useSelector((state: RootState) => state.auth.token);
  let loginInfo = useSelector((state: RootState) => state.auth.loginInfo);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await getToken({ username: email, password });
  };

  const getNavigation = (scope: string) => {
    let route;
    if (scope === UserRole.ADMIN) route = "/TenantDashboard";
    else route = RoutePath.tenantHome;
    return route;
  };

  const storeLoginInfo = (data: any) => {
    localStorage.setItem(key.loginInfo, JSON.stringify(data));
    dispatch(setLoginInfo(data));
  };

  useEffect(() => {
    if (isSuccess && data) {
      setErrorMessage("");
      dispatch(setToken(data));
      const decoded: any = jwtDecode(data);
      storeLoginInfo(decoded);
      navigate(getNavigation(decoded?.scope));
    } else if (isError) {
      setErrorMessage("Incorrect username or password.");
    }
    // Restrict Login Page navigation for Logged in user
    if (token && loginInfo) {
      if (!loginInfo.scope) {
        const decoded: any = jwtDecode(token);
        storeLoginInfo(decoded);
        loginInfo = decoded;
      }
      // code producing wrong output need to confirm again
      // let logoutUser = window.confirm("Do you want to logout?");
      // if (logoutUser) {
      //   dispatch(clearToken());
      //   dispatch(clearConfig());
      //   dispatch({ type: "RESET" });
      // }
      // storeLoginInfo(loginInfo);
      // navigate(getNavigation(loginInfo.scope));
    }
  }, [isSuccess, isError, data, dispatch, navigate]);

  return (
    <div className="login-container">
      <div className="form-container">
        <img src={VistaXMLogo} alt="VistaXM Logo" className="logo-vista" />
        <form className="form-style" onSubmit={handleSubmit}>
          <div className="input-container">
            <Input
              type="email"
              placeholder={literals.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              customStyle={{ width: 200 }}
            />
          </div>
          <div className="input-container">
            <Input
              type="password"
              placeholder={literals.password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              customStyle={{ width: 200 }}
            />
          </div>
          {/* <div className="forgot-password">
            <Text
              text={literals.forgotPassword}
              styleName="caption2"
              color={colors.Light_Blue_PRI}
              onClick={() => {}}
            />
          </div> */}
          <button
            type="submit"
            className="button-style pointer"
            disabled={isLoading || !email || !password}
          >
            {isLoading ? "Logging in..." : "Submit"}
          </button>
        </form>
      </div>
      {errorMessage && (
        <Text
          text={errorMessage}
          styleName="caption2"
          color={colors.red}
          onClick={() => { }}
        />
      )}
    </div>
  );
};

export default Login;
