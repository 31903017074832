import React from "react";
import ReactDOM from "react-dom";
import IconMoonClose from "../../assets/images/IconMoon_close.svg";
import Text from "../Text/Text";
import { colors } from "../../theme/colors";
import "../../styles/component/RightSidebar/RightSidebar.scss";
import Loader from "../Loader/Loader";

interface RightSidebarProps {
  handleCancelSidebar: () => void;
  children: React.ReactNode;
  header?: string;
  overrideRSBClass?: string;
  headerBodyContent?: React.ReactNode;
  isLoading?: boolean;
}

export const RightSidebar: React.FC<RightSidebarProps> = ({
  handleCancelSidebar,
  header,
  overrideRSBClass = "",
  children,
  headerBodyContent,
  isLoading = false,
}) => {
  return ReactDOM.createPortal(
    <div className={`right-sidebar ${overrideRSBClass}`}>
      {isLoading ? (
        <div className="rsb-loader">
          <Loader isLoading={true} loaderCustomClass="rsb-loader-overlay" />
        </div>
      ) : (
        <>
          {header && (
            <div className="header-wrapper">
              <div className="header-title-wrapper">
                <Text
                  text={header}
                  styleName="Heading7"
                  color={colors.grayFive}
                />
                <img
                  className="cross-icon"
                  src={IconMoonClose}
                  alt="cross-icon"
                  onClick={handleCancelSidebar}
                />
              </div>
              {headerBodyContent}
            </div>
          )}
          {children}
        </>
      )}
    </div>,
    document.body
  );
};
