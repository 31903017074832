import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CustomerData {
  customer: { label: string; value: string | null };
}

const initialState: CustomerData = { customer: { label: "", value: null } };

const CSATCustomerSlice = createSlice({
  name: "CSATCustomer",
  initialState,
  reducers: {
    setCSATCustomerData: (
      state,
      action: PayloadAction<{ label: string; value: string } | null>
    ) => {
      state.customer = action.payload ?? { label: "", value: null };
    },
    clearCSATCustomerData: (state) => {
      state.customer = initialState.customer;
    },
  },
});

export const { setCSATCustomerData, clearCSATCustomerData } =
  CSATCustomerSlice.actions;

export default CSATCustomerSlice.reducer;
