import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../feature/Header/Header";
import { getRoutes, tenantRoutes } from "./constants";
import { IRoute } from "./model";
import React, { Suspense, useEffect, useMemo, useCallback } from "react";
import Loader from "../component/Loader/Loader";
import Footer from "../feature/Footer/Footer";
import "../styles/AppRoute/AppRoute.scss";
import Layout from "../feature/Layout/Layout";
import { Toaster } from "react-hot-toast";
import "./amChartSetup";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import DashboardLayout from "../feature/DashboardLayout/DashboardLayout";
import Login from "../page/Login/Login";
import { UserRole } from "../page/Admin/utils/globalType";
import { RoutePath } from "./AppRoutePath";
import NotFound from "../component/NotFound/NotFound";

function AppRoute() {
  const { token, loginInfo } = useSelector((state: RootState) => state?.auth);

  const isAdminUser = useMemo(
    () => token && loginInfo?.scope === UserRole.ADMIN,
    [token, loginInfo]
  );

  const isTenantUser = useMemo(
    () => (token && loginInfo?.scope === UserRole.TENANT ? true : false),
    [token, loginInfo]
  );

  const updateOverflowClass = useCallback(() => {
    document.documentElement.classList.toggle("overflow-hidden", isTenantUser);
  }, [isTenantUser]);

  useEffect(() => {
    updateOverflowClass();
  }, [updateOverflowClass]);

  const adminRoutes = useMemo(
    () =>
      getRoutes.map((route: IRoute) => (
        <Route path={route.path} element={route.element} key={route.path} />
      )),
    []
  );

  const tenantUserRoutes = useMemo(
    () =>
      tenantRoutes.map((menuItem, index) => (
        <React.Fragment key={`${menuItem.path}-${index}`}>
          <Route
            path={menuItem.path}
            element={menuItem.element || <div>{menuItem.label} View</div>}
          />
          {menuItem.subMenu?.map((subItem, subIndex) => (
            <Route
              key={`${subItem.path}-${subIndex}`} // Ensuring uniqueness
              path={subItem.path}
              element={subItem.element || <div>{subItem.label} View</div>}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </React.Fragment>
      )),
    []
  );

  return (
    <div className="app-container">
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 7000,
          style: { fontFamily: "Roboto" },
        }}
      />
      <Router>
        {isAdminUser ? (
          <Suspense fallback={<Loader isLoading={true} />}>
            <Header />
            <Layout>
              <Routes>{adminRoutes}</Routes>
            </Layout>
            <Footer />
          </Suspense>
        ) : isTenantUser ? (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path={RoutePath.tenantHome} element={<DashboardLayout />}>
              {tenantUserRoutes}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <>
            <Header />
            <Layout>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
          </>
        )}
      </Router>
    </div>
  );
}

export default AppRoute;
