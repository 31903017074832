import React from 'react';
import '../../styles/component/Dropdown/Dropdown.scss';

interface DropdownProps {
  options: { value: string; label: string }[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onChange, value }) => (
  <select className="dropdown" onChange={onChange} value={value}>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

export default Dropdown;
