import React, { useState } from "react";
import PlusIcon from "../../../../assets/images/PlusIcon.svg";
import { NBRightSidebarProjectMetrics } from "./NBRightSidebarProjectMetrics";
import "../../../../styles/page/ClientDetails/TenantDetails/NBCustomMetricEditor.scss";

const NBCustomMetricEditor = (params: any) => {
  const aggregateProjectMetricValue =
    params?.data?.aggregate?.overallAggregateValue;
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className="nbc-metric-editor-wrapper">
      <div className="text-container">{aggregateProjectMetricValue}</div>
      <img
        className="plus-icon-image"
        src={PlusIcon}
        alt="plus-icon"
        onClick={() => setShowSidebar(!showSidebar)}
      />
      {showSidebar && (
        <NBRightSidebarProjectMetrics
          handleCancelSidebar={() => setShowSidebar(false)}
          projectOption={params?.values || []}
          aggregateProjectMetricValue={aggregateProjectMetricValue}
          existedProjectMetrics={params?.data?.aggregate?.projectMetricValues}
          params={params}
        />
      )}
    </div>
  );
};

export default NBCustomMetricEditor;
