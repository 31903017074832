import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import ProtectedRoute from "../../AppRoute/ProtectedRoute";
import Footer from "../Footer/Footer";
import { headerHeight } from "../../page/Admin/utils/globalUtil";
import { MenuItem, tenantRoutes } from "../../AppRoute/constants";
import DashboardHeader from "./DashboardHeader";
import "../../styles/feature/DashboardLayout/DashboardLayout.scss";

const getParentMenuPath = (menuItems: MenuItem[], currentPath: string) => {
  for (const item of menuItems) {
    if (item.subMenu?.some((subItem) => subItem.path === currentPath)) {
      return item.path; // Return the parent menu's path
    }
  }
  return currentPath; // If no parent is found, return the current path
};

const DashboardLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<string>(
    getParentMenuPath(tenantRoutes, location.pathname) || ""
  );

  return (
    <div className="dashboard-layout">
      <DashboardHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      <div
        className="dashboard-content"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <Sidebar
          collapsed={collapsed}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
        <div className="main-container">
          <main className="main-content">
            <ProtectedRoute element={<Outlet />} />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
