import React, { useEffect, useState } from "react";
import PlusIcon from "../../../../assets/images/PlusIcon.svg";
import CSATRightSidebarSubTouchPoint, {
  touchpointKey,
} from "./CSATRightSidebarSubTouchPoint";
import toast from "react-hot-toast";
import { literals } from "../../../../theme/literals";
import {
  getSelectOptions,
  GenericSelectOption,
} from "../../../Admin/utils/globalUtil";
import "../../../../styles/page/ClientDetails/TenantDetails/CSATTouchPointEditor.scss";
import { useLazyGetCSATTouchAndSubTouchpointsQuery } from "../../slice/TenantCsatQuery";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
const CSATTouchPointEditor = (params: any) => {
  const agGridData = useSelector((state: RootState) => state.agGrid.data);
  const CSATCustomerData = useSelector(
    (state: RootState) => state.CSATCustomer
  );
  const isCustomerIdAvailable = CSATCustomerData?.customer?.value
    ? true
    : false;
  const isFullRowEdit = agGridData.editType === "fullRow";
  const [loader, setLoader] = useState(isFullRowEdit ? true : false);
  const [showSidebar, setShowSidebar] = useState(false);
  const touchPointValue = params?.data?.touchpointObj?.touchpoint;
  const subTouchPointsValue = params?.data?.touchpointObj?.subTouchPoints;
  const [touchPoint, setTouchpoint] = useState<GenericSelectOption | null>({
    label: touchPointValue,
    value: touchPointValue,
  });
  const [touchPointOptions, setTouchPointOptions] = useState<
    GenericSelectOption[]
  >([{ label: touchPointValue, value: touchPointValue }]);
  const [subTouchPoints, setSubTouchPoints] = useState(subTouchPointsValue);
  const [triggerApi, { data: CsatTouchSubTouchPD }] =
    useLazyGetCSATTouchAndSubTouchpointsQuery();

  const handleSidebarToggle = () => {
    if (isFullRowEdit && !isCustomerIdAvailable)
      toast.error(literals.selectCsatCustomerError);
    else {
      setShowSidebar(!showSidebar);
    }
  };

  const setTouchAndSubTPNull = (
    touchPointOptions: GenericSelectOption[],
    activeTouchpoint: GenericSelectOption | null,
    subTP: any[] | null
  ) => {
    setTouchPointOptions(touchPointOptions);
    setTouchpoint(activeTouchpoint);
    setSubTouchPoints(subTP);
    setLoader(false);
  };
  const getTouchAndSubTP = async () => {
    try {
      const response = await triggerApi({
        tenantId: params?.data?.tenantId,
        customerId: CSATCustomerData?.customer?.value,
      }).unwrap();
      if (response && response?.length > 0) {
        const touchPointOptions = getSelectOptions(
          response,
          "touchpoint",
          "touchpoint"
        );
        setTouchAndSubTPNull(
          touchPointOptions,
          touchPointOptions[0],
          response[0]?.subTouchPointScores
        );
      } else {
        setTouchAndSubTPNull([], null, null);
      }
      setLoader(false);
    } catch (err) {
      setTouchAndSubTPNull([], null, null);
      toast.error(literals.somethingWentWrong);
    }
  };
  useEffect(() => {
    if (isFullRowEdit && CSATCustomerData?.customer?.value && showSidebar) {
      getTouchAndSubTP();
      setLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CSATCustomerData?.customer?.value, isFullRowEdit, showSidebar]);

  const handleTouchPointChange = (e: any) => {
    const touchPoint = CsatTouchSubTouchPD.find(
      (item: any) => item[touchpointKey] === e?.value
    );
    setTouchpoint(e);
    setSubTouchPoints(touchPoint.subTouchPointScores);
  };
  return (
    <div className="csat-touchPoint-editor-wrapper">
      <div className="text-container">{touchPointValue}</div>
      <img
        className="plus-icon-image"
        src={PlusIcon}
        alt="plus-icon"
        onClick={() => handleSidebarToggle()}
      />
      {showSidebar && (
        <CSATRightSidebarSubTouchPoint
          handleCancelSidebar={() => setShowSidebar(false)}
          touchPoint={touchPoint}
          oldSubTouchPoint={subTouchPoints}
          touchPointOptions={touchPointOptions}
          handleTouchPointChange={handleTouchPointChange}
          isDisabledTPSelection={!isFullRowEdit}
          isLoading={loader}
          params={params}
        />
      )}
    </div>
  );
};

export default CSATTouchPointEditor;
