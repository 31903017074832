import { ColDef } from "ag-grid-community";
import { CustomerSummaryResponse, RowData } from "../CustomerSummary.type";
import { avgTouchpointKey, trendKey } from "../CustomerSummaryQuery";
import { CustomersGridColumns, CustomerSummaryFeedbackGridColumns } from "../../../constants/ClientDetails";
import { getFieldsFromColumn } from "../../ClientDetails/utils/GridOps";
import { literals } from "../../../theme/literals";

export const customersGridField = getFieldsFromColumn(CustomersGridColumns);
export const customerSummaryFeedbackGridField = getFieldsFromColumn(CustomerSummaryFeedbackGridColumns);

export const createColumnDefs = (
  data: CustomerSummaryResponse | undefined,
  trendCellRenderer: React.FC<any>,
  TouchPointHeader: React.FC<any>,
  uniqueKeys: Record<string, string[]>,
  activeTouchpoint: any,
  handleChange: any,
  handleOnClickCustomer: any
): ColDef<RowData>[] => {
  if (!data) return [];
  const columnDefs: ColDef<any>[] = [];
  columnDefs.push({
    headerName: CustomersGridColumns.customer,
    field: customersGridField.customer,
    pinned: "left",
    cellClass: "sticky-column",
    headerClass: "first-column-header",
    cellRenderer: trendCellRenderer,
    cellRendererParams: { handleOnClickCustomer },
    width: 350,
  });

  // columnDefs.push({
  //   headerName: CustomersGridColumns.priority,
  //   field: customersGridField.priority,
  //   headerClass: "top-header",
  //   cellStyle: { color: "green" },
  //   width: 200,
  // });

  columnDefs.push({
    headerName: CustomersGridColumns.health,
    field: customersGridField.health,
    headerClass: "top-header",
    cellRenderer: trendCellRenderer,
    width: 200,
  });

  columnDefs.push({
    headerName: CustomersGridColumns.nps,
    field: customersGridField.nps,
    headerClass: "top-header",
    cellRenderer: trendCellRenderer,
    width: 200,
  });

  data.touchpoints.forEach((key) => {
    columnDefs.push({
      headerName: key,
      field: key,
      headerClass: "top-header",
      cellRenderer: trendCellRenderer,
      cellRendererParams: {
        keyValueToDisplay: activeTouchpoint[key],
      },
      width: 200,
      headerComponent: TouchPointHeader,
      headerComponentParams: {
        options: getTouchpointOptions(uniqueKeys[key]),
        value: activeTouchpoint[key],
        handleChange: (e: any) => {
          handleChange(key, e);
        },
        headerName: key,
      },
    });
  });

  return columnDefs;
};

const getTouchpointOptions = (rawOption: any[]) => {
  let options = [];
  if (rawOption?.length) {
    options.push({ label: avgTouchpointKey, value: avgTouchpointKey });
    rawOption.forEach((item) => options.push({ label: item, value: item }));
  }
  return options;
};

export const getTrendKey = (scoreType: string | undefined) => {
  if (!scoreType) return "";
  return scoreType + trendKey;
};


export const getFeedbackDetailsTableColumnDefs = (
  data: any[] | undefined,
  FeedbackCellRenderer: React.FC<any>
): ColDef<any>[] => {
  if (!data) return [];
  const columnDefs: ColDef<any>[] = [];
  columnDefs.push({
    headerName: CustomerSummaryFeedbackGridColumns.respondentName,
    field: customerSummaryFeedbackGridField.respondentName,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 170,
    suppressMovable: true
  });
  columnDefs.push({
    headerName: CustomerSummaryFeedbackGridColumns.email,
    field: customerSummaryFeedbackGridField.email,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 225,
    suppressMovable: true
  });
  columnDefs.push({
    headerName: CustomerSummaryFeedbackGridColumns.date,
    field: customerSummaryFeedbackGridField.date,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 160,
    suppressMovable: true
  });

  columnDefs.push({
    headerName: CustomerSummaryFeedbackGridColumns.comment,
    field: customerSummaryFeedbackGridField.comment,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 670,
    suppressMovable: true
  });

  return columnDefs;
};

export const getFormattedDate = (date: any) => {
  const dateString = new Date(date);

  // Extract day, month, and year from the date object
  const day = String(dateString.getDate()).padStart(2, '0');
  const month = String(dateString.getMonth() + 1).padStart(2, '0');
  const year = dateString.getFullYear();

  // Return formatted date
  return `${day}/${month}/${year}`;
}

export const getNPSLabel = (value: number) => {
  return value > 7
    ? literals.promoter
    : value < 3
      ? literals.detractor
      : literals.neutralNPS
}
