import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";

export const InsightHomeQuery = createApi({
  reducerPath: "InsightHomeQuery",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getInsightSummary: builder.query<any, void>({
      query: () => ({
        body: gql`
          query Insights {
            insights {
              tenantId
              insightsSummary {
                total
                promoted
                newlyDiscovered
                promotedAndNewlyDiscovered
              }
            }
          }
        `,
      }),
      transformResponse: ({ insights }: any) => insights,
    }),
    getInsightStats: builder.query<any, void>({
      query: () => ({
        body: gql`
          query Insights {
            insights {
              tenantId
              promotedInsights {
                id
                date
                title
                description
                frictionDescription
              }
              topInsights {
                id
                date
                title
                overallScore
              }
            }
          }
        `,
      }),
      transformResponse: ({ insights }: any) => insights,
    }),
  }),
});

export const { useGetInsightSummaryQuery, useGetInsightStatsQuery } =
  InsightHomeQuery;
