import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
const minRadius = 30;
const maxRadius = 50;
const radiusRange = maxRadius - minRadius;
const radiusAverage = (minRadius + maxRadius) / 2;
const strokeColors = [0x7852fe, 0x7fb5f2, 0x3185fc];

function WordBubbleChart({ chartID, data }: { chartID: string; data: any[] }) {
  useLayoutEffect(() => {
    const values = data?.map((item) => item.frequency) || [];
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    const root = am5.Root.new(chartID);
    root.setThemes([am5themes_Animated.new(root)]);
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    // Create series
    const series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        valueField: "frequency", // Use frequency for size
        categoryField: "name", // Use name for category
      })
    );

    // Code to scale the radius of the circle based on the value
    series.circles.template.adapters.add("radius", (radius, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem) {
        const value = dataItem.get("value");
        if (minValue === maxValue) {
          return radiusAverage;
        }
        const scaledRadius = Math.min(
          maxRadius,
          Math.max(
            minRadius,
            ((value - minValue) / (maxValue - minValue)) * radiusRange +
            minRadius
          )
        );
        return scaledRadius;
      }
      return radius;
    });

    // code to assign different color to circle border
    series.circles.template.adapters.add("stroke", (stroke, target) => {
      const dataItem = target.dataItem;
      if (dataItem) {
        // randomly assign color from strokes color
        const randomIndex = Math.floor(Math.random() * strokeColors.length);
        return am5.color(strokeColors[randomIndex]);
      }
      return stroke; // Default stroke if no data item is found
    });

    series.data.setAll(data || []);
    series.labels.template.setAll({
      text: "{name}",
      fontSize: 15,
      centerX: am5.p50,
      centerY: am5.p50,
      fill: am5.color(0x000000),
    });

    // series circle styling
    series.circles.template.setAll({
      fillOpacity: 1,
      strokeWidth: 3,
      radius: minRadius,
      strokeOpacity: 1,
      fill: am5.color(0xffffff),
      tooltipHTML:
        "<div style='background: #52a3ff; padding: 0.5rem; border-radius: 0.25rem'>{name}: {frequency}</div>",
    });

    return () => {
      root.dispose();
    };
  }, [chartID, data]);
  const getChartHeight = (dataLength: any) => {
    switch (true) {
      case !dataLength || dataLength < 10:
        return 250;
      case dataLength < 30:
        return 700;
      case dataLength < 50:
        return 1200;
      default:
        return 1400;
    }
  };
  const calcHeight = getChartHeight(data?.length);
  return <div id={chartID} style={{ height: calcHeight, width: "100%" }}></div>;
}
export default WordBubbleChart;
