import Relational from "../page/Signals/Relational";
import { ReactNode } from "react";
import { literals } from "../theme/literals";
import lazyLoad from "./LazyLoad";
import HomeImg from "../assets/images/HomeOutline.svg";
import ActiveHomeImg from "../assets/images/ActiveHomeOutline.svg";
import CustomerImg from "../assets/images/CustomerOutline.svg";
import ActiveCustomerImg from "../assets/images/ActiveCustomerOutline.svg";
import SignalImg from "../assets/images/SignalOutline.svg";
import ActiveSignalImg from "../assets/images/ActiveSignalOutline.svg";
import InsightImg from "../assets/images/AnalyticsOutline.svg";
import ActiveInsightImg from "../assets/images/ActiveAnalyticsOutline.svg";
import RankingImg from "../assets/images/RankingOutline.svg";
import ActiveRankingImg from "../assets/images/ActiveRankingOutline.svg";
import ExecutionImg from "../assets/images/ExecutionOutline.svg";
import ActiveExecutionImg from "../assets/images/ActiveExecutionOutline.svg";
import CurrencyUsdImg from "../assets/images/CurrencyUsd.svg";
import ActiveCurrencyUsdImg from "../assets/images/ActiveCurrencyUsd.svg";
import SupportImg from "../assets/images/SupportOutline.svg";
import ActiveSupportImg from "../assets/images/ActiveSupportOutline.svg";
import SettingImg from "../assets/images/SettingOutline.svg";
import ActiveSettingImg from "../assets/images/ActiveSettingOutline.svg";
import ProtectedRoute from "./ProtectedRoute";
import { IRoute } from "./model";
import LazyCompFallbackWrapper from "./LazyCompFallbackWrapper";
import Transactional from "../page/Signals/Transactional";
import Digital from "../page/Signals/Digital";
import Content from "../page/Signals/Content";
import { RoutePath } from "./AppRoutePath";
import SignalsDashboard from "../page/Signals/SignalsDashboard";
import NotFound from "../component/NotFound/NotFound";

export type MenuItem = {
  label: string;
  path?: string;
  logo?: ReactNode;
  activeLogo?: ReactNode;
  element?: ReactNode; // Component to render for the route
  subMenu?: MenuItem[]; // Recursive type for submenu
};

export type MenuItems = {
  tenant: MenuItem[];
  admin: MenuItem[] | [];
};

const About = lazyLoad(() => import("../page/About/About"));
const CustomerSummary = lazyLoad(
  () => import("../page/CustomerSummary/CustomerSummary")
);
const Home = lazyLoad(() => import("../page/Home/Home.tsx"));
const TenantHome = lazyLoad(() => import("../page/Version2/Home/Home.tsx"));
const Insights = lazyLoad(
  () => import("../page/Version2/Insights/InsightsDashboard.tsx")
);
const Signals = lazyLoad(() => import("../page/Signals/Signals.tsx"));
const Ranking = lazyLoad(() => import("../page/Ranking/Ranking"));
const ExecutionProjectIssues = lazyLoad(
  () => import("../page/ExecutionProjectIssues/ExecutionProjectIssues.tsx")
);
const NetBenefitDetails = lazyLoad(
  () => import("../page/NetBenefitDetails/NetBenefitDetails.tsx")
);
const InsightAnalysis = lazyLoad(
  () => import("../page/InsightAnalysis/InsightAnalysis")
);
const ExecutionDetails = lazyLoad(
  () => import("../page/ExecutionDetails/ExecutionDetails")
);
const Login = lazyLoad(() => import("../page/Login/Login"));
const CustomerDetails = lazyLoad(() => import("../page/Admin/CustomerDetails"));
const ClientDetails = lazyLoad(
  () => import("../page/ClientDetails/ClientDetails.tsx")
);
const TenantDashboard = lazyLoad(
  () => import("../page/Tenant/TenantDashboard.tsx")
);

export const getNavBar: IRoute[] = [
  {
    path: "/About",
    element: <ProtectedRoute element={<About />} />,
    name: "About",
  },
  {
    path: "/Tutorial",
    element: <ProtectedRoute element={<Home />} />,
    name: "Tutorial",
  },
  {
    path: "/Support",
    element: <ProtectedRoute element={<Home />} />,
    name: "Support",
  },
  {
    path: "/Task",
    element: <ProtectedRoute element={<Home />} />,
    name: "Task",
  },
  {
    path: "/Feedback",
    element: <ProtectedRoute element={<Home />} />,
    name: "Feedback",
  },
  {
    path: "/Profile",
    element: <ProtectedRoute element={<Home />} />,
    name: "Profile",
  },
];

export const getNavigation: IRoute[] = [
  {
    path: "/",
    element: <Login />,
    name: "Login",
  },
  {
    path: "/Home",
    element: <ProtectedRoute element={<Home />} />,
    name: "Home",
  },
  {
    path: "/Signals",
    element: <ProtectedRoute element={<Signals />} />,
    name: "Signals",
  },
  {
    path: "/Ranking",
    element: <ProtectedRoute element={<Ranking />} />,
    name: "Ranking",
  },
  {
    path: "/Execution-project-issues",
    element: <ProtectedRoute element={<ExecutionProjectIssues />} />,
    name: "ExecutionProjectIssues",
  },
  {
    path: "/net-benefit-details",
    element: <ProtectedRoute element={<NetBenefitDetails />} />,
    name: "NetBenefitDetails",
  },
  {
    path: "/Insight-analysis",
    element: <ProtectedRoute element={<InsightAnalysis />} />,
    name: "Insight",
  },
  {
    path: "/Execution-Details",
    element: <ProtectedRoute element={<ExecutionDetails />} />,
    name: "Execution",
  },
  {
    path: "/CustomerDetails",
    element: <ProtectedRoute element={<CustomerDetails />} />,
    name: "CustomerDetails",
  },
  {
    path: "/ClientDetails",
    element: <ProtectedRoute element={<ClientDetails />} />,
    name: "ClientDetails",
  },
  {
    path: "/TenantDashboard",
    element: <ProtectedRoute element={<TenantDashboard />} />,
    name: "TenantDashboard",
  },
  {
    path: "*",
    element: <NotFound />,
    name: "NotFound",
  },
];

export const getRoutes = [...getNavigation, ...getNavBar];

export const menuItems: MenuItems = {
  tenant: [
    {
      label: literals.home,
      path: RoutePath.tenantHome,
      logo: <img src={HomeImg} alt="home" />,
      activeLogo: <img src={ActiveHomeImg} alt="home" />,
      element: (
        <LazyCompFallbackWrapper>
          <TenantHome />
        </LazyCompFallbackWrapper>
      ),
    },
    {
      label: literals.customers,
      path: RoutePath.tenantCustomers,
      logo: <img src={CustomerImg} alt="customers" />,
      activeLogo: <img src={ActiveCustomerImg} alt="customers" />,
      element: (
        <LazyCompFallbackWrapper>
          <CustomerSummary />
        </LazyCompFallbackWrapper>
      ),
    },
    {
      label: literals.signals,
      path: RoutePath.tenantSignal,
      logo: <img src={SignalImg} alt="signal" />,
      activeLogo: <img src={ActiveSignalImg} alt="signal" />,
      element: (
        <LazyCompFallbackWrapper>
          <SignalsDashboard />
        </LazyCompFallbackWrapper>
      ),
      subMenu: [
        {
          label: literals.relational,
          path: RoutePath.tenantRelational,
          element: <Relational />,
        },
        {
          label: literals.transactional,
          path: RoutePath.tenantTransactional,
          element: <Transactional />,
        },
        {
          label: literals.digital,
          path: RoutePath.tenantDigital,
          element: <Digital />,
        },
        {
          label: literals.content,
          path: RoutePath.tenantContent,
          element: <Content />,
        },
      ],
    },
    {
      label: literals.insights,
      path: RoutePath.tenantInsight,
      logo: <img src={InsightImg} alt="insight" />,
      activeLogo: <img src={ActiveInsightImg} alt="insight" />,
      element: (
        <LazyCompFallbackWrapper>
          <Insights />
        </LazyCompFallbackWrapper>
      ),
    },
    {
      label: literals.ranking,
      path: RoutePath.tenantRanking,
      logo: <img src={RankingImg} alt="ranking" />,
      activeLogo: <img src={ActiveRankingImg} alt="ranking" />,
      element: (
        <LazyCompFallbackWrapper>
          <Ranking />
        </LazyCompFallbackWrapper>
      ),
    },
    {
      label: literals.execution,
      logo: <img src={ExecutionImg} alt="execution" />,
      activeLogo: <img src={ActiveExecutionImg} alt="execution" />,
      path: RoutePath.tenantProjectReport,
      element: (
        <LazyCompFallbackWrapper>
          <ExecutionDetails />
        </LazyCompFallbackWrapper>
      ),
      subMenu: [
        {
          label: literals.projectReport,
          path: RoutePath.tenantProjectReport,
          element: (
            <LazyCompFallbackWrapper>
              <ExecutionDetails />
            </LazyCompFallbackWrapper>
          ),
        },
        {
          label: literals.projectBlockers,
          path: RoutePath.tenantProjectBlockers,
          element: (
            <LazyCompFallbackWrapper>
              <ExecutionProjectIssues />
            </LazyCompFallbackWrapper>
          ),
        },
      ],
    },
    {
      label: literals.netBenefitsText,
      path: RoutePath.tenantNetBenefit,
      logo: <img src={CurrencyUsdImg} alt="net benefit" />,
      activeLogo: <img src={ActiveCurrencyUsdImg} alt="net benefit" />,
      element: (
        <LazyCompFallbackWrapper>
          <NetBenefitDetails />
        </LazyCompFallbackWrapper>
      ),
    },
  ],
  admin: [],
};

export const miscellaneousTenantMenu: MenuItem[] = [
  {
    label: literals.support,
    path: RoutePath.tenantSupport,
    logo: <img src={SupportImg} alt="support" />,
    activeLogo: <img src={ActiveSupportImg} alt="support" />,
  },
  {
    label: literals.settings,
    path: RoutePath.tenantSettings,
    logo: <img src={SettingImg} alt="settings" />,
    activeLogo: <img src={ActiveSettingImg} alt="settings" />,
  },
];

export const tenantRoutes: MenuItem[] = [
  ...menuItems.tenant,
  ...miscellaneousTenantMenu,
];
