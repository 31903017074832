import { literals } from "../../../theme/literals";
import { getFormattedCsat } from "./getFormattedCsat";

export interface CommonWidgetInfo {
  graphData : { month: number, value: number }[];
  averageScore: number;
  trend: string;
  changePercent: number
}

export const getCommonWidgetInfo = (data = []): CommonWidgetInfo => {
  
  const graphData = getFormattedCsat(
    data?.map((item: any) => ({
      ...item,
      score: item.value
    }))
  );

  let averageScore: any = 0;
  if(data.length > 0){
    for(let item of data as any){
      averageScore += item.value;
    }
    averageScore = (averageScore/(data?.length));
  }

  let trend = literals.up;
  let changePercent = 0;

  if(data?.length > 1){
    const first = 0 ;
    const second = 1;
    const change = ((data as any)[first].value - (data as any)[second].value);
    changePercent = Math.abs((change/(data as any)[second].value) * 100);
    if(change <= 0){
      trend = literals.down
    }
  }

  return { graphData, averageScore, trend, changePercent }
}