import React from "react";
import { Form } from "react-bootstrap";
import "../../../styles/component/CustomSelectCellEditor/CustomSelectCellEditor.scss";

interface SingleSelectOptionProps {
  option: string | any;
  value: string;
  keyToShowValue: string;
  type?: "radio" | "checkbox";
  overrideCOCClass?: string;
}
const CustomDropdownOptionRender = ({
  option,
  value,
  keyToShowValue,
  type = "radio",
  overrideCOCClass,
}: SingleSelectOptionProps) => {
  const isActive = value === (option?.[keyToShowValue] || option);
  return (
    <div
      className={`custom-option-content-wrapper ${isActive ? "active" : ""}`}
    >
      <Form.Check type={type} checked={isActive} readOnly />
      <div className={`custom-option-content caption2 ${overrideCOCClass}`}>
        {option?.[keyToShowValue] || option}
      </div>
    </div>
  );
};

export default CustomDropdownOptionRender;
