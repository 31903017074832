import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "./DonutChart.scss";

interface DataPoint {
  category: string;
  value: number;
  colorClass: string;
  percentType?: boolean;
}

interface DonutChartProps {
  data: DataPoint[];
  chartID: string;
  showLegend?: boolean;
  height?: any;
  width?: any;
  chartPadding?: { left: number; right: number; top: number; bottom: number };
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  chartID,
  showLegend = true,
  height,
  width,
  chartPadding = { left: -10, right: -10, top: -10, bottom: -10 },
}) => {
  useLayoutEffect(() => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    const root = am5.Root.new(chartID, {
      tooltipContainerBounds: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      },
    });

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        innerRadius: am5.percent(80),
      })
    );

    series.get("colors")?.set(
      "colors",
      data.map((d) =>
        am5.color(
          getComputedStyle(document.documentElement).getPropertyValue(
            `--${d.colorClass}-color`
          )
        )
      )
    );
    // Reduce padding
    chart.setAll({
      paddingLeft: chartPadding.left,
      paddingRight: chartPadding.right,
      paddingTop: chartPadding.top,
      paddingBottom: chartPadding.bottom,
    });

    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    series.data.setAll(data);

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartID, data, chartPadding]);

  return (
    <div className="chartContainer">
      <div
        id={chartID}
        style={{ width: width || "100px", height: height || "90px" }}
      ></div>
      {showLegend && (
        <div className="chartLabels">
          {data.map((d, index) => (
            <div key={index} className={`labelRow ${d.colorClass}`}>
              <span className="labelColor">●</span>
              <div className="legendContainer">
                <span className="percentStyle">
                  {d.value}
                  {d.percentType && <span>%</span>}
                </span>
                <span className="labelStyle">{d.category}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DonutChart;
