import { literals } from "../../theme/literals";
import Text from "../Text/Text";
import "./NotFound.scss";
import '../../sass/typography.scss';

const NotFound = () => {
    return (
        <div className="no-found-container">
            <Text text={literals.notFound} styleName="Heading4" />
        </div>
    );
}

export default NotFound;