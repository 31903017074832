import React from "react";
import "../../styles/component/Loader/Loader.scss";

interface LoaderProps {
  isLoading: boolean;
  loaderCustomClass?: string;
}

const Loader: React.FC<LoaderProps> = ({ isLoading, loaderCustomClass }) => {
  if (!isLoading) return null;

  return (
    <div
      className={`loader-overlay ${loaderCustomClass ? loaderCustomClass : ""}`}
    >
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loader;
