import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";
import { RowData, SubTouchpointScores } from "./CustomerSummary.type";
export const avgTouchpointKey = "average";
export const trendKey = "Trend";
interface CustomerSummaryQueryResponse {
  customerSummary: {
    summary: RowData[];
    touchpoints: string[];
  };
}
export const CustomerSummaryQuery = createApi({
  reducerPath: "CustomerSummaryQuery",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getCustomerSummary: builder.query<any, {startDate: string; endDate: string }>({
      query: ({startDate, endDate}) => ({
        body: gql`
          query CustomerSummary {
            customerSummary {
              touchpoints
              summary (startDate: "${startDate}", endDate: "${endDate}") {
                id
                customer
                customerId
                priority
                health
                healthTrend
                nps
                npsTrend
                touchPointScores {
                  touchpoint
                  score
                  trend
                  subTouchPointScores {
                    subTouchpoint
                    score
                  }
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response: CustomerSummaryQueryResponse) => {
        const data = response?.customerSummary?.summary;
        if (!data) return undefined;
        const uniqueKeys = new Map();
        const transformedData = data?.map((row: RowData) => {
          const touchpoints: Record<
            string,
            Record<string, number | string>
          > = {};
          row.touchPointScores?.forEach((touchPoint) => {
            const { touchpoint, score, trend, subTouchPointScores } =
              touchPoint;
            const formedTrendKey = `${[avgTouchpointKey]}${trendKey}`;
            const touchpointData: Record<string, number | string> = {
              [avgTouchpointKey]: score, // using average key to represent average of all the sub-touchpoint
              [formedTrendKey]: trend,
            };

            subTouchPointScores?.forEach((sub: SubTouchpointScores) => {
              touchpointData[sub.subTouchpoint] = sub.score;
              // Collect unique keys for touchpoints and sub-touchpoints
              if (!uniqueKeys.has(touchpoint)) {
                uniqueKeys.set(touchpoint, new Set());
              }
              uniqueKeys.get(touchpoint).add(sub.subTouchpoint);
            });

            touchpoints[touchpoint] = touchpointData;
          });

          return {
            ...row,
            ...touchpoints,
          };
        });

        // Convert unique keys to a plain object
        const uniqueKeyObject: Record<string, string[]> = {};
        uniqueKeys?.forEach((subKeys, key) => {
          uniqueKeyObject[key] = Array.from(subKeys);
        });

        return {
          summary: transformedData,
          touchpoints: response?.customerSummary?.touchpoints,
          uniqueKeys: uniqueKeyObject,
        };
      },
    }),
  }),
});

export const { useGetCustomerSummaryQuery } = CustomerSummaryQuery;
