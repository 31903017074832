import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuItem } from "../../AppRoute/constants";

interface RenderMenuItemProps {
  item: MenuItem;
}

export const HeaderMenuItem: React.FC<RenderMenuItemProps> = ({ item }) => {
  const location = useLocation();
  const isActive = location.pathname === item.path;
  const showActiveLogo = item.activeLogo && isActive;
  const logoToShow = showActiveLogo
    ? item.activeLogo
    : item.logo
    ? item.logo
    : null;

  return (
    <div key={item.label} className="pointer">
      {/* <Link to={item.path!} key={item.label} > */}
      <div className={`logo-wrapper ${isActive ? "active" : ""}`}>
        {logoToShow}
      </div>
    </div>
  );
};
