import { ColDef } from "ag-grid-community";
import { insightsGridField } from "../../ClientDetails/TenantDetails/gridConfig";
import { InsightsGridColumns } from "../../../constants/ClientDetails";
import {
  InsightStatusEnum,
  InsightStatusEnumType,
} from "../../Ranking/ProjectInsights.type";
import { colors } from "../../../theme/colors";

export const getIIAnalysisTableColumnDefs = (
  data: any[] | undefined,
  InsightAnalysisTitleCellRenderer: React.FC<any>
): ColDef<any>[] => {
  if (!data) return [];
  const columnDefs: ColDef<any>[] = [];
  columnDefs.push({
    headerName: "Insight",
    field: insightsGridField.title,
    pinned: "left",
    headerClass: "first-column-header",
    cellClass: "sticky-column",
    cellRenderer: InsightAnalysisTitleCellRenderer,
    autoHeight: true,
    width: 400,
  });

  columnDefs.push({
    headerName: InsightsGridColumns.description,
    field: insightsGridField.description,
    cellClass: "unpinned-column",
    width: 700,
    autoHeight: true,
  });

  columnDefs.push({
    headerName: InsightsGridColumns.frictionDescription,
    field: insightsGridField.frictionDescription,
    cellClass: "unpinned-column",
    width: 600,
    autoHeight: true,
  });
  return columnDefs;
};

interface StatusDetails {
  classname: string;
  color: string;
}

export const getStatusColorAndClass = (
  status: InsightStatusEnumType
): StatusDetails => {
  switch (status) {
    case InsightStatusEnum.PENDING:
      return { color: colors.pendingLabelColor, classname: "pending-label" };
    case InsightStatusEnum.COMPLETED:
      return {
        color: colors.completedLabelColor,
        classname: "completed-label",
      };
    case InsightStatusEnum.DELAYED:
      return { color: colors.delayedLabelColor, classname: "delayed-label" };
    default:
      return { color: "", classname: "" };
  }
};

export const capitalizeFirstLetter = (str: string | undefined) => {
  if (!str) {
    return str;
  }
  try {
    const trimmedStr = str.trim();
    if (!trimmedStr) {
      return str; // Return original string if it's only spaces
    }
    // Capitalize the first letter of the first word, and make the rest lowercase
    const firstLetter = trimmedStr.charAt(0).toUpperCase();
    const restOfString = trimmedStr.slice(1).toLowerCase();
    return firstLetter + restOfString;
  } catch (e) {
    return str;
  }
};

const formatDate = (date: any) => date.toISOString().split("T")[0];
export const getFilterQueryDate = (activeTimeFrame: any) => {
  const endDate = new Date(); // Today's date
  let startDate;
  switch (activeTimeFrame.value) {
    case "30days":
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 30);
      return { startDate: formatDate(startDate), endDate: formatDate(endDate) };

    case "6month":
      startDate = new Date();
      startDate.setMonth(endDate.getMonth() - 6);
      return { startDate: formatDate(startDate), endDate: formatDate(endDate) };

    case "1year":
      startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 1);
      return { startDate: formatDate(startDate), endDate: formatDate(endDate) };

    default:
      return { startDate: null, endDate: null };
  }
};
