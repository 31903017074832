import React from "react";
import { AgGridReact } from "ag-grid-react";
import { SignalsSurveyCellRenderer } from "./SignalsSurveyCellRenderer";
import "../../styles/page/CustomerSummary/FeedbackTable.scss";
import "../../sass/agGridTable.scss";
import { getSignalsSurveyTableColumnDefs } from "./utils/gridUtility";
import { SurveyPlanned } from "./SignalsQuery.type";

interface SurveyDataProps {
  signalsSurveyData: SurveyPlanned[];
}

const SignalsSurveyDetailsTable: React.FC<SurveyDataProps> = ({ signalsSurveyData }) => {
  const columnDefs = getSignalsSurveyTableColumnDefs(signalsSurveyData, SignalsSurveyCellRenderer);
  const calculateRowHeight = 15 * signalsSurveyData?.length

  return (
    <div className="feedback-details-container">
      <div
        className="ag-theme-alpine custom-ag-grid-css"
        style={{
          height: calculateRowHeight + "vh",
          maxHeight: "160px",
          width: "100%",
        }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={signalsSurveyData}
          defaultColDef={{ resizable: true, sortable: false, filter: false }} />
      </div>
    </div>
  );
};

export default SignalsSurveyDetailsTable;
