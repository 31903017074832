import React, { useEffect, useState } from "react";
import { RightSidebar } from "../../../../component/RightSidebar/RightSidebar";
import { literals } from "../../../../theme/literals";
import InBodyCustomDropdown from "../../../../component/InBodyCustomDropdown/InBodyCustomDropdown";
import {
  CSAT_TOUCHPOINTSCORES,
  SUB_TOUCHPOINT_SCORE,
} from "./CSATTouchPointType";
import Text from "../../../../component/Text/Text";
import { colors } from "../../../../theme/colors";
import Button from "../../../../component/Button/Button";
import { allowOnlyNumbers } from "../../../Admin/utils/globalUtil";
import toast from "react-hot-toast";
export const touchpointKey: keyof CSAT_TOUCHPOINTSCORES = "touchpoint";
const subTouchpointKey: keyof SUB_TOUCHPOINT_SCORE = "subTouchpoint";
const subTouchpointValueKey: keyof SUB_TOUCHPOINT_SCORE = "score";
const CSATRightSidebarSubTouchPoint = ({
  handleCancelSidebar,
  touchPoint,
  oldSubTouchPoint,
  touchPointOptions,
  handleTouchPointChange,
  isDisabledTPSelection,
  isLoading,
  params,
}: any) => {
  const [subTouchPoints, setSubTouchPoints] = useState(oldSubTouchPoint);
  useEffect(() => {
    setSubTouchPoints(oldSubTouchPoint);
  }, [oldSubTouchPoint]);
  const handleSubTouchpointUpdate = (
    index: number,
    valueToUpdate: string,
    changedValue: any
  ) => {
    let updatedSubTouchPoint = [...subTouchPoints];
    // update score by key, so that in future if want to edit subTouchpoint name,
    // then just add key for subTouchpoint name and update like below one
    if (valueToUpdate === subTouchpointValueKey) {
      updatedSubTouchPoint[index] = {
        ...updatedSubTouchPoint[index],
        [valueToUpdate]: Number(changedValue),
      };
    }
    setSubTouchPoints(updatedSubTouchPoint);
  };

  const handleSave = () => {
    let score = 0;
    if (subTouchPoints?.length > 0) {
      const isSubTouchPtZero = subTouchPoints.some(
        (subTouchPoint: SUB_TOUCHPOINT_SCORE) => {
          return !subTouchPoint.score;
        }
      );
      if (isSubTouchPtZero) {
        toast.error(literals.noSubTPMsg);
        return;
      }
      const totalScore = subTouchPoints.reduce(
        (sum: number, item: any) => sum + (item?.[subTouchpointValueKey] || 0),
        0
      );
      score = totalScore / subTouchPoints.length;
    }
    params.onValueChange({
      score: score,
      touchpoint: touchPoint?.label,
      subTouchPoints: subTouchPoints,
    });
    params.stopEditing();
  };
  return (
    <RightSidebar
      handleCancelSidebar={handleCancelSidebar}
      overrideRSBClass="csat-touchPoint-right-sidebar"
      header={literals.touchPoint}
      isLoading={isLoading}
      headerBodyContent={
        <InBodyCustomDropdown
          isDisabled={isDisabledTPSelection}
          value={touchPoint}
          onChange={handleTouchPointChange}
          options={touchPointOptions}
          inputType="radio"
          width="13.5rem"
        />
      }
    >
      {subTouchPoints?.length > 0 ? (
        <div className="all-sub-touchpoint-wrapper">
          {subTouchPoints.map(
            (subTouchPoint: SUB_TOUCHPOINT_SCORE, index: number) => {
              return (
                <div
                  className="each-sub-touchpoint-wrapper"
                  key={`div-id${index}`}
                >
                  <Text
                    text={`${literals.subTouchPointText} ${index + 1}`}
                    styleName="Heading7"
                    color={colors.grayFive}
                  />
                  <div className="sub-touchpoint-value-wrapper">
                    <input
                      value={subTouchPoint?.[subTouchpointKey] || ""}
                      onChange={(e) => e.preventDefault()}
                      className="sub-touchpoint-name"
                      disabled={true}
                    />
                    <input
                      type="number"
                      value={subTouchPoint?.[subTouchpointValueKey] || ""}
                      className="sub-touchpoint-score"
                      onChange={(e) => {
                        allowOnlyNumbers(
                          e,
                          subTouchPoint?.[subTouchpointValueKey] || "",
                          1,
                          5
                        );
                        handleSubTouchpointUpdate(
                          index,
                          subTouchpointValueKey,
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <Text
          text={literals.noSubTouchPointAvailable}
          color={colors.grayFive}
          styleName="Heading7"
        />
      )}
      <Button type="primary" onClick={() => handleSave()}>
        {literals.save}
      </Button>
    </RightSidebar>
  );
};

export default CSATRightSidebarSubTouchPoint;
