import { numberUpto2Decimal } from "../../Home/utils/executionProjectUtil";
import { CsatTouchPoint } from "../SignalsQuery.type";

export const getCsatTouchpoints = (data?: CsatTouchPoint[]) => {
  if (!data) return [];

  const touchpoints = [...data];

  const transformedData = touchpoints?.map((item: CsatTouchPoint) => {
    const value = numberUpto2Decimal(item?.score);
    return {
      value: value,
      category: `${item?.touchpoint}\n${value}`,
    };
  });

  return transformedData;
};
