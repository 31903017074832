import React, { useState } from "react";
import CustomDropdown from "../../../../component/CustomDropdown/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setCSATCustomerData } from "../../slice/CSATCustomerSlice";

const CSATCustomerCellEditor = (params: any) => {
  const agGridData = useSelector((state: RootState) => state.agGrid.data);
  const dispatch = useDispatch();
  const isFullRowEdit = agGridData.editType === "fullRow";
  const [selectedValue, setSelectedValue] = useState({
    label: params?.value?.name,
    value: params?.value.id,
  });
  const handleChange = (e: any) => {
    setSelectedValue(e);
    params.onValueChange({
      name: e.label,
      id: e.value,
    });
    dispatch(setCSATCustomerData(e));
    if (!isFullRowEdit) {
      // only call BE if not editing full row, As for row "Enter" event will be listened
      params.stopEditing();
    }
  };
  return (
    <CustomDropdown
      options={params.values}
      value={selectedValue}
      onChange={handleChange}
    />
  );
};

export default CSATCustomerCellEditor;
