import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";

function BubbleChart(props: any) {
  const { chartID, data } = props;

  useLayoutEffect(() => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    const root = am5.Root.new(chartID, {
      tooltipContainerBounds: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      },
    });

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {});

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        min: -1,
        max: 6,
      })
    );

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    yRenderer.labels.template.set("visible", false);
    yRenderer.grid.template.set("stroke", am5.color(0xf1f1f1));

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.2,
        renderer: xRenderer,
        categoryField: "category",
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.data.setAll(data);

    xRenderer.labels.template.setAll({
      fontSize: "12px",
      fontWeight: "400",
      fill: am5.color(0x666666),
      maxWidth: 70,
      textAlign: "center",
      centerX: am5.p50,
      centerY: am5.p50,
      fontFamily: "Roboto",
    });

    const series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        valueField: "value",
        tooltip: am5.Tooltip.new(root, {}),
        stroke: am5.color(0x3d89df),
      })
    );

    series1.strokes.template.setAll({
      strokeDasharray: [5, 5],
    });

    function getFillColor(value: number): am5.Color {
      if (value < 2) {
        return am5.color(0xffdfdf);
      } else if (value >= 2 && value <= 4) {
        return am5.color(0xf9e5c2);
      } else {
        return am5.color(0xd8f3d2);
      }
    }

    const circleTemplate = am5.Template.new<am5.Circle>({});
    series1.bullets.push((root, _series, dataItem) => {
      const dataContext = dataItem.dataContext as { value: number };
      const value = dataContext.value;
      const color = getFillColor(value);
      const graphics = am5.Circle.new(
        root,
        {
          fill: color,
        },
        circleTemplate
      );
      return am5.Bullet.new(root, {
        sprite: graphics,
      });
    });

    series1.set("heatRules", [
      {
        target: circleTemplate,
        min: 5, 
        max: 32,
        dataField: "value",
        key: "radius",
      },
    ]);

    series1.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [chartID, data]);

  return <div id={chartID} style={{ height: 250, width: "100%" }}></div>;
}

export default BubbleChart;
