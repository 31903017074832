import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";

function SimpleLineChart(props: any) {
  const { chartID, data, benchmark, showBenchmark=true, height = 226 } = props;

  useLayoutEffect(() => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    var root = am5.Root.new(chartID, {
      tooltipContainerBounds: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      },
    });

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    var yRenderer = am5xy.AxisRendererY.new(root, {});

    // Craete Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxis.get("renderer").grid.template.set("forceHidden", false);
    yRenderer.labels.template.set("visible", true);

    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.2,
        renderer: xRenderer,
        categoryField: "category",
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.data.setAll(data);

    xRenderer.labels.template.setAll({
      fontSize: "12px",
      fontWeight: "400",
      fill: am5.color(0x666666),
      maxWidth: 70,
      textAlign: "center",
      centerX: am5.p50,
      centerY: am5.p50,
      fontFamily: "Roboto",
      paddingTop: 10
    });

    yRenderer.labels.template.setAll({
      fontSize: "12px",
      fontWeight: "400",
      fill: am5.color(0x666666),
      maxWidth: 70,
      textAlign: "center",
      centerX: am5.p50,
      centerY: am5.p50,
      fontFamily: "Roboto",
    });

    // Create series
    var series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: '{category}: {valueY}',
        }),
        stroke: am5.color(0x3d89df),
      })
    );

    if(showBenchmark){
      const range = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: benchmark,
          endValue: benchmark,
        })
      );

      range?.get("grid")?.setAll({
        stroke: am5.color(0x3185fc),
        strokeDasharray: [5, 5],
        strokeOpacity: 0.7,
      });
    }

    chart.set('cursor', am5xy.XYCursor.new(root, {}));

    series1.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [chartID, data, benchmark, showBenchmark]);

  return <div id={chartID} style={{ height: height, width: "100%" }}></div>;
}
export default SimpleLineChart;
