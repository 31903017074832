import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import DropdownImg from "../../assets/images/FilledDropDown.svg";
import { literals } from "../../theme/literals";
import { SelectDropdownOption } from "../../page/Admin/utils/globalType";
import DropdownList from "./DropdownList";
import "../../styles/component/CustomDropdown/CustomDropdown.scss";

interface CustomDropdownProps {
  options: SelectDropdownOption[];
  onChange: (selectedOption: SelectDropdownOption | null) => void;
  value: SelectDropdownOption | null;
  additionalProps?: any;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  onChange,
  value,
  additionalProps,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const triggerRef = useRef<HTMLDivElement | null>(null);

  const calcHeight = useMemo(
    () => (options?.length || 1) * literals.eachOptionHeight,
    [options]
  );

  const calculateDropdownPosition = useCallback(() => {
    if (!triggerRef.current) return { top: 0, left: 0, width: 0 };

    const rect = triggerRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    const minHeight = Math.min(literals.customDropdownHeight, calcHeight);

    let topPosition = rect.bottom;
    if (spaceBelow < minHeight) {
      topPosition = spaceAbove - minHeight + 15;
    }

    return {
      top: topPosition,
      left: rect.left,
      width: rect.width,
    };
  }, [calcHeight]);

  const handleToggleDropdown = useCallback(() => {
    if (!isDropdownOpen) {
      const position = calculateDropdownPosition();
      setDropdownPosition(position);
    }
    setIsDropdownOpen((prev) => !prev);
  }, [isDropdownOpen, calculateDropdownPosition]);

  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    },
    [closeDropdown]
  );

  const handleScroll = useCallback(() => {
    closeDropdown();
  }, [closeDropdown]);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
      document
        .querySelector(".ag-body-viewport")
        ?.addEventListener("scroll", handleScroll);
      document
        .querySelector(".ag-center-cols-viewport")
        ?.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("click", handleClickOutside);
      document
        .querySelector(".ag-body-viewport")
        ?.removeEventListener("scroll", handleScroll);
      document
        .querySelector(".ag-center-cols-viewport")
        ?.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document
        .querySelector(".ag-body-viewport")
        ?.removeEventListener("scroll", handleScroll);
      document
        .querySelector(".ag-center-cols-viewport")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [isDropdownOpen, handleClickOutside, handleScroll]);

  return (
    <div ref={triggerRef} className="custom-dropdown-container">
      <div
        onClick={handleToggleDropdown}
        className={`selected-dropdown-option ${additionalProps?.overrideSDOClass}`}
      >
        <span
          className="dropdown-value"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100% - 24px)",
          }}
          title={
            additionalProps?.parentLabel
              ? additionalProps?.parentLabel + value?.label
              : value?.label
          }
        >
          {(additionalProps?.selectedValueFormatter ? (
            additionalProps?.selectedValueFormatter(value)
          ) : (
            <>{value?.label}</>
          )) || "Select"}
        </span>
        <img
          className={`dropdown-image ${additionalProps?.overrideDIClass}`}
          src={DropdownImg}
          alt="dropdown"
        />
      </div>
      {isDropdownOpen &&
        options?.length > 0 &&
        ReactDOM.createPortal(
          <DropdownList
            options={options}
            onChange={(option) => {
              onChange(option);
              closeDropdown();
            }}
            position={dropdownPosition}
            value={value}
            props={additionalProps}
          />,
          document.body
        )}
    </div>
  );
};

export default CustomDropdown;
