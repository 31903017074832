import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc"; // Correct import
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface DataPoint {
  name: string;
  frequency: number;
}

interface WordChartProps {
  data: DataPoint[];
}

const WordChart: React.FC<WordChartProps> = ({ data = [] }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<am5.Root | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    if (rootRef.current) {
      rootRef.current.dispose();
    }
    const root = am5.Root.new(chartRef.current);
    rootRef.current = root;
    root.setThemes([am5themes_Animated.new(root)]);

    // Sorting and threshold logic
    const dataSet = [...data];
    dataSet.sort((a, b) => b.frequency - a.frequency);
    const groupSize = Math.ceil(dataSet.length / 3);
    const highThreshold = dataSet[groupSize - 1]?.frequency || 0;
    const mediumThreshold = dataSet[groupSize * 2 - 1]?.frequency || 0;

    const series = root.container.children.push(
      am5wc.WordCloud.new(root, {
        maxFontSize: am5.percent(8),
        minFontSize: am5.percent(7),
        categoryField: "name",
        valueField: "frequency",
        rotation: 0,
        centerX: am5.percent(0),
        centerY: am5.percent(0),
        angles: [0],
      })
    );

    series.labels.template.setAll({
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      textAlign: "center",
      tooltipText: "{category}: {value}",
      marginTop: 15,
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
    });

    // Dynamically setting the `fill` color for each label
    series.labels.template.adapters.add("fill", (fill, target) => {
      const dataItem: any = target.dataItem; // Access the associated data item
      const frequency: any = dataItem?.get("value"); // Retrieve the frequency field from data

      if (frequency >= highThreshold) {
        return am5.color(0xffffff);
      } else if (frequency >= mediumThreshold) {
        return am5.color(0x777777);
      } else {
        return am5.color(0x666666);
      }
    });

    series.labels.template.setup = function (label) {
      let backgroundColor = am5.color(0xffffff);
      let borderColor = am5.color(0x52a3ff);

      label.set(
        "background",
        am5.RoundedRectangle.new(root, {
          cornerRadiusBL: 20,
          cornerRadiusBR: 20,
          cornerRadiusTL: 20,
          cornerRadiusTR: 20,
          fill: backgroundColor,
          fillOpacity: 1,
          stroke: borderColor,
          strokeWidth: 1,
          marginTop: 15,
          marginBottom: 15,
          marginLeft: 15,
          marginRight: 15,
        })
      );

      label.events.on("dataitemchanged", function (ev) {
        var label: any = ev.target;
        const frequency = label.dataItem?.dataContext?.frequency; // Retrieve the frequency field from data
        if (frequency >= highThreshold) {
          backgroundColor = am5.color(0x52a3ff);
          borderColor = am5.color(0xffffff);
        } else if (frequency >= mediumThreshold) {
          backgroundColor = am5.color(0xdbecff);
          borderColor = am5.color(0xffffff);
        } else {
          backgroundColor = am5.color(0xffffff);
          borderColor = am5.color(0x7fb5f2);
        }

        label.set(
          "background",
          am5.RoundedRectangle.new(root, {
            fill: backgroundColor,
            fillOpacity: 1,
            stroke: borderColor,
            strokeWidth: 1,
            marginTop: 15,
            marginBottom: 15,
            marginLeft: 15,
            marginRight: 15,
          })
        );
      });
    };

    series.data.setAll(dataSet);
    return () => {
      root.dispose();
    };
  }, [data]);

  const getChartHeight = (dataLength: any) => {
    switch (true) {
      case !dataLength || dataLength < 20:
        return "230px";
      case dataLength < 35:
        return "400px";
      case dataLength < 50:
        return "650px";
      default:
        return "900px";
    }
  };
  const calcHeight = getChartHeight(data?.length);
  return <div ref={chartRef} style={{ width: "100%", height: calcHeight }} />;
};

export default WordChart;
