import React, { useState } from "react";
import SimpleLineChart from "../../component/LineChart/SimpleLineChart";
import "../../styles/page/Signals/Signals.scss";
import { colors } from "../../theme/colors";
import Text from "../../component/Text/Text";
import ProgressBar from "../../component/ProgressBar/ProgressBar";
import { literals } from "../../theme/literals";
import LineChart from "../../component/LineChart/LineChart";
import trendUp from "../../assets/images/trendUp.jpg";
import trendDown from "../../assets/images/trendDown.png";
import trendNeutral from "../../assets/images/neutral.png";
import { useGetDigitalQuery } from "./SignalsQuery";
import Dropdown from "../../component/Dropdown/Dropdown"; // Import Dropdown component
import { CommonWidgetInfo, getCommonWidgetInfo } from "./utils/getCommonWidgetInfo";
import { useGetCustomerVisitsQuery } from "./SignalsRestQuery";

const getTrendImage = (trend: string) => {
  switch (trend) {
    case literals.up:
      return trendUp;
    case literals.down:
      return trendDown;
    default:
      return trendNeutral;
  }
};

const getTrendSign = (trend: string) => {
  switch (trend) {
    case literals.up:
      return "+";
    case literals.down:
      return "-";
    default:
      return "+";
  }
};

export const monthMap: any = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sept",
  10: "Oct",
  11: "Nov",
  12: "Dec",
}

const getCustomerGraphData = (data = []) => {
  if(!data.length) return [];

  let graphData = [...data].reverse();
  return graphData.map((data: any) => ({
    value: data.value,
    category: monthMap[data.month]
  })) || [];
}

interface WidgetData extends CommonWidgetInfo {
  heading: string;
}

interface WidgetProps {
  heading: string;
  value: number;
  trend: string;
  trendValue: string;
  chartID: string;
  chartData: { month: number, value: number }[];
}

const filterOptions = [
  // { value: "month", label: "month" },
  // { value: "quarter", label: "quarter" },
  { value: "year", label: "year" }
]

const Digital: React.FC = () => {

  const [filterByDuration, setFilterByDuration] = useState(filterOptions[0].value);
  const { data: digitalData } = useGetDigitalQuery();
  const { averageCsat, customerEffort, likelihoodToReturn, taskCompletionRate } = digitalData?.digital || {};
  const { data: customerVisitsData } = useGetCustomerVisitsQuery({ duration: filterByDuration },{
    refetchOnMountOrArgChange: true
  });
  
  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterByDuration(event.target.value);
  };

  const customerVisitsGraphData = getCustomerGraphData(customerVisitsData)

  const widgetData: WidgetData[] = [
    { heading: "AVERAGE CSAT", ...getCommonWidgetInfo(averageCsat as any) },
    { heading: "CUSTOMER EFFORT", ...getCommonWidgetInfo(customerEffort  as any) },
    { heading: "TASK COMPLETION RATE", ...getCommonWidgetInfo(taskCompletionRate  as any) },
    { heading: "LIKELIHOOD TO RETURN", ...getCommonWidgetInfo(likelihoodToReturn  as any) },
  ];

  return (
    <div className="digital-container">
      <div className="digital-header-container">
        <Text styleName="Heading7" text="Customer Visits" color={colors.black} />
        <Dropdown 
          options={filterOptions}
          onChange={handleDropdownChange}
          value={filterByDuration}
        />
      </div>
      <div className="digital-chart-container">
        <SimpleLineChart
          chartID="bubble-chart-two"
          data={customerVisitsGraphData}
          showBenchmark={false}
        />
      </div>
      <div className="widgets">
        {widgetData.map((widget, index) => (
          <Widget
            key={'digital-widgets'+index}
            heading={widget.heading}
            value={widget.averageScore}
            trend={widget.trend}
            trendValue={widget.changePercent.toFixed(2)}
            chartID={`line-chart-${index + 1}`}
            chartData={widget.graphData}
          />
        ))}
      </div>
    </div>
  );
};

export default Digital;

const Widget: React.FC<WidgetProps> = ({ heading, value, trend, trendValue, chartID, chartData }) => (
  <div className="widget-outer-container">
    <Text styleName="Heading7" text={heading} color={colors.black} />
    <div className="widget-container">
      <div style={{ height: "100%" }}>
        <LineChart chartID={chartID} data={chartData} />
        <div className="cef-container">
          <Text color={colors.black} text={value?.toFixed(2)} styleName="Heading1" />
          <Text color={colors.black} text="/10" styleName="Heading4" />
        </div>
        <div className="trend-container-digital">
          <img
            src={getTrendImage(trend)}
            alt="trend"
            className="trend-img"
          />
          <Text
            styleName="body2"
            text={`(${getTrendSign(trend)}${trendValue}% ${
              literals.sinceLastMonth
            })`}
            color={colors.graySix}
          />
        </div>
        <div className="progress-container">
          <ProgressBar progress={value * 10} progressColor={trend === 'Down' ? '#DD524D' :'#40B869'}/>
        </div>
      </div>
    </div>
  </div>
);