export const RoutePath = {
  tenantHome: "/tenant",
  tenantCustomers: "/tenant/tenant-customers",
  tenantSignal: "/tenant/tenant-signal",
  tenantRelational: "/tenant/tenant-relational",
  tenantTransactional: "/tenant/tenant-transactional",
  tenantDigital: "/tenant/tenant-digital",
  tenantContent: "/tenant/tenant-content",
  tenantInsight: "/tenant/tenant-insight",
  tenantRanking: "/tenant/tenant-ranking",
  tenantProjectReport: "/tenant/tenant-project-report",
  tenantProjectBlockers: "/tenant/tenant-project-blockers",
  tenantNetBenefit: "/tenant/tenant-netBenefit",
  tenantSettings: "/tenant/tenant-settings",
  tenantSupport: "/tenant/tenant-support",
};
