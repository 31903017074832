import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";

export const CustomerSummaryDetailedViewQuery = createApi({
  reducerPath: "CustomerSummaryDetailedViewQuery",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getCustomerSummaryDetailedView: builder.query<any, { customerId: number, startDate: string; endDate: string }>({
      query: ({ customerId, startDate, endDate }) => ({
        body: gql`
         query {
          customerDetailedView(customerId: "${customerId}", startDate: "${startDate}", endDate: "${endDate}") {
            surveyStats {
              customerId
              surveysSent
              responsesReceived
            }
            npsScores {
              year
              quarter
              score
            }
            csatScores {
              tenantId
              year
              month
              score
            }
            chiScores {
              year
              month
              score
            }
            cesScores {
              year
              month
              score
            }
            feedbacks{
              respondentName
              email
              comment
              date
            }
          }
        }
        `,
        variables: { customerId },
      }),
    }),
  }),
});

export const { useGetCustomerSummaryDetailedViewQuery } = CustomerSummaryDetailedViewQuery;
