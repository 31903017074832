export const literals = {
  signals: "SIGNALS",
  viewAll: "View All",
  nps: "NPS",
  csat: "CSAT",
  totalCustomers: "Total Customers",
  viewSummary: "View Summary",
  chi: "CHI",
  ces: "CES",
  netBenefits: "NET BENEFITS TO DATE",
  netBenefitDetails: "NET BENEFIT",
  valueCaptured: "Value Captured",
  roi: "ROI",
  insights: "Insights",
  execution: "EXECUTION",
  customerSummary: "Customers Summary",
  npsTrend: "NPS Trend",
  npsAndCsatTrend: "NPS and CSAT Trend",
  surveysPlanned: "Surveys Planned",
  surveys: "Surveys",
  surveyStats: "Survey Stats",
  overallCsatTrend: "Overall CSAT Trend",
  csatTouchPoints: "CSAT Touchpoints",
  touchPoint: "Touchpoint",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot password?",
  submit: "Submit",
  newUser: "New user? ",
  clickHere: "Click here",
  toCreateAccount: " to create an account.",
  poweredBy: "powered by ",
  upload: "Upload",
  clientName: "CLIENT NAME",
  accountManager: "ACCOUNT MANAGER",
  onboardDate: "ONBOARD DATE",
  valCaptured: "VALUE CAPTURED",
  fileUploaded: "FILE UPLOADED",
  fieldOne: "clientName",
  fieldTwo: "accountManager",
  fieldThree: "onboardDate",
  fieldFour: "valueCaptured",
  fieldFive: "fileUploaded",
  fieldSix: "upload",
  uploadTypeError: "Invalid file type. Please upload an Excel file.",
  uploadHeader: "Upload and attach files",
  supportedFormats: "Supported formats: xls, xlsx",
  clickToUpload: "Click to upload or drag and drop.",
  maximumFile: "Maximum file size 50 MB.",
  failed: "FAILED",
  fileName: "FILE NAME",
  status: "STATUS",
  fieldSeven: "fileName",
  fieldEight: "status",
  errors: "Errors",
  field: "Field",
  rejectedValue: "Rejected Value",
  message: "Message",
  returned: "Returned",
  notReturned: "Not Returned",
  up: "Up",
  down: "Down",
  responseRate: "Response Rate",
  sinceLastMonth: "since last month",
  last1year: "Last 1 Year",
  sinceLastQuarter: "since last quarter",
  totalSurveys: "Total Surveys",
  loyal: "loyal",
  neutral: "neutral",
  uploadDate: "UPLOAD DATE",
  userManagement: "User Management",
  about: "About",
  ClientDetails: "Client Details",
  uploadFile: "UPLOAD FILE",
  customerEffortScore: "Customer Effort Score",
  logout: "Logout",
  privacyPloicy: "Privacy Policy",
  termsAndConditions: "Terms & conditions",
  tenantLiterals: {
    tenantId: "ID",
    tenantName: "NAME",
    address: "ADDRESS",
    city: "CITY",
    stateProvince: "STATE PROVINCE",
    postalCode: "POSTAL CODE",
    industry: "INDUSTRY",
    tenantIdField: "id",
    tenantNameField: "name",
    addressField: "address",
    cityField: "city",
    stateProvinceField: "stateProvince",
    postalCodeField: "postalCode",
    industryField: "industry",
    phone: "PHONE",
    url: "URL",
    revenue: "REVENUE",
    phoneField: "phone",
    urlField: "url",
    revenueField: "revenue",
  },
  userLiterals: {
    username: "USERNAME",
    password: "PASSWORD",
    role: "ROLE",
    firstName: "FIRST NAME",
    lastName: "LAST NAME",
    usernameField: "username",
    passwordField: "password",
    roleField: "role",
    firstNameField: "firstName",
    lastNameField: "lastName",
    userIdField: "id",
    userId: "USER ID",
  },
  newlyDiscovered: "Newly Discovered",
  readyForAction: "Ready for action",
  totalInsights: "Total Insights",
  newInsights: "New Insights",
  KeyInsights: "Key Insights",
  inFlightInsights: "In Flight",
  detailsOfKeyInsights: "Details of key insights",
  detractorSaying: "What are our detractors saying",
  detractorTopics: "Detractor Topics",
  detractorCommonWords: "Detractor Common Words",
  insightImpactAnalysis: "Insight Impact Analysis",
  key: "Key",
  rankings: "Rankings",
  ranking: "Ranking",
  projectScoring: "Project Scoring",
  projectEstimation: "Project Estimation",
  unlockNewFeatures: "Unlock New Features",
  comingSoon: "Coming Soon",
  contactSales: "Contact Sales",
  noDataAdded: "No Data Added",
  contentCsat: "CONTENT CSAT",
  interceptResponseRates: "Intercept Response Rates",
  contentEffectiveness: "CONTENT EFFECTIVENESS",
  segmentName: "Segment Name",
  addCustomerToSegment: "Add customer to a segment",
  newSegmentCreated: "New segment created",
  customerSegmentUpdated: "Customer Segment Updated",
  somethingWentWrong: "Something went wrong !",
  dataAddSuccess: "Data added successfully",
  dataDeleteSuccess: "Data deleted successfully",
  dataUpdateSuccess: "Data updated successfully",
  topicAlreadyExist: "Topic already exist",
  tagAlreadyExist: "Tag already exist",
  assigneeAlreadyExist: "Assignee already exist",
  approverAlreadyExist: "Approver already exist",
  contributorAlreadyExist: "Contributor already exist",
  informedAlreadyExist: "Informed already exist",
  X: "X",
  search: "Search",
  existingTag: "Existing Tags",
  existingAssignee: "Existing Assignees",
  existingApprover: "Existing Approvers",
  existingContributors: "Existing Contributors",
  existingInformed: "Existing Informed",
  detractorTopicCellWidth: 300,
  detractorTopicDropdownHeight: 300,
  dropdownHeight300: 300,
  cellWidth300: 300,
  yearCellWidth: 300,
  netBenefitsCellWidth: 300,
  detractorCellWidth: 300,
  customDropdownHeight: 250,
  executionTimelinePopup: 100,
  eachOptionHeight: 29,
  multiSelectCellWidth: 250,
  pending: "Pending",
  completed: "Completed",
  delayed: "Delayed",
  cancelled: "Cancelled",
  inProgress: "In Progress",
  bounced: "Bounced",
  complete: "Complete",
  addNew: "Add New",
  save: "Save",
  tooltipCustomMetric: "Select checkbox to view the metric on dashboard.",
  projectsCompletedToDate: "Projects Completed To Date",
  blockerToValueCapture: "Blocker To Value Capture",
  project: "project",
  keyIssues: "Key Issues",
  keyRisks: "Key Risks",
  overallROI: "Overall ROI",
  overallROIDescription: "Overall ROI from all the projects completed",
  addCustomMetric: "Add custom Metric",
  valueBreakdownPerProject: "VALUE BREAKDOWN PER PROJECT",
  overallAggregate: "OVERALL AGGREGATE",
  overallGraphDisplayNote:
    "*All the above graphs indicate overall aggregate values.",
  customMetricAddFirst:
    "Add Custom Metric first from the ADD NEW dropdown and then Add Row for selected Custom Metric",
  specialCharRegex: /[!@#$%^&*(),.?":{}|<>]/,
  userPasswordMinLength: 8,
  userPasswordMinLengthError: (length: number) =>
    `Password must be at least ${length} characters long.`,
  userPasswordSpecialCharError:
    "Password must include at least one special character.",
  owner: "Owner",
  lastUpdated: "Last Updated",
  driver: "Driver",
  approver: "Approver",
  contributors: "Contributors",
  informed: "Informed",
  objective: "Objective",
  dueDate: "Due Date",
  noTagsAvailable: "No tags available",
  noAssigneesAvailable: "No assignees available",
  noApproversAvailable: "No approvers available",
  noContributorsAvailable: "No contributors available",
  noInformedAvailable: "No informed available",
  editLabelHeader: "Edit Label Header",
  enterLabelName: "Please enter label name.",
  enterParamName: "Please enter new parameter name.",
  chiFormula: {
    chi: "CHI",
    nps: "NPS",
    csat: "CSAT",
    noNpsData: "Please enter NPS value",
    noCSATData: "Please enter CSAT value",
    noWeightData: "Please enter weight or % value",
    addNewParameter: "Add New Parameter",
  },
  none: "None",
  subTouchPointText: "sub touchpoint",
  noSubTouchPointAvailable: "no sub touchpoints available",
  duplicateProjectExist: "Duplicate projects exist",
  selectCsatCustomerError: "Please select customer",
  signalDetails: "Signal Details",
  surveySent: "SURVEY(S) SENT",
  surveySentText: "Survey Sent",
  surveyFailedText: "Failed",
  responsesReceived: "RESPONSES RECEIVED",
  surveysUnAnswered: "SURVEY(S) UNANSWERED",
  signalsReport: "Signals",
  touchPoints: "Touchpoints",
  relational: "Relational",
  transactional: "Transactional",
  digital: "Digital",
  content: "Content",
  feedback: "Feedback",
  responses: "Responses",
  surveyID: "Survey ID",
  home: "home",
  netBenefitsText: "net benefit",
  support: "support",
  settings: "settings",
  projectReport: "project report",
  projectBlockers: "blockers",
  customers: "Customers",
  commonWords: "Common Words",
  commonThemes: "Common Themes",
  keyIndicators: "key indicators",
  insightList: "Insight List",
  promoter: "Promoter",
  detractor: "Detractor",
  neutralNPS: "Neutral",
  enterNumberMsg: "Please enter number",
  numberRangeMsg: (min: number, max: number) =>
    `Please enter number between ${min} to ${max}`,
  invalidNumberFormat: "Invalid number format !",
  noSubTPMsg: "Sub touchpoint score can't be empty",
  summary: "Summary",
  easeImpactAnalysis: "Ease-Impact Analysis",
  easeText: "EASE",
  impactText: "IMPACT",
  insightDetails: "Insight Details",
  insight: "Insight",
  insightName: "Insight Name",
  uncoveredOn: "Uncovered On",
  currentStatus: "Current Status",
  projects: "Project(s)",
  attributions: "Attribution(s)",
  description: "Description",
  yes: "Yes",
  no: "No",
  filter: "Filter",
  date: "Date",
  dispositionStatus: "Disposition status",
  inflight: "Inflight",
  responsesSent: "Sent",
  responsesUnanswered: "Unanswered",
  responsesCompleted: "Completed",
  notFound: "404 - Page Not Found",
};
