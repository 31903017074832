export const tabs = ["Relational", "Transactional", "Digital", "Content"];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ContentInNeedGridColumns = {
  id: 'ID',
  content: 'CONTENT IN NEED OF ATTENTION',
  score: 'CSAT',
  comments: 'COMMENTS'
}

export const ContentMostSearcedGridColumns = {
  id: 'ID',
  content: 'MOST COMMON CONTENT SEARCHES',
  score: 'CSAT',
}