import { CellFocusedEvent } from 'ag-grid-community';

export const onCellFocused = (params:CellFocusedEvent) => {
  const cellEditorInstances = params.api.getCellEditorInstances();
  setTimeout(() => {
    cellEditorInstances.forEach((cellEditorInstance: any) => {
      const inputEle = cellEditorInstance?.eInput?.eGui?.querySelector('input');
      if(inputEle){
        inputEle.setAttribute('autocomplete', 'off');
      }
    });
  },100)
};