import React from "react";
import "../../../../styles/page/Version2/Home/InsightColumn/InsightStackedBarChart.scss";
import {
  getNumberLength,
  pixelForOneDigit,
} from "../../../Admin/utils/globalUtil";

interface InsightStackBarData {
  label: string;
  value: number;
  color: string;
}

interface InsightStackedBarChartProps {
  data: InsightStackBarData[];
  totalInsights: number;
}

export const InsightStackedBarChart: React.FC<InsightStackedBarChartProps> = ({
  data,
  totalInsights = 1,
}) => {
  const maxNumLength = getNumberLength(totalInsights);

  return (
    <div className="insight-stacked-bar-chart-container">
      {totalInsights > 0 && (
        <div className="bar-container">
          {data?.map((item, index) => {
            return (
              item?.value > 0 && (
                <div
                  key={`insight-bar-${index}`}
                  className="bar"
                  style={{
                    width: `${(item.value / totalInsights) * 100}%`,
                    backgroundColor: item.color,
                  }}
                />
              )
            );
          })}
        </div>
      )}

      <div className="legend">
        {data?.map((item, index) => (
          <div key={`insight-bar-item-${index}`} className="legend-item">
            <div className="dot" style={{ backgroundColor: item.color }}></div>
            <div className="label">
              <span
                className="Heading6 value-text"
                style={{ width: `${maxNumLength * pixelForOneDigit}px` }}
              >
                {item.value}
              </span>
              <span className="caption1 label-text">{item.label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
