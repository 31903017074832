import React from "react";
import Select from "react-select";
import { InBodyItemRenderer } from "./InBodyItemRenderer";
import "../../styles/component/InBodyCustomDropdown/InBodyCustomDropdown.scss";

export interface Option {
  label: string;
  value: string | number;
  customComponent?: React.ReactNode;
}

interface InBodyCustomDropdownProps {
  options: Option[];
  value: Option | null;
  onChange: any;
  onMenuOpen?: any;
  isDisabled?: boolean;
  customProps?: Record<string, any>;
  inputType?: "radio" | "checkbox";
  textColor?: string;
  customCLPrefix?: string; // pass custom-class prefix to override global react-select styling
  styleName?: string;
  width?: string;
}

const InBodyCustomDropdown: React.FC<InBodyCustomDropdownProps> = ({
  options,
  value,
  width,
  onChange,
  onMenuOpen = () => {},
  isDisabled = false,
  customCLPrefix,
  customProps,
  ...restProps
}) => {
  return (
    <div
      className="in-body-custom-dropdown-wrapper"
      style={{ width: width || "100%", textAlign: "start" }}
    >
      <Select
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        classNamePrefix={`general-select ${
          customCLPrefix ? customCLPrefix : ""
        }`}
        components={{
          Option: (props) => (
            <InBodyItemRenderer {...restProps} {...props} {...customProps} />
          ),
        }}
        menuPortalTarget={document.body} // Render dropdown outside of the scrollable container
        menuPosition="fixed" // Use fixed positioning to prevent layout shifts
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 10000 }), // Ensure dropdown appears on top
        }}
      />
    </div>
  );
};

export default InBodyCustomDropdown;
