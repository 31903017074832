import { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatDateFromCalendar } from '../../page/Version2/Insights/utils/gridUtility';
import '../../styles/component/CalendarPicker/CalendarPicker.scss';
import { GenericCustomStartEndDate } from '../../page/Admin/utils/globalUtil';

interface CalendarRangeProps {
    isCalendarOpen: boolean;
    setCalendarOpen: (open: boolean) => void;
    setCustomFilter: (filter: GenericCustomStartEndDate) => void;
    handleCalendarCustomChange: () => void;
}

export default function CalendarRange({ isCalendarOpen, setCalendarOpen, setCustomFilter, handleCalendarCustomChange }: CalendarRangeProps) {
    const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    return (
        <DatePicker
            open={isCalendarOpen}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
                setDateRange(update);
                if (update[0] && update[1]) {
                    handleCalendarCustomChange()
                    setCalendarOpen(false);
                    const startDate = new Date(update[0].getFullYear(), update[0].getMonth(), 1);
                    const endDate = new Date(update[1].getFullYear(), update[1].getMonth() + 1, 0);
                    setCustomFilter({
                        startDate: formatDateFromCalendar(startDate),
                        endDate: formatDateFromCalendar(endDate),
                    });
                }
            }}
            className="calendarContainer"
            showMonthYearPicker
            showMonthDropdown
            showYearDropdown
            dateFormat="MM/yyyy"
            maxDate={new Date()}
        />
    )
}
