import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../api/restBaseQuery";

export const CustomerSearchQuery = createApi({
  reducerPath: "CustomerSearchQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCustomerSearchData: builder.query({
      query: (searchText) => ({
        url: `customers/search?keyword=${searchText}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetCustomerSearchDataQuery } = CustomerSearchQuery;
