import { useState, useMemo } from "react";
import "../../styles/page/Signals/Signals.scss";
import NpsCsatTrendChart from "../../component/LineChart/NpsCsatTrendChart";
import { getFormattedNpsRelational } from "./utils/getFormattedNpsTrend";
import { getFormattedCsatTrendRelational } from "./utils/getFormattedCsatTrend";
import Text from "../../component/Text/Text";
import { literals } from "../../theme/literals";
import { colors } from "../../theme/colors";
import { useGetRelationalFilterQuery } from "./SignalsQuery";
import DonutChart from "../../component/DonutChart/DonutChart";
import trendUp from "../../assets/images/trendUp.jpg";
import ChevronRight from "../../assets/images/chevron-right.svg";
import trendDown from "../../assets/images/trendDown.png";
import trendNeutral from "../../assets/images/neutral.png";
import LineChart from "../../component/LineChart/LineChart";
import Loader from "../../component/Loader/Loader";
import { getFormattedCsat } from "./utils/getFormattedCsat";
import { getRelationalSurveyChartData, getResponseRate } from "./utils/relationalUtil";
import {
  GenericCustomStartEndDate,
  getNumberLength,
  pixelForOneDigit,
} from "../Admin/utils/globalUtil";
import EmptyStateComp from "../Version2/Home/EmptyStateComp";
import "../../styles/page/Signals/Relational.scss";
import FilterDropdown, {
  ScreenFilterDuration,
} from "../../component/FilterDropdown/FilterDropdown";
import { SelectDropdownOption } from "../Admin/utils/globalType";
import {
  canSkipQuery,
  getDurationFromStartEndDate,
  getQueryDateRange,
  getScreenFilterQueryDate,
} from "../Version2/Insights/utils/gridUtility";

const getTrendImage = (trend?: string) => {
  switch (trend) {
    case literals.up:
      return trendUp;
    case literals.down:
      return trendDown;
    default:
      return trendNeutral;
  }
};

const Relational = () => {
  const [activeFilter, setActiveFilter] = useState<SelectDropdownOption>(ScreenFilterDuration[2]);
  const [customFilter, setCustomFilter] = useState<GenericCustomStartEndDate>({});
  const { startDate, endDate } = getScreenFilterQueryDate(activeFilter);
  const { data, isFetching } = useGetRelationalFilterQuery(
    getQueryDateRange(activeFilter, customFilter, startDate, endDate),
    {
      skip: canSkipQuery(activeFilter, customFilter),
    }
  );
  const quarterWiseSurveyStats = data?.relational?.quarterWiseSurveyStats;
  const quarterSurvey = quarterWiseSurveyStats
    ? quarterWiseSurveyStats[0]
    : null;

  const surveyTitle = activeFilter.value === ScreenFilterDuration[0].value
    ? `Q${quarterSurvey?.quarter} - ${quarterSurvey?.year}`
    : getDurationFromStartEndDate(getQueryDateRange(activeFilter, customFilter, startDate, endDate));

  const trendData = useMemo(() => {
    let trendInPercentage: any = 0;
    let trend = literals.up;
    let surveySent = 0;

    quarterWiseSurveyStats?.forEach((survey) => { surveySent += survey.sent; });

    if ((quarterWiseSurveyStats?.length as number) > 1) {
      const [survA, survB] = quarterWiseSurveyStats as any[];
      trendInPercentage =
        ((survA.responseRate - survB.responseRate) / survB.responseRate) * 100;

      if (trendInPercentage <= 0) {
        trend = literals.down;
      }
      trendInPercentage = trendInPercentage.toFixed(2);
    }
    return { trendInPercentage, trend, surveySent };
  }, [quarterWiseSurveyStats]);

  const formattedNpsTrendDataMulti = useMemo(
    () => getFormattedNpsRelational(data?.relational?.npsTrend),
    [data?.relational?.npsTrend]
  );

  const formattedCsatTrendDataMulti = useMemo(
    () => getFormattedCsatTrendRelational(data?.relational?.csatTrend),
    [data?.relational?.csatTrend]
  );

  const showNpsCsatTrendChart = useMemo(
    () =>
      formattedNpsTrendDataMulti?.length > 0 ||
      formattedCsatTrendDataMulti?.length > 0,
    [formattedNpsTrendDataMulti, formattedCsatTrendDataMulti]
  );

  const chartData = useMemo(
    () => getRelationalSurveyChartData(quarterWiseSurveyStats),
    [quarterWiseSurveyStats]
  );

  const responseRate = useMemo(
    () => getResponseRate(quarterWiseSurveyStats),
    [quarterWiseSurveyStats]
  );

  const maxNumLength = useMemo(
    () => getNumberLength(quarterSurvey?.sent),
    [quarterSurvey?.sent]
  );

  const responseRateChartData = useMemo(
    () =>
      getFormattedCsat(
        quarterWiseSurveyStats?.map((survey, index) => ({
          tenantId: index.toString(),
          year: survey.year,
          month: survey.quarter * 3,
          score: survey.responseRate,
        }))
      ),
    [quarterWiseSurveyStats]
  );

  return (
    <>
      <div className="relational-container">
        <div className="heading-div">
          <div className="nps-csat-container">
            <div className="current-link-container">
              <Text
                color={colors.grayFour}
                text={literals.signals}
                styleName="body9 capitalize-first"
              />
              <>
                <img
                  src={ChevronRight}
                  alt="Arrow Right icon"
                  className="arrow-img"
                />
                <Text
                  color={colors.Light_Blue_PRI}
                  text={literals.relational}
                  styleName="body9"
                />
              </>
            </div>
            <FilterDropdown
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              setCustomFilter={setCustomFilter}
            />
          </div>
        </div>
        {isFetching ? (
          <Loader isLoading={isFetching} />
        ) : (
          <div className="content-div">
            <div className="rel-left-container">
              <Text
                styleName="Heading7"
                text={literals.npsAndCsatTrend}
                color={colors.black}
              />
              <div className="nps-trend-container">
                {showNpsCsatTrendChart ? (
                  <NpsCsatTrendChart
                    chartID="bubble-chart-two"
                    npsData={formattedNpsTrendDataMulti}
                    csatData={formattedCsatTrendDataMulti}
                    height="100%"
                  />
                ) : (
                  <EmptyStateComp containerMinHeight="24rem" />
                )}
              </div>
            </div>
            <div className="rel-right-container">
              <Text
                styleName="Heading7"
                text={literals.totalSurveys}
                color={colors.black}
              />
              <div className="total-surveys-container">
                {!!quarterWiseSurveyStats?.length && (
                  <div className="upper-div">
                    <div className="upper-div-left">
                      <Text
                        styleName="Heading6 uppercase-text"
                        text={literals.surveyStats}
                        color={colors.black}
                      />
                      <div className="survey-title">
                        <Text
                          styleName="caption2"
                          text={surveyTitle}
                          color={colors.black}
                        />
                      </div>
                    </div>
                    <Text
                      styleName="Heading2"
                      text={`${trendData.surveySent}`}
                      color={colors.black}
                    />
                    <div className="survey-donut-chart">
                      <DonutChart
                        chartID="donut-chart-two"
                        data={chartData as any}
                        showLegend={false}
                        height="91px"
                        width="91px"
                      />
                      <div className="lg-container">
                        {chartData.map((d, index) => (
                          <div key={index} className="legend-container">
                            <div
                              style={{ backgroundColor: d.color }}
                              className="legend-circle"
                            ></div>
                            <div className="label-container">
                              <div
                                className="value-container"
                                style={{
                                  width: `${maxNumLength * pixelForOneDigit}px`,
                                }}
                              >
                                <Text
                                  styleName="Heading5"
                                  text={`${d.value}`}
                                  color={colors.black}
                                />
                              </div>
                              <Text
                                styleName="caption1"
                                text={d.category}
                                color={colors.black}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {!!quarterWiseSurveyStats?.length && (
                  <div className="lower-div">
                    <Text
                      styleName="Heading6"
                      text={literals.responseRate}
                      color={colors.black}
                    />
                    <LineChart
                      chartID="line-chart-three"
                      data={responseRateChartData}
                      chartHeight="34px"
                      chartPadding={{
                        bottom: -13,
                        top: -8,
                        left: -30,
                        right: -30,
                      }}
                    />
                    <Text
                      styleName="Heading2"
                      text={`${responseRate}%`}
                      color={colors.black}
                    />
                    <div className="trend-container">
                      <img
                        src={getTrendImage(trendData.trend)}
                        alt="trend"
                        className="trend-img"
                      />
                      <Text
                        styleName="body2"
                        text={`(${trendData.trend === literals.up ? "+" : ""}${trendData.trendInPercentage
                          }% ${literals.sinceLastQuarter})`}
                        color={colors.graySix}
                      />
                    </div>
                  </div>
                )}
                {!quarterWiseSurveyStats?.length && (
                  <EmptyStateComp containerMinHeight="24rem" />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Relational;
