import BluePlusIcon from "../../../../assets/images/BluePlusIcon.svg";
import Text from "../../../../component/Text/Text";
import { colors } from "../../../../theme/colors";
import { literals } from "../../../../theme/literals";
import Button from "../../../../component/Button/Button";
import InBodyCustomDropdown from "../../../../component/InBodyCustomDropdown/InBodyCustomDropdown";
import { checkDuplicatesInObjectArray } from "../../../Admin/utils/globalUtil";
import toast from "react-hot-toast";
import { useState } from "react";
import { RightSidebar } from "../../../../component/RightSidebar/RightSidebar";
interface ProjectMetric {
  projectId?: string | null;
  value?: number | null;
  projectName: string;
}
const projectNameKey: keyof ProjectMetric = "projectName";
const projectIdKey: keyof ProjectMetric = "projectId";
const projectMetricValueKey: keyof ProjectMetric = "value";

export const NBRightSidebarProjectMetrics = ({
  handleCancelSidebar,
  projectOption,
  existedProjectMetrics,
  aggregateProjectMetricValue,
  params,
}: any) => {
  const [projectAggregate, setProjectAggregate] = useState(
    aggregateProjectMetricValue
  );
  const [projectMetrics, setProjectMetrics] = useState(
    existedProjectMetrics?.length > 0
      ? existedProjectMetrics
      : [{ [projectIdKey]: null, [projectMetricValueKey]: "" }]
  );
  const handleMetricUpdate = (
    index: number,
    valueToUpdate: string,
    changedValue: any
  ) => {
    let updatedMetrics = [...projectMetrics];
    if (valueToUpdate === projectIdKey) {
      updatedMetrics[index] = {
        ...updatedMetrics[index],
        [valueToUpdate]: changedValue?.value,
        [projectNameKey]: changedValue?.label,
      };
    } else if (valueToUpdate === projectMetricValueKey) {
      updatedMetrics[index] = {
        ...updatedMetrics[index],
        [valueToUpdate]: Number(changedValue),
      };
    }
    setProjectMetrics(updatedMetrics);
  };

  const handleSave = () => {
    const cleanedProjectMetrics = projectMetrics.filter(
      (project: any) => project[projectIdKey] !== null
    );
    const hasDuplicate = checkDuplicatesInObjectArray(
      cleanedProjectMetrics,
      projectIdKey
    );
    if (hasDuplicate) toast.error(literals.duplicateProjectExist);
    else {
      params.onValueChange({
        overallAggregateValue: projectAggregate,
        projectMetricValues: cleanedProjectMetrics,
      });
      params.stopEditing();
    }
  };

  const handleAddNewProject = () => {
    let newProjectMetric = [
      ...projectMetrics,
      { [projectIdKey]: null, [projectMetricValueKey]: "" },
    ];
    setProjectMetrics(newProjectMetric);
  };
  return (
    <RightSidebar
      handleCancelSidebar={handleCancelSidebar}
      overrideRSBClass="nb-custom-metric-right-sidebar"
      header={literals.valueBreakdownPerProject}
    >
      <div className="overall-aggregate-wrapper">
        <Text
          text={literals.overallAggregate}
          color={colors.grayFive}
          styleName="Heading7"
        />
        <input
          type="number"
          value={projectAggregate}
          onChange={(e) => setProjectAggregate(Number(e.target.value))}
        />
      </div>
      <div className="all-project-wrapper">
        {projectMetrics.map((projectMetric: ProjectMetric, index: number) => {
          const selectedProject = projectMetric?.[projectIdKey]
            ? {
                label: projectMetric?.[projectNameKey],
                value: projectMetric?.[projectIdKey],
              }
            : null;

          return (
            <div className="each-project-wrapper" key={`div-id${index}`}>
              <Text
                text={`${literals.project} ${index + 1}`}
                styleName="Heading7"
                color={colors.grayFive}
              />
              <div className="project-metric-value-wrapper">
                <InBodyCustomDropdown
                  options={projectOption || []}
                  value={selectedProject}
                  inputType="radio"
                  styleName="Heading8 uppercase-text label-text"
                  onChange={(changedValue: any) => {
                    handleMetricUpdate(index, projectIdKey, changedValue);
                  }}
                />
                <input
                  type="number"
                  value={projectMetric?.[projectMetricValueKey] || ""}
                  onChange={(e) =>
                    handleMetricUpdate(
                      index,
                      projectMetricValueKey,
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="add-new-project-wrapper">
        <Button
          type="tertiary"
          customClass="Caption2 add-new-button"
          onClick={() => handleAddNewProject()}
        >
          <img src={BluePlusIcon} alt="plus-icon" className="blue-plus-icon" />
          {literals.addNew}
        </Button>
      </div>
      <div className="save-button-wrapper">
        <Button type="primary" onClick={handleSave}>
          {literals.save}
        </Button>
      </div>
    </RightSidebar>
  );
};
