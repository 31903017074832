import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { colors } from "../../theme/colors";

export interface SignalTouchPointProps {
  metricValue: number
  benchmarkValue: number
  minThreshold: number
  maxThreshold: number
}

export interface SpeedoMeterChartProps {
  chartID: string;
  title?: string;
  signalTouchPointItem: SignalTouchPointProps;
}

const SpeedoMeterChart: React.FC<SpeedoMeterChartProps> = ({ chartID, signalTouchPointItem }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<am5.Root | null>(null);
  const { metricValue, benchmarkValue, minThreshold, maxThreshold } = signalTouchPointItem;

  useEffect(() => {
    if (!chartRef.current) return;
    if (rootRef.current) {
      rootRef.current.dispose();

      am5.array.each(am5.registry.rootElements, function (root) {
        if (root.dom.id === chartID) {
          root.dispose();
        }
      });
    }
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);
    rootRef.current = root;

    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
        radius: am5.percent(95),
        innerRadius: am5.percent(50),
      })
    );

    const axisRenderer = am5radar.AxisRendererCircular.new(root, {
      radius: am5.percent(100),
      innerRadius: -10,
      strokeOpacity: 0.1,
      minGridDistance: 60,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: minThreshold,
        max: maxThreshold,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    // Remove all value labels by default
    xAxis.get("renderer").labels.template.setAll({
      visible: false
    });

    // Show only min and max value labels
    xAxis.get("renderer").labels.template.adapters.add("visible", (visible, target) => {
      const value = target.dataItem?.get("value" as any);
      const id = target.dataItem?.get("id" as any);

      if (value === xAxis.get("min") || value === xAxis.get("max") || id === "benchmark") {
        return true;
      }
      return false;
    });


    // Set the color of the labels
    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(colors.grayTwo), // Set the color of the labels here
      textAlign: "center",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "300"
    });

    const axisDataItem = xAxis.makeDataItem({
      value: 0,
    });

    axisDataItem.animate({
      key: "value",
      to: metricValue,
      duration: 800,
      easing: am5.ease.out(am5.ease.cubic)
    });

    axisRenderer.ticks.template.setAll({
      visible: false,
      strokeOpacity: 0.5
    });

    axisRenderer.grid.template.setAll({
      visible: false
    });

    xAxis.createAxisRange(axisDataItem);

    const createRange = (start: any, end: any, color: any, isShowBenchmarkTooltip: boolean = false) => {
      var rangeDataItem = xAxis.makeDataItem({
        value: start,
        endValue: end,
      });

      xAxis.createAxisRange(rangeDataItem);

      const axisFill = rangeDataItem.get("axisFill");
      if (axisFill) {
        const fillProperties: am5.IGraphicsSettings = {
          visible: true,
          fill: am5.color(color),
          fillOpacity: color === colors.backgroundGray ? 0.5 : 1,
        };

        if (isShowBenchmarkTooltip && benchmarkValue) {
          let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getStrokeFromSprite: false,
            autoTextColor: false,
            getLabelFillFromSprite: false,
            labelText: "" + benchmarkValue,
          });

          tooltip.label.setAll({
            fill: am5.color(colors.white)
          });

          tooltip?.get("background")?.setAll({
            fill: am5.color(colors.black_gray),
            stroke: am5.color(colors.black_gray), // Set the border color
            strokeWidth: 0, // Set the border width
          });

          fillProperties.tooltip = tooltip;
          fillProperties.showTooltipOn = "hover";
          fillProperties.tooltipText = "" + benchmarkValue;
          if (metricValue >= benchmarkValue) {
            fillProperties.tooltipX = benchmarkValue;
          }
        }

        axisFill.setAll(fillProperties);
      }

      const tick = rangeDataItem.get("tick");
      if (tick) {
        tick.setAll({
          visible: false
        });
      }

      const rangeLabel = rangeDataItem.get("label");
      if (rangeLabel) {
        rangeLabel.setAll({
          visible: false // Hide the range labels
        });
      }
    }

    if (metricValue >= benchmarkValue) {
      createRange(minThreshold, metricValue, colors.Green, true);
      createRange(metricValue, maxThreshold, colors.backgroundGray);
    } else {
      createRange(minThreshold, metricValue, colors.red);
      createRange(metricValue, maxThreshold, colors.backgroundGray, true);
    }

    if (benchmarkValue) {
      const benchmarkDataItem = xAxis.makeDataItem({
        value: benchmarkValue,
        "id": "benchmark", // Add an id to the benchmark data item
      });

      xAxis.createAxisRange(benchmarkDataItem);

      benchmarkDataItem.get("grid")?.setAll({
        stroke: am5.color(colors.grayTwo),
        strokeWidth: 1,
        strokeOpacity: 1,
        visible: true,
      });

      benchmarkDataItem.get("label")?.setAll({
        text: "Benchmark",
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        inside: false,
        visible: true,
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "300",
        fill: am5.color(colors.grayTwo),
      });

      // Add a bullet with a rectangle shape to increase the stroke height
      benchmarkDataItem.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          sprite: am5.Rectangle.new(root, {
            width: 25,
            height: 1,
            centerX: am5.percent(70),
            centerY: am5.percent(70),
            fill: am5.color(colors.black),
          }),
        })
      );
    }

    axisDataItem.get("grid")?.set("visible", false);

    axisDataItem.get("label")?.set("visible", false);

    return () => {
      root.dispose();
    };
  }, [chartID, metricValue, benchmarkValue, maxThreshold, minThreshold]);

  return <div ref={chartRef} style={{ width: "100%", height: "120px" }} />;
};

export default SpeedoMeterChart;