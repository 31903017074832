import React from "react";
import "../../styles/component/Button/Button.scss";

export enum BtnType {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

interface ButtonProp {
  children: any;
  type: "primary" | "secondary" | "tertiary";
  customClass?: string;
  onClick?: () => any;
  disabled?: boolean;
}

const Button: React.FC<ButtonProp> = ({
  children,
  type,
  customClass = "",
  onClick = () => null,
  disabled = false,
}) => {
  return (
    <button
      className={`app-btn ${type} ${customClass}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default React.memo(Button);
