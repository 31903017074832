import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const ExecutionProjectQuery = createApi({
  reducerPath: "ExecutionProjectQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getExecutionProject: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/projects/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        return response.map((item: any) => {
          return {
            ...item,
            insights: item.insightName
              ? {
                  name: item.insightName,
                  id: item.insightId,
                }
              : null,
          };
        });
      },
    }),
    addExecutionProject: builder.mutation({
      query: (projectData) => {
        return {
          url: `/admin/projects/tenants/${projectData.tenantId}`,
          method: "POST",
          body: getBody(projectData),
        };
      },
    }),
    updateExecutionProject: builder.mutation({
      query: (projectData) => ({
        url: `/admin/projects/tenants/${projectData.tenantId}/${projectData.id}`,
        method: "PUT",
        body: getBody(projectData),
      }),
    }),
    removeExecutionProject: builder.mutation({
      query: ({ tenantId, id: projectId }) => ({
        url: `/admin/projects/tenants/${tenantId}/${projectId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetExecutionProjectQuery,
  useAddExecutionProjectMutation,
  useUpdateExecutionProjectMutation,
  useRemoveExecutionProjectMutation,
} = ExecutionProjectQuery;

const getBody = (bodyData: any) => {
  const { insightName, insights, tenantId, ...restData } = bodyData;
  const body = {
    ...restData,
    insightId: insights?.id,
    pctComplete: parseFloat(restData.pctComplete),
  };
  if (!body.insightId) {
    delete body.insightId;
  }
  return body;
};
