import { useRef } from "react";
import { GridTableProps } from "../../component/CustomAgGridCRUD/CustomAgGridCRUD";
import { ContentInNeedGridColumns, ContentMostSearcedGridColumns } from "../../constants/Signals.constants";
import { getFieldsFromColumn } from "../ClientDetails/utils/GridOps";
import { ColDef } from "ag-grid-community";

export const contentInNeedGridField = getFieldsFromColumn(ContentInNeedGridColumns);
export const contentInNeedGridColumn: ColDef[] = [
  { headerName: ContentInNeedGridColumns.content, field: contentInNeedGridField.content, flex: 1, minWidth: 255 },
  { headerName: ContentInNeedGridColumns.score, field: contentInNeedGridField.score, maxWidth: 80 },
  { headerName: ContentInNeedGridColumns.comments, field: contentInNeedGridField.comments, flex: 1 },
];

export const contentMostSearcedGridField = getFieldsFromColumn(ContentMostSearcedGridColumns);
export const contentMostSearcedGridColumn: ColDef[] = [
  { headerName: ContentMostSearcedGridColumns.content, field: contentMostSearcedGridField.content, flex: 1 },
  { headerName: ContentMostSearcedGridColumns.score, field: contentMostSearcedGridField.score, flex: 1 },
];

export const useContentGridProp = ({
  data,
  isLoading,
  column,
  idColumnField
}: any): GridTableProps => {

  const empty = () => ([{}, {}])
  return {
    props: {
      colDefs: column,
      addEmptyRow: empty,
      addDataMutation: empty,
      updateDataMutation: empty,
      deleteDataMutation: empty,
      idColumnField: idColumnField,
      gridData: data || [],
      isLoading,
      refetch: empty,
      isNavigation: false,
      Navigation: "",
      readonly: true
    },
    ref: useRef(null)
  }
}