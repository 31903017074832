import { colors } from "../../../theme/colors";
import { literals } from "../../../theme/literals";
import { toast } from "react-hot-toast";
import { monthMap } from "../../Signals/Digital";
export const ALLOWED_KEYS = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
export const DECIMAL_REGEX = /^\d*\.?\d*$/;
export const NUMBER_REGEX = /^\d$/;
export const NON_NUMERIC_REGEX = /[^0-9.]/g;
export const pixelForOneDigit = 12;
export const headerHeight = 54;
export const profilePic = "https://picsum.photos/28";
export const LEADING_NUMBER_REGEX = /^(\d+)/;

type GenericObject = {
  [key: string]: any;
};
export type GenericSelectOption = Record<string, any>;
export interface GenericCustomStartEndDate {
  startDate?: string;
  endDate?: string;
}

enum AllStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  DELAYED = "DELAYED",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  KEY_RISKS = "KEY_RISKS",
  KEY_ISSUES = "KEY_ISSUES",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export const BackgroundColorByStatus: any = {
  [AllStatus.PENDING]: colors.pendingBGColor,
  [AllStatus.COMPLETED]: colors.tertiaryGreen,
  [AllStatus.NOT_STARTED]: colors.notStartedBGColor,
  [AllStatus.CANCELLED]: colors.cancelledBGColor,
  [AllStatus.DELAYED]: colors.delayedBGColor,
  [AllStatus.IN_PROGRESS]: colors.pendingBGColor,
  [AllStatus.DONE]: colors.tertiaryGreen,
  [AllStatus.KEY_RISKS]: colors.keyRiskBGColor,
  [AllStatus.KEY_ISSUES]: colors.keyIssueBGColor,
  [AllStatus.LOW]: colors.lowIssueBGColor,
  [AllStatus.MEDIUM]: colors.mediumIssueBGColor,
  [AllStatus.HIGH]: colors.highIssueBGColor,
};

export const checkDuplicatesInObjectArray = <T extends GenericObject>(
  arr: T[],
  key: keyof T
): boolean => {
  const countMap: Record<string, number> = arr.reduce((acc, item) => {
    const keyValue = item[key] as string; // Ensure key value is treated as a string
    acc[keyValue] = (acc[keyValue] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return Object.values(countMap).some((count) => count > 1);
};

export const getSelectOptions = (
  options: GenericSelectOption[], // Array of objects where each object can have dynamic keys
  mappedLabelKey: string, // Key to extract the label from the passed options array
  mappedLabelValue: string, // Key to extract the value from the passed options array
  haveNoneOption: boolean = false, // is new options array have none as a select option ?
  labelKey: string = "label", // Key to use for label in the new options array
  valueKey: string = "value" // Key to use for value in the new options array
): GenericSelectOption[] => {
  let updatedOptions: GenericSelectOption[] = [];

  if (options?.length) {
    // Start with an entry for 'none' value
    if (haveNoneOption) {
      updatedOptions = [{ [labelKey]: literals.none, [valueKey]: null }];
    }
    options.forEach((item) => {
      updatedOptions.push({
        [labelKey]: item[mappedLabelKey],
        [valueKey]: item[mappedLabelValue],
      });
    });
  }

  return updatedOptions;
};

export const hexToRgb = (hex: number): string => {
  return `#${hex.toString(16).padStart(6, "0")}`;
};

export const allowOnlyNumbers = (
  event:
    | React.ChangeEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLInputElement>
    | React.ClipboardEvent<HTMLInputElement>,
  oldValue: any,
  min: number = Number.MIN_SAFE_INTEGER,
  max: number = Number.MAX_SAFE_INTEGER
) => {
  const { value } = event.currentTarget;

  // If the event is a paste event
  if (event.type === "paste") {
    const pastedData = (
      event as React.ClipboardEvent<HTMLInputElement>
    ).clipboardData.getData("text");
    if (!DECIMAL_REGEX.test(pastedData)) {
      event.preventDefault();
      toast.error(literals.enterNumberMsg);
      return;
    }
    const pastedNumber = parseFloat(pastedData);
    if (pastedNumber < min || pastedNumber > max) {
      event.preventDefault();
      toast.error(literals.numberRangeMsg(min, max));
    }
  }

  // If the event is a keyboard event (e.g., typing)
  if (event.type === "keydown") {
    const key = (event as React.KeyboardEvent<HTMLInputElement>).key;
    if (ALLOWED_KEYS.includes(key)) return;
    if (key === "." && !value.includes(".")) return;
    if (!NUMBER_REGEX.test(key)) {
      event.preventDefault();
      toast.error(literals.enterNumberMsg);
      return;
    }
  }

  // If the event is a change event (e.g., input value changed)
  if (event.type === "change") {
    let numericValue = value.replace(NON_NUMERIC_REGEX, ""); // Remove non-numeric characters except dot

    // Check for multiple dots and correct decimal format
    if ((numericValue.match(/\./g) || []).length > 1) {
      toast.error(literals.invalidNumberFormat);
      numericValue = oldValue; // Revert to old value
    }

    // Check if the value is within the specified range
    if (numericValue) {
      const num = parseFloat(numericValue);
      if (num < min || num > max) {
        toast.error(literals.numberRangeMsg(min, max));
        numericValue = oldValue; // Revert to old value
      }
    }

    event.currentTarget.value = numericValue;
  }
};

export const padStartWithZero = (num: number, length: number = 2): string => {
  if (num === 0) return "0";
  return num?.toString()?.padStart(length, "0");
};

export const getMonthName = (date: string): string => {
  if (!date) return "";
  const month = parseInt(date?.split("-")[1]);
  return monthMap[month];
};

export const getNumberLength = (num: number | any) =>
  num?.toString()?.length || 1;

export const findMaxNumber = (
  data: { [key: string]: any }[],
  key: string,
  nullMaxNumber: number = 10
): number => {
  if (!data || data?.length === 0) return nullMaxNumber;
  return Math.max(...data.map((item) => item?.[key]));
};

export const getNumericValueFromStringNumber = (timeframe: string) => {
  const match = timeframe?.match(LEADING_NUMBER_REGEX); // Extract leading number
  return match ? parseInt(match[0], 10) : Infinity; // Default to Infinity for "12+ Months"
};

export const objectSortComparator = (keyToCompare: string) => {
  return (a: GenericObject, b: GenericObject) => {
    const valueA = a?.[keyToCompare]?.toLowerCase() || "";
    const valueB = b?.[keyToCompare]?.toLowerCase() || "";
    return valueA.localeCompare(valueB);
  };
};

export const getTimelineSortValue = (timeline: any) => {
  const year = timeline?.year || 0;
  const quarter = timeline?.quarter?.replace(/\D/g, "") || 0; // Extract numeric quarter
  return year * 10 + parseInt(quarter, 10); // Convert "Q1" -> 1, "Q2" -> 2, etc.
};
