import { useState, useCallback } from "react";
import InBodyCustomDropdown from "../InBodyCustomDropdown/InBodyCustomDropdown";
import { SelectDropdownOption } from "../../page/Admin/utils/globalType";
import "../../styles/component/FilterDropdown/FilterDropdown.scss";
import CalendarMonth from "../../assets/images/calendar-month.svg";
import CalendarRange from "../CalendarRange/CalendarRange";

export enum ScreenFilterEnum {
    LAST_THREE_MONTHS = "3month",
    LAST_SIX_MONTHS = "6month",
    LAST_YEAR = "1year",
    CUSTOM = "CUSTOM",
}

export const ScreenFilterDuration = [
    { label: "Last 3 Months", value: ScreenFilterEnum.LAST_THREE_MONTHS },
    { label: "Last 6 Months", value: ScreenFilterEnum.LAST_SIX_MONTHS },
    { label: "Last Year", value: ScreenFilterEnum.LAST_YEAR },
    { label: "Custom", value: ScreenFilterEnum.CUSTOM },
];

interface FilterDropdownProps {
    activeFilter: SelectDropdownOption;
    setActiveFilter: (filter: SelectDropdownOption) => void;
    setCustomFilter: (filter: any) => void;
}

export default function FilterDropdown({ activeFilter, setActiveFilter, setCustomFilter }: FilterDropdownProps) {
    const [isCalendarOpen, setCalendarOpen] = useState(false);

    const handleFilterChange = useCallback(
        (selectedFilter: SelectDropdownOption) => {
            if (selectedFilter?.value !== activeFilter?.value) {
                setActiveFilter(selectedFilter);
                if (selectedFilter.value === ScreenFilterEnum.CUSTOM) {
                    setCalendarOpen(true);
                }
            }
        },
        [activeFilter, setActiveFilter]
    );

    const handleCalendarCustomChange = () => {
        if (activeFilter?.value !== ScreenFilterEnum.CUSTOM) {
            setActiveFilter(ScreenFilterDuration[3]);
        }
    }

    const onCalendarClick = () => {
        setCalendarOpen(!isCalendarOpen);
    };

    return (
        <div className="filter-container">
            <InBodyCustomDropdown
                options={ScreenFilterDuration}
                value={activeFilter}
                onMenuOpen={() => setCalendarOpen(false)}
                onChange={handleFilterChange}
                inputType="radio"
                styleName="body9 uppercase-text label-text"
            />
            <img src={CalendarMonth} alt="Calendar-Month" className="calendar-icon" onClick={onCalendarClick} />
            <CalendarRange isCalendarOpen={isCalendarOpen} setCalendarOpen={setCalendarOpen} setCustomFilter={setCustomFilter} handleCalendarCustomChange={handleCalendarCustomChange} />
        </div>
    );
}
