import { createApi } from "@reduxjs/toolkit/query/react";
import {
  DashboardAPIResponse,
  DigitalResponse,
  NpsQuarterData,
  QuarterData,
  RelationalResponse,
  TransactionalResponse,
} from "./SignalsQuery.type";
import { gql } from "graphql-request";
import { graphqlBaseQuery } from "../../api/graphqlBaseQuery";
import { numberUpto2Decimal } from "../Home/utils/executionProjectUtil";

export const Signals = createApi({
  reducerPath: "signals",
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getCsat: builder.query<any, void>({
      query: () => ({
        body: gql`
          query {
            signals {
              lastFourQuartersNps {
                year
                quarter
                score
              }
              lastFourQuartersCsat {
                tenantId
                year
                month
                score
              }
              customerHealthSummary {
                loyalCustomers {
                  id
                  score
                  date
                  customer
                  trend
                }
                riskCustomers {
                  id
                  score
                  date
                  customer
                  trend
                }
                loyalCustomersLabel
                riskCustomersLabel
                percentageLoyal
                percentageRisk
                percentageNeutral
              }
              totalCustomers
            }
          }
        `,
      }),
      transformResponse: (response: any) => response,
    }),
    getKeyIndicatorSignalAndInsight: builder.query<any, void>({
      query: () => ({
        body: gql`
          query {
            insights {
              tenantId
              insightsSummary {
                total
                promoted
                newlyDiscovered
                promotedAndNewlyDiscovered
                inflight
                completed
                pending
                delayed
              }
            }
            surveyStats {
              sent
              failed
              bounced
              completed
            }
            signals {
              lastFourQuartersNps {
                year
                quarter
                score
              }
              lastFourQuartersCsat {
                tenantId
                year
                month
                score
              }
              customerHealthSummary {
                topPositiveTrends {
                  customerId
                  customerName
                  latestPercentageChange
                  dataPoints {
                    date
                    value
                  }
                }
                topNegativeTrends {
                  customerId
                  customerName
                  latestPercentageChange
                  dataPoints {
                    date
                    value
                  }
                }
                loyalCustomers {
                  id
                  score
                  date
                  customer
                  trend
                }
                riskCustomers {
                  id
                  score
                  date
                  customer
                  trend
                }
                loyalCustomersLabel
                riskCustomersLabel
              }
            }
          }
        `,
      }),
      transformResponse: (response: DashboardAPIResponse) => {
        return {
          ...response,
          signals: {
            ...response?.signals,
            customerHealthSummary: {
              ...response?.signals?.customerHealthSummary,
              topPositiveTrends:
                response?.signals?.customerHealthSummary?.topPositiveTrends?.map(
                  (item) => ({
                    ...item,
                    dataPoints: item?.dataPoints?.reverse()?.map((data) => ({
                      ...data,
                      value: numberUpto2Decimal(data?.value, false, 1),
                    })),
                    latestPercentageChange: numberUpto2Decimal(
                      item?.latestPercentageChange
                    ),
                  })
                ),
              topNegativeTrends:
                response?.signals?.customerHealthSummary?.topNegativeTrends?.map(
                  (item) => ({
                    ...item,
                    dataPoints: item?.dataPoints?.reverse()?.map((data) => ({
                      ...data,
                      value: numberUpto2Decimal(data?.value, false, 1),
                    })),
                    latestPercentageChange: numberUpto2Decimal(
                      item?.latestPercentageChange
                    ),
                  })
                ),
            },
            lastFourQuartersCsat: response?.signals?.lastFourQuartersCsat?.map(
              (item: QuarterData) => ({
                ...item,
                score: numberUpto2Decimal(item?.score),
              })
            ),
            lastFourQuartersNps: response?.signals?.lastFourQuartersNps?.map(
              (item: NpsQuarterData) => ({
                ...item,
                score: numberUpto2Decimal(item?.score),
              })
            ),
          },
        };
      },
    }),
    getInsightSummary: builder.query<any, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        body: gql`
          query {
            insights {
              insightsSummary(startDate: "${startDate}", endDate: "${endDate}") {
                total
                promoted
                newlyDiscovered
                promotedAndNewlyDiscovered
                inflight
                completed
                pending
                delayed
              }
              allInsights(startDate: "${startDate}", endDate: "${endDate}") {
                id
                date
                title
                description
                frictionDescription
                status
                promoted
                newlyDiscovered
                importance
                customersImpacted
                severity
                frequency
                customerCentricScore
                businessValue
                implementationEase
                strategicAlignment
                orgCentricScore
                overallScore
                insightImplementationTimeFrame
                label
              }
            }
          }
        `,
        variables: { startDate, endDate },
      }),
      transformResponse: (response: DashboardAPIResponse) => {
        return response;
      },
    }),
    getTransactional: builder.query<TransactionalResponse, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        body: gql`
          query Transactional {
            transactional {
              customerEffortScore(startDate: "${startDate}", endDate: "${endDate}") {
                tenantId
                date
                year
                month
                quarter
                type
                value
              }
              csatBenchmark
              csatTrend(startDate: "${startDate}", endDate: "${endDate}") {
                tenantId
                score
                month
                year
              }
              csatTouchPoints(startDate: "${startDate}", endDate: "${endDate}") {
                tenantId
                touchpoint
                score
              }
            }
          }
        `,
        variables: { startDate, endDate },
      }),
      transformResponse: (response: TransactionalResponse) => response,
    }),
    getRelational: builder.query<RelationalResponse, void>({
      query: () => ({
        body: gql`
          query Relational {
            surveyStats {
              sent
              failed
              bounced
              completed
            }
            relational {
              npsBenchmark
              npsTrend {
                year
                quarter
                score
              }
              csatTrend {
                tenantId
                year
                month
                score
              }
              surveys {
                id
                title
                type
                customerSegment
                startDate
                endDate
                status
                numSent
                responsesReceived
              }
              quarterWiseSurveyStats {
                quarter
                year
                sent
                returned
                responseRate
              }
            }
          }
        `,
      }),
      transformResponse: (response: RelationalResponse) => {
        return {
          ...response,
          relational: {
            ...response?.relational,
            quarterWiseSurveyStats:
              response?.relational?.quarterWiseSurveyStats?.map((item) => ({
                ...item,
                responseRate: numberUpto2Decimal(item?.responseRate),
              })),
          },
        };
      },
    }),
    getRelationalFilter: builder.query<RelationalResponse, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        body: gql`
          query Relational {
            relational {
              npsTrend(startDate: "${startDate}", endDate: "${endDate}") {
                year
                quarter
                score
              }
              csatTrend(startDate: "${startDate}", endDate: "${endDate}") {
                tenantId
                year
                month
                score
              }
              quarterWiseSurveyStats(startDate: "${startDate}", endDate: "${endDate}") {
                quarter
                year
                sent
                returned
                responseRate
              }
            }
          }
        `,
        variables: { startDate, endDate },
      }),
      transformResponse: (response: RelationalResponse) => {
        return {
          ...response,
          relational: {
            ...response?.relational,
            quarterWiseSurveyStats:
              response?.relational?.quarterWiseSurveyStats?.map((item) => ({
                ...item,
                responseRate: numberUpto2Decimal(item?.responseRate),
              })),
          },
        };
      },
    }),
    getDigital: builder.query<DigitalResponse, void>({
      query: () => ({
        body: gql`
          query Digital {
            digital {
              averageCsat {
                date
                year
                month
                quarter
                type
                value
              }
              customerEffort {
                date
                year
                month
                quarter
                type
                value
              }
              taskCompletionRate {
                date
                year
                month
                quarter
                type
                value
              }
              likelihoodToReturn {
                date
                year
                month
                quarter
                type
                value
              }
            }
          }
        `,
      }),
      transformResponse: (response: DigitalResponse) => response,
    }),
    getContent: builder.query<any, void>({
      query: () => ({
        body: gql`
          {
            content {
              contentEffectiveness {
                tenantId
                content
                score
                date
                mostCommonlySearched
                needsAttention
                comments
                year
                month
                quarter
              }
              mostCommonSearches {
                tenantId
                content
                score
                date
                mostCommonlySearched
                needsAttention
                comments
                year
                month
                quarter
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response,
    }),
    getTouchPoints: builder.query<any, { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => ({
        body: gql`
          query Signals {
            signals {
              relationalMetric(startDate: "${startDate}", endDate: "${endDate}") {
                metricValue
                benchmarkValue
                minThreshold
                maxThreshold
              }
              transactionalMetric(startDate: "${startDate}", endDate: "${endDate}") {
                metricValue
                benchmarkValue
                minThreshold
                maxThreshold
              }
              digitalMetric(startDate: "${startDate}", endDate: "${endDate}") {
                metricValue
                benchmarkValue
                minThreshold
                maxThreshold
              }
              contentMetric(startDate: "${startDate}", endDate: "${endDate}") {
                metricValue
                benchmarkValue
                minThreshold
                maxThreshold
              }
            }
          }
        `,
        variables: { startDate, endDate },
      }),
      transformResponse: (response: any) => ({
        ...response,
        signals: {
          ...response?.signals,
          relationalMetric: {
            ...response?.signals?.relationalMetric,
            metricValue:
              numberUpto2Decimal(
                response?.signals?.relationalMetric?.metricValue,
                false,
                0
              ) || 0,
          },
          transactionalMetric: {
            ...response?.signals?.transactionalMetric,
            metricValue:
              numberUpto2Decimal(
                response?.signals?.transactionalMetric?.metricValue,
                false,
                2
              ) || 0,
          },
          digitalMetric: {
            ...response?.signals?.digitalMetric,
            metricValue:
              numberUpto2Decimal(
                response?.signals?.digitalMetric?.metricValue,
                false,
                2
              ) || 0,
          },
          contentMetric: {
            ...response?.signals?.contentMetric,
            metricValue:
              numberUpto2Decimal(
                response?.signals?.contentMetric?.metricValue,
                false,
                2
              ) || 0,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetCsatQuery,
  useGetKeyIndicatorSignalAndInsightQuery,
  useGetInsightSummaryQuery,
  useGetTransactionalQuery,
  useGetRelationalQuery,
  useGetRelationalFilterQuery,
  useGetDigitalQuery,
  useGetContentQuery,
  useGetTouchPointsQuery,
} = Signals;
