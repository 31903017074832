import React, { useEffect } from "react";
//import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { clearToken } from "../../page/Login/authSlice";
import { useLazyGetConfigurationQuery } from "./HeaderQuery";

import "../../styles/feature/Header/Header.scss";
//Uncomment later when nav bar code is uncommented
// import { getNavBar } from "../../AppRoute/constants";
// import { IRoute } from "../../AppRoute/model";
import vistaxmLogo from "../../assets/images/VistaXM-logo-light.png";
import { clearConfig, setLogo, setTheme, setFeatureLocks } from "./ConfigSlice";
import UserSection from "./UserSection";
import { UserRole } from "../../page/Admin/utils/globalType";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, loginInfo } = useSelector((state: RootState) => state?.auth);
  const { logo, theme } = useSelector((state: RootState) => state.config);
  const [getConfiguration] = useLazyGetConfigurationQuery();
  const isUserTenant = token && loginInfo?.scope === UserRole.TENANT;
  useEffect(() => {
    if (isUserTenant) {
      getConfiguration()
        .unwrap()
        .then((result) => {
          dispatch(setLogo(result?.configuration?.logo));
          dispatch(setTheme(result?.configuration?.theme));
          dispatch(setFeatureLocks(result?.configuration));
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [isUserTenant, getConfiguration, dispatch]);

  const logout = () => {
    dispatch(clearToken());
    dispatch(clearConfig());
    dispatch({ type: 'RESET' })
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="navbar" style={{ backgroundColor: theme || "" }}>
      <img
        src={isUserTenant && logo ? logo : vistaxmLogo}
        alt="logo"
        className="logo"
      />
      <ul className="navbar-nav">
        {!token || loginInfo?.scope === UserRole.ADMIN ? (
          <></>
        ) : (
          <></>
          //Uncomment later when we have pages for nav bar items
          //   getNavBar?.map((route: IRoute) => {
          //     return (
          //       <li className="navbar-item pointer" key={route.path}>
          //         <NavLink className="navbar-link" to={route.path}>
          //           {route.name}
          //         </NavLink>
          //       </li>
          //     );
          //   })
        )}
        {token && (
          <UserSection
            username={`${loginInfo.firstName} ${loginInfo.lastName}`}
            onLogout={logout}
          />
        )}
      </ul>
    </div>
  );
};

export default Header;
