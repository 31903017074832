import React from 'react';
import '../../styles/component/ProgressBar/ProgressBar.scss';

interface ProgressBarProps {
    progress: number;
    backgroundColor?: string;
    progressColor?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, backgroundColor = '#f3f3f3', progressColor = '#4caf50' }) => {
    return (
        <div className='progress-bar-container' style={{ backgroundColor }}>
            <div className='progress-bar' style={{ width: `${progress}%`, backgroundColor: progressColor }} />
        </div>
    );
};

export default ProgressBar;
