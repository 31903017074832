import { ColDef } from "ag-grid-community";
import { getFieldsFromColumn } from "../../../ClientDetails/utils/GridOps";
import { InsightGridColumns } from "../../../../constants/ClientDetails";
import { InsightHomeQueryInterface } from "../../../Signals/SignalsQuery.type";
import { literals } from "../../../../theme/literals";
import { InsightStatusEnum } from "../../../Ranking/ProjectInsights.type";
import { InsightImpactAnalysisType } from "../../../InsightAnalysis/InsightAnalysis.type";
import { ScreenFilterEnum } from "../../../../component/FilterDropdown/FilterDropdown";
import { SelectDropdownOption } from "../../../Admin/utils/globalType";
import { GenericCustomStartEndDate } from "../../../Admin/utils/globalUtil";
import { months } from "../../../../constants/Signals.constants";

export const insightGridField = getFieldsFromColumn(InsightGridColumns);

export const getInsightTableColumnDefs = (
  data: any[] | undefined,
  InsightCellRenderer: React.FC<any>,
  handleOnClickCustomer: any,
  showSidebar: any
): ColDef<any>[] => {
  if (!data) return [];
  const columnDefs: ColDef<any>[] = [];
  columnDefs.push({
    headerName: InsightGridColumns.label,
    field: insightGridField.label,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    autoHeight: true,
    width: 80,
    suppressMovable: true,
  });
  columnDefs.push({
    headerName: InsightGridColumns.title,
    field: insightGridField.title,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    cellRendererParams: { handleOnClickCustomer, showSidebar },
    autoHeight: true,
    width: 250,
    suppressMovable: true,
  });
  columnDefs.push({
    headerName: InsightGridColumns.date,
    field: insightGridField.date,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    autoHeight: true,
    width: 180,
    suppressMovable: true,
  });
  columnDefs.push({
    headerName: InsightGridColumns.overallScore,
    field: insightGridField.overallScore,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    autoHeight: true,
    width: 120,
    suppressMovable: true,
  });
  columnDefs.push({
    headerName: InsightGridColumns.promoted,
    field: insightGridField.promoted,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    autoHeight: true,
    width: 100,
    suppressMovable: true,
  });
  columnDefs.push({
    headerName: InsightGridColumns.newlyDiscovered,
    field: insightGridField.newlyDiscovered,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    autoHeight: true,
    width: 180,
    suppressMovable: true,
  });
  columnDefs.push({
    headerName: InsightGridColumns.status,
    field: insightGridField.status,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: InsightCellRenderer,
    autoHeight: true,
    width: 250,
    suppressMovable: true,
    flex: 1,
  });

  return columnDefs;
};

export const getInsightSummary = (
  insightsSummaryData: InsightHomeQueryInterface
) => {
  if (!insightsSummaryData) return [];
  const insightsSummary = [
    {
      name: literals.newlyDiscovered,
      value: insightsSummaryData?.newlyDiscovered || 0,
    },
    {
      name: literals.readyForAction,
      value:
        (insightsSummaryData?.pending || 0) +
        (insightsSummaryData?.delayed || 0),
    },
    {
      name: literals.inflight,
      value: insightsSummaryData?.inflight || 0,
    },
    {
      name: literals.completed,
      value: insightsSummaryData?.completed || 0,
    },
  ];

  return insightsSummary;
};

export const getDateInMonthDayYearFormat = (date: any) => {
  const dateString = new Date(date);

  // Extract day, month, and year from the date object
  const day = String(dateString.getDate()).padStart(2, "0");
  const month = String(dateString.getMonth() + 1).padStart(2, "0");
  const year = dateString.getFullYear();

  // Return formatted date
  return `${month}/${day}/${year}`;
};

export const getStyleAccordingToStatus = (value: string) => {
  let styleName = "label-style";
  if (value === InsightStatusEnum.PENDING) {
    styleName += " neutral-label";
  } else if (
    value === InsightStatusEnum.COMPLETED ||
    value === InsightStatusEnum.INFLIGHT
  ) {
    styleName += " positive-label";
  } else if (value === InsightStatusEnum.DELAYED) {
    styleName += " negative-label";
  }
  return styleName;
};

export const getEaseImpactChartData = (
  data: InsightImpactAnalysisType[] | undefined
) => {
  if (!data) return [];

  return data
    .map((item) => ({
      impact: item.customerCentricScore,
      ease: item.implementationEase,
      label: item.label,
      value: item.businessValue,
      title: item.title,
    }))
    .sort((a, b) => b.value - a.value);
};

export const formatDateForAPI = (date: any) => date.toISOString().split("T")[0];

const getStartDateEndDate = (isForMonth: boolean, monthCount: number = 0) => {
  const endDate = new Date(); // Today's date
  let startDate = new Date();
  if (isForMonth) {
    startDate.setMonth(endDate.getMonth() - monthCount); // for Month
  } else {
    startDate.setFullYear(endDate.getFullYear() - 1); // for Year
  }
  return {
    startDate: formatDateForAPI(startDate),
    endDate: formatDateForAPI(endDate),
  };
};

export const getScreenFilterQueryDate = (activeTimeFrame: any) => {
  switch (activeTimeFrame.value) {
    case ScreenFilterEnum.LAST_THREE_MONTHS:
      return getStartDateEndDate(true, 3);

    case ScreenFilterEnum.LAST_SIX_MONTHS:
      return getStartDateEndDate(true, 6);

    case ScreenFilterEnum.LAST_YEAR:
      return getStartDateEndDate(false);

    default:
      return { startDate: null, endDate: null };
  }
};

export const getQueryDateRange = (
  activeFilter: SelectDropdownOption,
  customFilter: GenericCustomStartEndDate,
  startDate: string | any,
  endDate: string | any
) => {
  return {
    startDate:
      activeFilter.value === ScreenFilterEnum.CUSTOM
        ? customFilter?.startDate
        : startDate,
    endDate:
      activeFilter.value === ScreenFilterEnum.CUSTOM
        ? customFilter?.endDate
        : endDate,
  };
};

export const canSkipQuery = (
  activeFilter: SelectDropdownOption,
  customFilter: GenericCustomStartEndDate
) => {
  return activeFilter.value === ScreenFilterEnum.CUSTOM
    ? activeFilter.value === ScreenFilterEnum.CUSTOM &&
    !customFilter?.startDate &&
    !customFilter?.endDate
    : false;
};

export const formatDateFromCalendar = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getDurationFromStartEndDate = (dates: any) => {
  const start = new Date(dates.startDate);
  const end = new Date(dates.endDate);

  const startMonth = months[start.getMonth()];
  const startYear = start.getFullYear();

  const endMonth = months[end.getMonth()];
  const endYear = end.getFullYear();

  return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
};