import Text from "../../component/Text/Text";
import { surveyStatus } from "../../constants/ClientDetails";
import { colors } from "../../theme/colors";
import { getFormattedDate } from "../CustomerSummary/utils/gridUtility";
import { signalsSurveyGridField } from "./utils/gridUtility";

export const SignalsSurveyCellRenderer = (params: any) => {
    const { value, column } = params

    const isStatusValue = column.colId === signalsSurveyGridField.status;
    const isDateValue = column.colId === signalsSurveyGridField.startDate;

    const getStyleAccordingToStatus = () => {
        let styleName = "label-style"
        if (value === surveyStatus[0]) {
            styleName += " neutral-label"
        } else if (value === surveyStatus[1] || value === surveyStatus[2]) {
            styleName += " positive-label"
        } else if (value === surveyStatus[3]) {
            styleName += " negative-label"
        }
        return styleName
    }

    return (
        <div className="feedback-title-cell">
            {isStatusValue
                ? <div className="feedback-status" >
                    <Text
                        text={value}
                        styleName={getStyleAccordingToStatus()}
                        color={colors.black} />
                </div>
                : isDateValue
                    ? <div className="feedback-text">{getFormattedDate(value)}</div>
                    : <div className="feedback-text">{value}</div>}
        </div>
    );
};
