export interface SelectDropdownOption {
  label: string;
  value: string | null;
}

export enum UserRole {
  ADMIN = "ADMIN",
  TENANT = "TENANT",
}

export const UserRoleMenu: Record<UserRole, string> = {
  [UserRole.ADMIN]: "admin",
  [UserRole.TENANT]: "tenant",
};
