import { ColDef, GetCellRendererInstancesParams } from "ag-grid-community";
import dropdownArrow from "../../../assets/images/dropdown-arrow.svg";
import ColoredStatusWrapper from "../../../component/ColoredStatusWrapper/ColoredStatusWrapper";
import CustomSelectCellEditor from "../../../component/CustomSelectCellEditor/CustomSelectCellEditor";
import {
  AverageCsatGridColumns,
  CHIGridColumns,
  ContentEffectivenessGridColumns,
  CsatGridColumns,
  CustomerEfforScoreGridColumns,
  CustomerGridColumns,
  CustomerVisitsGridColumns,
  CustomMetricGridColumns,
  ExecutionProjectGridColumns,
  ExecutionProjectIssueGridColumns,
  ExecutionProjectSummaryGridColumns,
  HomeInsightTableGridColumns,
  InsightsDetractorGridColumns,
  InsightsGridColumns,
  insightTimeFrame,
  NetBenefitsGridColumns,
  NpsGridColumns,
  ProjectApprovalStatusEnum,
  projectApprovalStatusValues,
  ProjectIssuePriorityEnum,
  ProjectIssuePriorityValues,
  ProjectIssueStatusEnum,
  ProjectIssueStatusValues,
  ProjectIssueTypeEnum,
  ProjectIssueTypeValues,
  ProjectStatusEnum,
  projectStatusValues,
  surveyStatus,
  SurveyGridColumns,
  TenantDetailsTabs as Tabs,
} from "../../../constants/ClientDetails";
import { colors } from "../../../theme/colors";
import { literals } from "../../../theme/literals";
import { getFieldsFromColumn } from "../utils/GridOps";
import CustomDropdownOptionRender from "./CustomDropdownOptionRender";
import { DetractorTopicCell } from "./DetractorTopicCell";
import { Segment } from "./SegmentModals";
import { YearQuarterSingleCellRenderer } from "./YearQuarterSingleCellRenderer";
import {
  EPITagCellEditor,
  EPITagCellRender,
} from "./EstimationProjectIssue/EPITagCell";
import {
  EPIAssigneeCellEditor,
  EPIAssigneeCellRender,
} from "./EstimationProjectIssue/EPIAssigneeCell";
import {
  EPSApproverCellEditor,
  EPSApproverCellRender,
} from "./ExecutionProjectSummary/EPSApproverCell";
import {
  EPSContributorCellEditor,
  EPSContributorCellRender,
} from "./ExecutionProjectSummary/EPSContributorCell";
import {
  EPSInformedCellEditor,
  EPSInformedCellRender,
} from "./ExecutionProjectSummary/EPSInformedCell";
import NumberSpecialCharEditor from "./NumberSpecialCharEditor";
import NBCustomMetricEditor from "./NetBenefit/NBCustomMetricEditor";
import {
  getNumericValueFromStringNumber,
  getSelectOptions,
  getTimelineSortValue,
  objectSortComparator,
} from "../../Admin/utils/globalUtil";
import CSATTouchPointEditor from "./CSAT/CSATTouchPointEditor";
import CSATCustomerCellEditor from "./CSAT/CSATCustomerCellEditor";

export const createNewRowData = ({ activeTab, tenantId, metricType }: any) => {
  switch (activeTab) {
    case Tabs.Customer:
      return () => ({
        name: "",
        priority: 0,
        address: "",
        city: "",
        stateProvince: "",
        postalCode: null,
        industry: "",
        phone: null,
        url: "",
        tenantId,
      });

    case Tabs.NPS:
      return () => ({
        customer: {},
        date: null,
        score: "",
        tenantId,
      });

    case Tabs.TenantNPS:
      return () => ({
        date: null,
        score: "",
        tenantId,
      });

    case Tabs.CSAT:
      return () => ({
        customer: {},
        date: null,
        [csatGridField.touchpointObj]: {
          score: "",
          touchpoint: null,
          subTouchPoints: null,
        },
        tenantId,
      });

    case Tabs.CHI:
      return () => ({
        customer: {},
        score: "",
        tenantId,
      });

    case Tabs.Insights:
      return () => ({
        title: "",
        description: "",
        frictionDescription: "",
        date: null,
        status: "PENDING",
        promoted: false,
        newlyDiscovered: false,
        customersImpacted: 0,
        importance: 0,
        severity: 0,
        frequency: 0,
        customerCentricScore: null,
        businessValue: 0,
        implementationEase: 0,
        strategicAlignment: 0,
        orgCentricScore: null,
        overallScore: null,
        insightImplementationTimeFrame: {
          key: "ZERO_TO_THREE_MONTHS",
          value: insightTimeFrame.ZERO_TO_THREE_MONTHS,
        },
        detractorTopics: null,
        tenantId,
      });

    case Tabs.InsightsDetractor:
      return () => ({
        name: "",
        frequency: 0,
        date: null,
        tenantId,
      });

    case Tabs.NetBenefits:
      return () => ({
        project: null,
        valueCapturedAmount: "",
        valueCapturedCurrency: "USD",
        timeline: null,
        roi: "",
        tenantId,
      });

    case Tabs.CustomMetrics:
      return () => ({
        [customMetricsGridField.timeline]: null,
        [customMetricsGridField.aggregate]: {
          overallAggregateValue: 0,
          projectMetricValues: [],
        },
        tenantId,
      });

    case Tabs.Survey:
      return () => ({
        title: "",
        type: "",
        startDate: null,
        endDate: null,
        customer: {},
        customerSegment: "",
        numSent: 0,
        responsesReceived: 0,
        status: surveyStatus[0],
        tenantId,
      });

    case Tabs.CES:
    case Tabs.CustomerVisits:
    case Tabs.AverageCSAT:
    case Tabs.customerEffort:
    case Tabs.taskCompletionRate:
    case Tabs.likelihoodToReturn:
    case Tabs.ContentCSAT:
    case Tabs.InterceptResponseRate:
      return () => ({
        value: 0,
        date: null,
        tenantId,
        type: metricType,
      });

    case Tabs.ContentEffectiveness:
      return () => ({
        content: "",
        score: 0,
        date: null,
        mostCommonlySearched: false,
        needsAttention: false,
        comments: "",
        tenantId,
      });
    case Tabs.project:
      return () => ({
        insights: null,
        projectName: "",
        projectDescription: "",
        startDate: null,
        endDate: null,
        pctComplete: 0,
        approvalStatus: ProjectApprovalStatusEnum.NOT_STARTED,
        status: ProjectStatusEnum.PENDING,
        tenantId,
      });

    case Tabs.projectIssue:
      return () => ({
        [executionProjectIssueGridField.project]: null,
        [executionProjectIssueGridField.issueName]: "",
        [executionProjectIssueGridField.issueDescription]: "",
        [executionProjectIssueGridField.issueStatus]:
          ProjectIssueStatusEnum.IN_PROGRESS,
        [executionProjectIssueGridField.issueType]:
          ProjectIssueTypeEnum.KEY_RISKS,
        [executionProjectIssueGridField.issuePriority]:
          ProjectIssuePriorityEnum.LOW,
        [executionProjectIssueGridField.tagNames]: null,
        [executionProjectIssueGridField.assignees]: null,
        [executionProjectIssueGridField.dueDate]: null,
        tenantId,
      });

    case Tabs.projectSummary:
      return () => ({
        project: null,
        ownerName: "",
        driver: "",
        approvers: null,
        contributors: null,
        informed: null,
        objective: "",
        dueDate: null,
        tenantId,
      });

    default:
      return () => ({});
  }
};

interface CustomButtonParams extends GetCellRendererInstancesParams {
  onClick: (rowData: any) => void;
  data: any;
}

const myCustomBtn = (params: CustomButtonParams) => {
  return (
    <div
      className="occupy-cell pointer"
      onClick={() => params.onClick(params.data)}
    >
      <div>Select Segment</div>
      <img src={dropdownArrow} alt="" />
    </div>
  );
};

export const customerGridField = getFieldsFromColumn(CustomerGridColumns);
export const getCustomerGridColumn = (
  onAddSegmentClick: undefined | ((params: any) => any),
  readonly = false
): ColDef[] => {
  const col: ColDef[] = [
    {
      headerName: CustomerGridColumns.id,
      field: customerGridField.id,
      flex: 1,
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      minWidth: 100,
      cellClass: "bg-gray",
    },
    {
      headerName: CustomerGridColumns.name,
      field: customerGridField.name,
      flex: 1,
      editable: !readonly,
    },
    {
      headerName: CustomerGridColumns.priority,
      field: customerGridField.priority,
      flex: 1,
      editable: !readonly,
      minWidth: 100,
      cellDataType: "number",
    },
    {
      field: "actions",
      headerName: "Add Segment",
      cellRenderer: myCustomBtn,
      cellRendererParams: {
        onClick: onAddSegmentClick,
      },
    },
    {
      headerName: CustomerGridColumns.address,
      field: customerGridField.address,
      flex: 1,
      editable: !readonly,
    },
    {
      headerName: CustomerGridColumns.city,
      field: customerGridField.city,
      flex: 1,
      editable: !readonly,
    },
    {
      headerName: CustomerGridColumns.stateProvince,
      field: customerGridField.stateProvince,
      flex: 1,
      editable: !readonly,
    },
    {
      headerName: CustomerGridColumns.postalCode,
      field: customerGridField.postalCode,
      flex: 1,
      editable: !readonly,
    },
    {
      headerName: CustomerGridColumns.industry,
      field: customerGridField.industry,
      flex: 1,
      editable: !readonly,
    },
    {
      headerName: CustomerGridColumns.phone,
      field: customerGridField.phone,
      flex: 1,
      editable: !readonly,
      cellEditor: NumberSpecialCharEditor,
    },
    {
      headerName: CustomerGridColumns.url,
      field: customerGridField.url,
      flex: 1,
      editable: !readonly,
    },
  ];
  col.splice(3, onAddSegmentClick ? 0 : 1);
  return col;
};

export const npsGridField = getFieldsFromColumn(NpsGridColumns);
export const getNpsGridColumn = (customerData: any[]): ColDef[] => [
  {
    headerName: NpsGridColumns.customer,
    field: npsGridField.customer,
    flex: 1,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: customerData?.map((customer) => ({
        name: customer.name,
        id: customer.id,
      })),
    },
    valueFormatter: ({ value: customer }: any) => customer?.name,
    valueParser: ({ data }) => data?.customer?.name,
    cellRenderer: ({ data }: any) => data?.customer?.name,
    getQuickFilterText: (params) => params.data?.customer?.name || "",
    comparator: objectSortComparator("name"),
  },
  {
    headerName: NpsGridColumns.date,
    field: npsGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
  },
  {
    headerName: NpsGridColumns.score,
    field: npsGridField.score,
    flex: 1,
    editable: true,
  },
];

export const tenantNpsGridColumn: ColDef[] = [
  {
    headerName: NpsGridColumns.date,
    field: npsGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: NpsGridColumns.score,
    field: npsGridField.score,
    flex: 1,
    editable: true,
  },
];

export const csatGridField = getFieldsFromColumn(CsatGridColumns);
export const getCsatGridColumn = (customerData: any[]): ColDef[] => [
  {
    headerName: CsatGridColumns.customer,
    field: csatGridField.customer,
    flex: 1,
    checkboxSelection: true,
    editable: true,
    cellEditor: CSATCustomerCellEditor,
    cellEditorParams: {
      values: customerData?.map((customer) => ({
        label: customer.name,
        value: customer.id,
      })),
    },
    valueFormatter: ({ value: customer }: any) => customer?.name,
    valueParser: ({ data }) => data?.customer?.name,
    cellRenderer: ({ data }: any) => data?.customer?.name,
    getQuickFilterText: (params) => params.data?.customer?.name || "",
    minWidth: 300,
  },
  {
    headerName: CsatGridColumns.date,
    field: csatGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
    minWidth: 300,
  },
  {
    headerName: CsatGridColumns.score,
    field: csatGridField.touchpointObj,
    flex: 1,
    editable: false,
    cellRenderer: ({ data }: any) => {
      return data?.touchpointObj?.score || null;
    },
    minWidth: 250,
    valueFormatter: ({ value: touchpointObj }: any) => touchpointObj?.score,
    valueParser: ({ data }) => data?.touchpointObj?.score,
    getQuickFilterText: (params) => params.data?.touchpointObj?.score || "",
  },
  {
    headerName: CsatGridColumns.touchpointObj,
    field: csatGridField.touchpointObj,
    cellEditor: CSATTouchPointEditor,
    cellRenderer: ({ data }: any) => {
      return data?.touchpointObj?.touchpoint;
    },
    flex: 1,
    editable: true,
    minWidth: 300,
    valueFormatter: ({ value: touchpointObj }: any) =>
      touchpointObj?.touchpoint,
    valueParser: ({ data }) => data?.touchpointObj?.touchpoint,
    getQuickFilterText: (params) =>
      params.data?.touchpointObj?.touchpoint || "",
  },
];

export const chiGridField = getFieldsFromColumn(CHIGridColumns);
export const getCHIGridColumn = (customerData: any[]): ColDef[] => [
  {
    headerName: CHIGridColumns.customer,
    field: chiGridField.customer,
    flex: 1,
    editable: false,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: customerData?.map((customer) => ({
        name: customer.name,
        id: customer.id,
      })),
    },
    valueFormatter: ({ value: customer }: any) => customer?.name,
    valueParser: ({ data }) => data?.customer?.name,
    cellRenderer: ({ data }: any) => data?.customer?.name,
    getQuickFilterText: (params) => params.data?.customer?.name || "",
    headerClass: "custom-header-center",
    cellClass: "custom-cell-center",
    comparator: objectSortComparator("name"),
  },
  {
    headerName: CHIGridColumns.score,
    field: chiGridField.score,
    flex: 1,
    editable: false,
    cellDataType: "number",
    headerClass: "custom-header-center",
    cellClass: "custom-cell-center",
  },
];

export const insightsGridField = getFieldsFromColumn(InsightsGridColumns);
export const homeInsightGridField = getFieldsFromColumn(
  HomeInsightTableGridColumns
);
export const insightsGridColumn: ColDef[] = [
  {
    headerName: InsightsGridColumns.id,
    field: insightsGridField.id,
    flex: 1,
    editable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: 290,
    cellClass: "bg-gray",
    cellDataType: "",
  },
  {
    headerName: InsightsGridColumns.title,
    field: insightsGridField.title,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.description,
    field: insightsGridField.description,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.frictionDescription,
    field: insightsGridField.frictionDescription,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.date,
    field: insightsGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.detractorTopics,
    field: insightsGridField.detractorTopics,
    flex: 1,
    editable: false,
    maxWidth: literals.detractorTopicCellWidth,
    minWidth: literals.detractorTopicCellWidth,
    cellRenderer: DetractorTopicCell,
    suppressKeyboardEvent: (params) => params.event.key === "Enter",
    autoHeight: true,
    cellClass: "detractor-cell",
  },
  {
    headerName: InsightsGridColumns.status,
    field: insightsGridField.status,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["PENDING", "COMPLETED", "DELAYED", "IN_FLIGHT"],
    },
  },
  {
    headerName: InsightsGridColumns.promoted,
    field: insightsGridField.promoted,
    flex: 1,
    editable: true,
    minWidth: 120,
    cellDataType: "boolean",
    cellClass: "center-checkbox",
  },
  {
    headerName: InsightsGridColumns.newlyDiscovered,
    field: insightsGridField.newlyDiscovered,
    flex: 1,
    editable: true,
    minWidth: 180,
    cellDataType: "boolean",
    cellClass: "center-checkbox",
  },
  {
    headerName: InsightsGridColumns.customersImpacted,
    field: insightsGridField.customersImpacted,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.importance,
    field: insightsGridField.importance,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.severity,
    field: insightsGridField.severity,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.frequency,
    field: insightsGridField.frequency,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.customerCentricScore,
    field: insightsGridField.customerCentricScore,
    flex: 1,
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.businessValue,
    field: insightsGridField.businessValue,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.implementationEase,
    field: insightsGridField.implementationEase,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.strategicAlignment,
    field: insightsGridField.strategicAlignment,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: InsightsGridColumns.orgCentricScore,
    field: insightsGridField.orgCentricScore,
    flex: 1,
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.overallScore,
    field: insightsGridField.overallScore,
    flex: 1,
    minWidth: 150,
  },
  {
    headerName: InsightsGridColumns.insightImplementationTimeFrame,
    field: insightsGridField.insightImplementationTimeFrame,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: Object.keys(insightTimeFrame).map((key) => ({
        key,
        value: insightTimeFrame[key],
      })),
    },
    valueFormatter: ({ value }: any) => value?.value,
    valueParser: ({ data }) => data?.insightImplementationTimeFrame?.value,
    cellRenderer: ({ data }: any) =>
      data?.insightImplementationTimeFrame?.value,
    comparator: (valueA, valueB) => {
      return (
        getNumericValueFromStringNumber(valueA?.value) -
        getNumericValueFromStringNumber(valueB?.value)
      );
    },
  },
];

export const insightsDetractorGridField = getFieldsFromColumn(
  InsightsDetractorGridColumns
);
export const insightsDetractorGridColumn: ColDef[] = [
  {
    headerName: InsightsDetractorGridColumns.name,
    field: insightsDetractorGridField.name,
    flex: 1,
    editable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    maxWidth: literals.detractorCellWidth,
    minWidth: literals.detractorCellWidth,
  },
  {
    headerName: InsightsDetractorGridColumns.frequency,
    field: insightsDetractorGridField.frequency,
    flex: 1,
    editable: true,
    cellDataType: "number",
    maxWidth: literals.detractorCellWidth,
    minWidth: literals.detractorCellWidth,
  },
];

export const netBenefitsGridField = getFieldsFromColumn(NetBenefitsGridColumns);
export const getNetBenefitsGridColumn = (projectData: any[]): ColDef[] => [
  {
    headerName: NetBenefitsGridColumns.project,
    field: netBenefitsGridField.project,
    flex: 1,
    editable: (params: any) => params.node.rowPinned !== "bottom",
    maxWidth: literals.netBenefitsCellWidth,
    minWidth: literals.netBenefitsCellWidth,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: projectData?.map((project) => ({
        projectName: project.projectName,
        projectId: project.id,
      })),
    },
    valueFormatter: ({ value: project }: any) => project?.projectName,
    valueParser: ({ data }) => data?.project?.projectName,
    cellRenderer: ({ data }: any) => data?.project?.projectName,
    getQuickFilterText: (params) => params.data?.project?.projectName,
    comparator: objectSortComparator("projectName"),
    cellStyle: (params: any) => {
      if (params.node.rowPinned === "bottom") {
        return {
          color: colors.Light_Blue_PRI,
          fontWeight: "700",
          fontFamily: "Roboto",
          fontSize: "14px",
        };
      }
      return null;
    },
  },
  {
    headerName: NetBenefitsGridColumns.valueCapturedAmount,
    field: netBenefitsGridField.valueCapturedAmount,
    flex: 1,
    editable: (params: any) => params.node.rowPinned !== "bottom",
    maxWidth: literals.netBenefitsCellWidth,
    minWidth: literals.netBenefitsCellWidth,
    valueFormatter: (params: any) => {
      return params.node.rowPinned === "bottom"
        ? params.value
        : params.value
        ? "$" + params.value
        : params.value;
    },
  },
  {
    headerName: NetBenefitsGridColumns.timeline,
    field: netBenefitsGridField.timeline,
    flex: 1,
    editable: false,
    maxWidth: literals.netBenefitsCellWidth,
    minWidth: literals.netBenefitsCellWidth,
    headerClass: "custom-header-center",
    cellRenderer: YearQuarterSingleCellRenderer,
    autoHeight: true,
    cellClass: "quarter-year-cell",
    getQuickFilterText: (params) => {
      const year = params?.data?.timeline?.year || "";
      const quarter = params?.data?.timeline?.quarter || "";
      return `${year} ${quarter}`.trim();
    },
    comparator: (valueA, valueB) => {
      return getTimelineSortValue(valueA) - getTimelineSortValue(valueB);
    },
  },
  {
    headerName: NetBenefitsGridColumns.roi,
    field: netBenefitsGridField.roi,
    flex: 1,
    editable: true,
    maxWidth: literals.netBenefitsCellWidth,
    minWidth: literals.netBenefitsCellWidth,
    valueFormatter: ({ value: roi }: any) => (roi ? roi + "%" : roi),
    cellStyle: (params: any) => {
      if (params.node.rowPinned === "bottom") {
        return {
          fontWeight: "700",
          fontFamily: "Roboto",
          fontSize: "14px",
        };
      }
      return null;
    },
  },
];

export const customMetricsGridField = getFieldsFromColumn(
  CustomMetricGridColumns
);
export const customMetricsGridColumn = (projectData: any[]): ColDef[] => [
  {
    headerName: CustomMetricGridColumns.timeline,
    field: customMetricsGridField.timeline,
    flex: 1,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    maxWidth: literals.netBenefitsCellWidth,
    minWidth: literals.netBenefitsCellWidth,
    headerClass: "custom-header-center",
    cellRenderer: YearQuarterSingleCellRenderer,
    cellRendererParams: {
      fromField: "CustomMetricCell",
    },
    autoHeight: true,
    cellClass: "quarter-year-cell-checkbox",
    getQuickFilterText: (params) => {
      const year = params?.data?.timeline?.year || "";
      const quarter = params?.data?.timeline?.quarter || "";
      return `${year} ${quarter}`.trim();
    },
    comparator: (valueA, valueB) => {
      return getTimelineSortValue(valueA) - getTimelineSortValue(valueB);
    },
  },
  {
    headerName: CustomMetricGridColumns.aggregate,
    field: customMetricsGridField.aggregate,
    flex: 1,
    editable: true,
    maxWidth: 500,
    minWidth: 500,
    cellEditor: NBCustomMetricEditor,
    cellEditorParams: {
      values: getSelectOptions(projectData, "projectName", "id", true),
    },
    headerClass: "custom-align-center-header",
    cellClass: "custom-align-center-cell",
    valueFormatter: (params: any) => {
      return params?.value?.overallAggregateValue;
    },
  },
];

export const surveyGridField = getFieldsFromColumn(SurveyGridColumns);
export const getSurveyGridColumn = (customerList: Segment[] = []): ColDef[] => [
  {
    headerName: SurveyGridColumns.id,
    field: surveyGridField.id,
    flex: 1,
    editable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: 290,
    cellClass: "bg-gray",
    cellDataType: "",
  },
  {
    headerName: SurveyGridColumns.title,
    field: surveyGridField.title,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
  {
    headerName: SurveyGridColumns.type,
    field: surveyGridField.type,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
  {
    headerName: SurveyGridColumns.startDate,
    field: surveyGridField.startDate,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
    minWidth: 180,
  },
  {
    headerName: SurveyGridColumns.endDate,
    field: surveyGridField.endDate,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
    minWidth: 170,
  },
  {
    headerName: SurveyGridColumns.customer,
    field: surveyGridField.customer,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          name: "none",
          id: null,
        },
        ...customerList?.map((customer) => ({
          name: customer.name,
          id: customer.id,
        })),
      ],
    },
    valueFormatter: ({ value }: any) => value?.name,
    valueParser: ({ data }) => data?.customer?.name,
    cellRenderer: ({ data }: any) => data?.customer?.name,
  },
  {
    headerName: SurveyGridColumns.customerSegment,
    field: surveyGridField.customerSegment,
    flex: 1,
    editable: true,
    minWidth: 190,
  },
  {
    headerName: SurveyGridColumns.numSent,
    field: surveyGridField.numSent,
    flex: 1,
    editable: true,
    cellDataType: "number",
    minWidth: 150,
  },
  {
    headerName: SurveyGridColumns.responsesReceived,
    field: surveyGridField.responsesReceived,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
  {
    headerName: SurveyGridColumns.status,
    field: surveyGridField.status,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: surveyStatus,
    },
  },
];

export const customerEfforScoreGridField = getFieldsFromColumn(
  CustomerEfforScoreGridColumns
);
export const customerEfforScoreGridColumn: ColDef[] = [
  {
    headerName: CustomerEfforScoreGridColumns.value,
    field: customerEfforScoreGridField.value,
    flex: 1,
    editable: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellDataType: "number",
  },
  {
    headerName: CustomerEfforScoreGridColumns.date,
    field: customerEfforScoreGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
  },
];

export const customerVisitsGridField = getFieldsFromColumn(
  CustomerVisitsGridColumns
);
export const customerVisitsGridColumns: ColDef[] = [
  {
    headerName: CustomerVisitsGridColumns.value,
    field: customerVisitsGridField.value,
    flex: 1,
    editable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: CustomerVisitsGridColumns.date,
    field: customerVisitsGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
  },
];

export const averageCsatGridField = getFieldsFromColumn(AverageCsatGridColumns);
export const averageCsatGridColumn: ColDef[] = [
  {
    headerName: AverageCsatGridColumns.value,
    field: averageCsatGridField.value,
    flex: 1,
    editable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: AverageCsatGridColumns.date,
    field: averageCsatGridField.date,
    flex: 1,
    editable: true,
    cellEditor: "agDateStringCellEditor",
  },
];

export const contentEffectivenessGridField = getFieldsFromColumn(
  ContentEffectivenessGridColumns
);
export const contentEffectivenessGridColumn: ColDef[] = [
  {
    headerName: ContentEffectivenessGridColumns.content,
    field: contentEffectivenessGridField.content,
    flex: 1,
    editable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: ContentEffectivenessGridColumns.score,
    field: contentEffectivenessGridField.score,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "number",
  },
  {
    headerName: ContentEffectivenessGridColumns.date,
    field: contentEffectivenessGridField.date,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: "agDateStringCellEditor",
  },
  {
    headerName: ContentEffectivenessGridColumns.mostCommonlySearched,
    field: contentEffectivenessGridField.mostCommonlySearched,
    flex: 1,
    editable: true,
    minWidth: 120,
    cellDataType: "boolean",
    cellClass: "center-checkbox",
  },
  {
    headerName: ContentEffectivenessGridColumns.needsAttention,
    field: contentEffectivenessGridField.needsAttention,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellDataType: "boolean",
    cellClass: "center-checkbox",
  },
  {
    headerName: ContentEffectivenessGridColumns.comments,
    field: contentEffectivenessGridField.comments,
    flex: 1,
    editable: true,
    minWidth: 150,
  },
];

export const executionProjectGridField = getFieldsFromColumn(
  ExecutionProjectGridColumns
);
export const executionProjectGridColumn = (insightData: any): ColDef[] => {
  return [
    {
      headerName: ExecutionProjectGridColumns.id,
      field: executionProjectGridField.id,
      flex: 1,
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      minWidth: 290,
      cellClass: "bg-gray",
    },
    {
      headerName: ExecutionProjectGridColumns.insights,
      field: executionProjectGridField.insights,
      flex: 1,
      editable: true,
      minWidth: 300,
      cellEditor: CustomSelectCellEditor,
      cellEditorParams: {
        values: insightData?.map((item: any) => ({
          name: item.title,
          id: item.id,
        })),
        keyToShowValue: "name",
        component: (childProps: any) => (
          <CustomDropdownOptionRender {...childProps} />
        ),
      },
      cellRenderer: ({ data }: any) => {
        return data?.insights?.name;
      },
      getQuickFilterText: (params) => params?.data?.insights?.name,
      comparator: objectSortComparator("name"),
      // headerClass: 'chiValue-center'
    },
    {
      headerName: ExecutionProjectGridColumns.projectName,
      field: executionProjectGridField.projectName,
      flex: 1,
      editable: true,
      minWidth: 250,
    },
    {
      headerName: ExecutionProjectGridColumns.projectDescription,
      field: executionProjectGridField.projectDescription,
      flex: 1,
      editable: true,
      minWidth: 250,
    },
    {
      headerName: ExecutionProjectGridColumns.startDate,
      field: executionProjectGridField.startDate,
      flex: 1,
      editable: true,
      minWidth: 150,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: ExecutionProjectGridColumns.endDate,
      field: executionProjectGridField.endDate,
      flex: 1,
      editable: true,
      minWidth: 150,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: ExecutionProjectGridColumns.status,
      field: executionProjectGridField.status,
      flex: 1,
      editable: true,
      minWidth: 150,
      cellEditor: CustomSelectCellEditor,
      cellEditorParams: {
        values: projectStatusValues,
        component: (childProps: any) => (
          <CustomDropdownOptionRender {...childProps} />
        ),
      },
      cellRenderer: ColoredStatusWrapper,
      autoHeight: true,
    },
    {
      headerName: ExecutionProjectGridColumns.pctComplete,
      field: executionProjectGridField.pctComplete,
      flex: 1,
      editable: true,
      cellDataType: "number",
      minWidth: 150,
    },
    {
      headerName: ExecutionProjectGridColumns.approvalStatus,
      field: executionProjectGridField.approvalStatus,
      flex: 1,
      editable: true,
      minWidth: 150,
      cellEditor: CustomSelectCellEditor,
      cellEditorParams: {
        values: projectApprovalStatusValues,
        component: (childProps: any) => (
          <CustomDropdownOptionRender {...childProps} />
        ),
      },
      cellRenderer: ColoredStatusWrapper,
    },
  ];
};

export const executionProjectIssueGridField = getFieldsFromColumn(
  ExecutionProjectIssueGridColumns
);
export const executionProjectIssueGridColumn = (projectData: any): ColDef[] => [
  {
    headerName: ExecutionProjectIssueGridColumns.id,
    field: executionProjectIssueGridField.id,
    flex: 1,
    editable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: 290,
    cellClass: "bg-gray",
  },
  {
    headerName: ExecutionProjectIssueGridColumns.project,
    field: executionProjectIssueGridField.project,
    flex: 1,
    editable: true,
    minWidth: 250,
    cellEditor: CustomSelectCellEditor,
    cellEditorParams: {
      values: projectData?.map((item: any) => ({
        name: item.projectName,
        id: item.id,
      })),
      keyToShowValue: "name",
      component: (childProps: any) => (
        <CustomDropdownOptionRender {...childProps} />
      ),
    },
    cellRenderer: ({ data }: any) => {
      return data?.project?.name;
    },
    comparator: objectSortComparator("name"),
    getQuickFilterText: (params) => params?.data?.project?.name,
  },
  {
    headerName: ExecutionProjectIssueGridColumns.issueName,
    field: executionProjectIssueGridField.issueName,
    flex: 1,
    editable: true,
    minWidth: 200,
  },
  {
    headerName: ExecutionProjectIssueGridColumns.issueDescription,
    field: executionProjectIssueGridField.issueDescription,
    flex: 1,
    editable: true,
    minWidth: 250,
  },
  {
    headerName: ExecutionProjectIssueGridColumns.issueStatus,
    field: executionProjectIssueGridField.issueStatus,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: CustomSelectCellEditor,
    cellEditorParams: {
      values: ProjectIssueStatusValues,
      component: (childProps: any) => (
        <CustomDropdownOptionRender {...childProps} />
      ),
    },
    cellRenderer: ColoredStatusWrapper,
  },
  {
    headerName: ExecutionProjectIssueGridColumns.issueType,
    field: executionProjectIssueGridField.issueType,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: CustomSelectCellEditor,
    cellEditorParams: {
      values: ProjectIssueTypeValues,
      component: (childProps: any) => (
        <CustomDropdownOptionRender {...childProps} />
      ),
    },
    cellRenderer: ColoredStatusWrapper,
  },
  {
    headerName: ExecutionProjectIssueGridColumns.issuePriority,
    field: executionProjectIssueGridField.issuePriority,
    flex: 1,
    editable: true,
    cellEditor: CustomSelectCellEditor,
    cellEditorParams: {
      values: ProjectIssuePriorityValues,
      component: (childProps: any) => (
        <CustomDropdownOptionRender {...childProps} />
      ),
    },
    cellRenderer: ColoredStatusWrapper,
    minWidth: 150,
  },
  {
    headerName: "Tags",
    field: executionProjectIssueGridField.tagNames,
    flex: 1,
    cellEditor: EPITagCellEditor,
    cellRenderer: EPITagCellRender,
    editable: true,
    minWidth: 300,
    autoHeight: true,
    suppressKeyboardEvent: (params) => params.event.key === "Enter",
  },
  {
    headerName: ExecutionProjectIssueGridColumns.assignees,
    field: executionProjectIssueGridField.assignees,
    flex: 1,
    cellEditor: EPIAssigneeCellEditor,
    cellRenderer: EPIAssigneeCellRender,
    editable: true,
    minWidth: 300,
    autoHeight: true,
    suppressKeyboardEvent: (params) => params.event.key === "Enter",
  },
  {
    headerName: ExecutionProjectIssueGridColumns.dueDate,
    field: executionProjectIssueGridField.dueDate,
    flex: 1,
    editable: true,
    minWidth: 150,
    cellEditor: "agDateStringCellEditor",
  },
];

export const executionProjectSummaryGridField = getFieldsFromColumn(
  ExecutionProjectSummaryGridColumns
);
export const executionProjectSummaryGridColumn = (
  projectData: any
): ColDef[] => {
  return [
    {
      headerName: ExecutionProjectSummaryGridColumns.projectId,
      field: executionProjectSummaryGridField.projectId,
      flex: 1,
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      minWidth: 290,
      cellClass: "bg-gray",
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.project,
      field: executionProjectSummaryGridField.project,
      flex: 1,
      editable: true,
      minWidth: 250,
      cellEditor: CustomSelectCellEditor,
      cellEditorParams: {
        values: projectData?.map((item: any) => ({
          name: item.projectName,
          id: item.id,
        })),
        keyToShowValue: "name",
        component: (childProps: any) => (
          <CustomDropdownOptionRender {...childProps} />
        ),
      },
      cellRenderer: ({ data }: any) => {
        return data?.project?.name;
      },
      getQuickFilterText: (params) => params?.data?.project?.name,
      comparator: objectSortComparator("name"),
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.ownerName,
      field: executionProjectSummaryGridField.ownerName,
      flex: 1,
      editable: true,
      minWidth: 200,
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.driver,
      field: executionProjectSummaryGridField.driver,
      flex: 1,
      editable: true,
      minWidth: 200,
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.approvers,
      field: executionProjectSummaryGridField.approvers,
      flex: 1,
      maxWidth: literals.multiSelectCellWidth,
      minWidth: literals.multiSelectCellWidth,

      /* Use of Multi Select from list */
      // editable: false,
      // cellRenderer: MultiSelectCell,
      // cellRendererParams: {
      //   values: projectData?.map((item: any) => ({
      //     id: item.id,
      //     name: item.projectName,
      //   })),
      // },
      // autoHeight: true,
      // cellClass: "multi-select-cell",

      /* Use of free text with selection from list */
      cellEditor: EPSApproverCellEditor,
      cellRenderer: EPSApproverCellRender,
      editable: true,
      autoHeight: true,
      suppressKeyboardEvent: (params) => params.event.key === "Enter",
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.contributors,
      field: executionProjectSummaryGridField.contributors,
      flex: 1,
      maxWidth: literals.multiSelectCellWidth,
      minWidth: literals.multiSelectCellWidth,
      cellEditor: EPSContributorCellEditor,
      cellRenderer: EPSContributorCellRender,
      editable: true,
      autoHeight: true,
      suppressKeyboardEvent: (params) => params.event.key === "Enter",
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.informed,
      field: executionProjectSummaryGridField.informed,
      flex: 1,
      maxWidth: literals.multiSelectCellWidth,
      minWidth: literals.multiSelectCellWidth,
      cellEditor: EPSInformedCellEditor,
      cellRenderer: EPSInformedCellRender,
      editable: true,
      autoHeight: true,
      suppressKeyboardEvent: (params) => params.event.key === "Enter",
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.objective,
      field: executionProjectSummaryGridField.objective,
      flex: 1,
      editable: true,
      minWidth: 200,
    },
    {
      headerName: ExecutionProjectSummaryGridColumns.dueDate,
      field: executionProjectSummaryGridField.dueDate,
      flex: 1,
      editable: true,
      minWidth: 150,
      cellEditor: "agDateStringCellEditor",
    },
  ];
};
