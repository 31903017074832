import { colors } from "../../../theme/colors";
import { literals } from "../../../theme/literals";
import { DashboardSurveyStats } from "../SignalsQuery.type";

export const getFormattedSurveyResponseData = (surveyStats?: DashboardSurveyStats) => {
  if (!surveyStats) return [];

  const responsesData = [{
    "label": literals.responsesSent,
    "value": surveyStats?.sent || 0,
    "color": colors.blueTwo
  },
  {
    "label": literals.responsesUnanswered,
    "value": (surveyStats?.sent ?? 0) - (surveyStats?.completed ?? 0),
    "color": colors.keyInsightsColor
  },
  {
    "label": literals.responsesCompleted,
    "value": surveyStats?.completed || 0,
    "color": colors.inFlightInsightsColor
  }]

  return responsesData;
};
