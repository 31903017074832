import { Suspense } from "react";
import Loader from "../component/Loader/Loader";

const LazyCompFallbackWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Suspense fallback={<Loader isLoading={true} />}>{children}</Suspense>;
};

export default LazyCompFallbackWrapper;
