import React, { useLayoutEffect, useMemo } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { colors } from "../../theme/colors";

const NpsCsatTrendChart = (props: any) => {
  const { chartID, npsData, csatData, height = 273 } = props;

  // Memoized Tooltip Configuration
  const createTooltip = (root: am5.Root) => {
    const tooltip = am5.Tooltip.new(root, {
      labelText: "{name} {categoryX} - {valueY}",
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      getLabelFillFromSprite: false,
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingTop: 5,
    });

    tooltip.get("background")?.setAll({
      fill: am5.color(colors.black_gray),
      stroke: am5.color(colors.black_gray),
      strokeWidth: 0,
    });

    return tooltip;
  };

  // Memoized Axis Labels Configuration
  const axisLabelStyle = useMemo(
    () => ({
      fill: am5.color(colors.hexBlack1),
      fontSize: "12px",
      fontFamily: "Roboto",
    }),
    []
  );

  useLayoutEffect(() => {
    am5.array.each(am5.registry.rootElements, (root) => {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    const root = am5.Root.new(chartID);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingTop: 40,
        paddingBottom: -25,
        paddingLeft: 0,
        paddingRight: 0,
      })
    );

    // Create Axes Function
    const createCategoryAxis = (
      categoryField: string,
      position: "top" | "bottom",
      data: any[]
    ) => {
      const axis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField,
          renderer: am5xy.AxisRendererX.new(root, {
            opposite: position === "top",
          }),
        })
      );

      axis.get("renderer").labels.template.setAll({
        ...axisLabelStyle,
        paddingTop: position === "bottom" ? 20 : 0,
        paddingBottom: position === "top" ? 20 : 0,
      });

      axis.get("renderer").grid.template.set("forceHidden", true);
      axis.data.setAll(data);

      return axis;
    };

    const xAxisNPS = createCategoryAxis("quarter", "bottom", npsData);
    const xAxisCSAT = createCategoryAxis("month", "top", csatData);

    // Create Y Axis Function
    const createValueAxis = (opposite: boolean, color: any) => {
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, { opposite }),
          extraMin: 0.1,
        })
      );

      yAxis.get("renderer").labels.template.setAll({
        ...axisLabelStyle,
        fill: am5.color(color),
        fontSize: "14px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500"
      });

      return yAxis;
    };

    const yAxisNPS = createValueAxis(false, colors.purple);
    const yAxisCSAT = createValueAxis(true, colors.purple3);

    // Create Series Function
    const createLineSeries = (
      name: string,
      xAxis: any,
      yAxis: any,
      valueYField: string,
      categoryXField: string,
      data: any[],
      color: any
    ) => {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name,
          xAxis,
          yAxis,
          valueYField,
          categoryXField,
          tooltip: createTooltip(root),
          stroke: am5.color(color),
        })
      );

      series.strokes.template.setAll({ strokeWidth: 2 });
      series.data.setAll(data);

      return series;
    };

    createLineSeries(
      "NPS",
      xAxisNPS,
      yAxisNPS,
      "NPS",
      "quarter",
      npsData,
      colors.purple
    );
    createLineSeries(
      "CSAT",
      xAxisCSAT,
      yAxisCSAT,
      "CSAT",
      "month",
      csatData,
      colors.purple3
    );

    // Add Cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Add Legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p100,
        x: am5.p100,
        centerY: 0,
        y: 0,
        layout: root.horizontalLayout,
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });

    legend.setAll({
      background: am5.RoundedRectangle.new(root, {
        fill: am5.color(colors.projectTotalColor),
        fillOpacity: 1,
        cornerRadiusBL: 2,
        cornerRadiusBR: 2,
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
      }),
    });

    legend.itemContainers.template.setAll({
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: -40,
    });
    legend.data.setAll(chart.series.values);

    legend.labels.template.setAll({
      fontSize: "12px",
      fontFamily: "Roboto",
      fill: am5.color(colors.primary_grey),
    });

    // Customize tooltips
    chart.series.each((series) => {
      series?.get("tooltip")?.label?.setAll({
        ...axisLabelStyle,
        fill: am5.color(colors.white),
      });
    });

    return () => {
      root.dispose();
    };
  }, [chartID, npsData, csatData, axisLabelStyle]);

  return <div id={chartID} style={{ height: height, width: "100%" }}></div>;
};

export default NpsCsatTrendChart;
