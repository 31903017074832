import { ColDef } from "ag-grid-community";
import { getFieldsFromColumn } from "../../ClientDetails/utils/GridOps";
import { SignalsSurveyGridColumns } from "../../../constants/ClientDetails";

export const signalsSurveyGridField = getFieldsFromColumn(SignalsSurveyGridColumns);

export const getSignalsSurveyTableColumnDefs = (
  data: any[] | undefined,
  FeedbackCellRenderer: React.FC<any>
): ColDef<any>[] => {
  if (!data) return [];
  const columnDefs: ColDef<any>[] = [];
  columnDefs.push({
    headerName: SignalsSurveyGridColumns.title,
    field: signalsSurveyGridField.title,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 160,
    suppressMovable: true
  });
  columnDefs.push({
    headerName: SignalsSurveyGridColumns.startDate,
    field: signalsSurveyGridField.startDate,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 120,
    suppressMovable: true
  });

  columnDefs.push({
    headerName: SignalsSurveyGridColumns.status,
    field: signalsSurveyGridField.status,
    headerClass: "first-column-header header-customer",
    cellClass: "sticky-column-detail customer-cell",
    cellRenderer: FeedbackCellRenderer,
    autoHeight: true,
    width: 120,
    suppressMovable: true
  });

  return columnDefs;
};