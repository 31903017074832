import React from "react";
import { literals } from "../../theme/literals";
import { SelectDropdownOption } from "../../page/Admin/utils/globalType";

interface DropdownListProps {
  options: SelectDropdownOption[];
  onChange: (selectedOption: SelectDropdownOption) => void;
  position: { top: number; left: number; width: number };
  value: SelectDropdownOption | null;
  props?: any;
}

const DropdownList: React.FC<DropdownListProps> = ({
  options,
  onChange,
  position,
  value,
  props,
}) => {
  return (
    <div
      className="dropdown-wrapper"
      style={{
        top: `${position.top + (props?.adjustedHeight || 0)}px`,
        left: `${position.left + (props?.adjustedLeft || 0)}px`,
        width: `${position.width + (props?.adjustedWidth || 0)}px`,
        maxHeight: literals.customDropdownHeight - 20,
      }}
    >
      {options.map((option) => {
        const isActive = value?.value === option?.value;
        return (
          <div
            key={option?.value}
            onClick={() => onChange(option)}
            className={`custom-option-wrapper ${props?.overrideCOWClass}`}
          >
            {props?.component ? (
              props.component({
                option,
                value,
                keyToShowValue: props?.keyToShowValue,
              })
            ) : (
              <div
                className={`default-option-content body9 uppercase-text ${
                  isActive ? "active" : ""
                } ${props?.overrideDOCClass}`}
              >
                {option?.label}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DropdownList;
