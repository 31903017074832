import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const CHIQuery = createApi({
  reducerPath: "CHIQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCHI: builder.query({
      query: ({ tenantId, selectedCHILabel, chiFormulaData }) => ({
        url: `/admin/customer-health/tenants/${tenantId}`,
        // url: `/admin/customer-health/tenants/${tenantId}/${selectedCHILabel.tag}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) => response.map(
        item => ({
          ...item,
          customer: {
            name: item.customer,
            id: item.customerId
          }
        })
      ),
    }),
    getCHIWeights: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/tenants/${tenantId}/chi-weights`,
        method: "GET",
      }),
    }),
    getCHILabels: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/tenants/${tenantId}/chi-labels`,
        method: "GET",
      }),
    }),
    addCHI: builder.mutation({
      query: (npsData) => {
        return {
          url: `/admin/customer-health`,
          method: "POST",
          body: getBody(npsData),
        };
      },
    }),
    updateCHI: builder.mutation({
      query: (npsData) => ({
        url: `/admin/customer-health`,
        method: "PUT",
        body: getBody(npsData),
      }),
    }),
    updateCHIWeights: builder.mutation({
      query: (npsCSATData) => ({
        url: `/admin/tenants/${npsCSATData.tenantId}/chi-weights`,
        method: "PUT",
        body: getNpsCSATBody(npsCSATData),
      }),
    }),
    updateCHILabels: builder.mutation({
      query: ({ tenantId, data }) => ({
        url: `/admin/tenants/${tenantId}/chi-labels`,
        method: "PUT",
        body: data,
      }),
    }),
    removeCHI: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/customer-health/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCHIQuery,
  useGetCHIWeightsQuery,
  useGetCHILabelsQuery,
  useAddCHIMutation,
  useUpdateCHIMutation,
  useUpdateCHIWeightsMutation,
  useUpdateCHILabelsMutation,
  useRemoveCHIMutation
} = CHIQuery;

const getBody = (npsData: any) => {
  const body = {
    ...npsData,
    customerId: npsData.customer.id,
    score: parseFloat(npsData.score),
    customer: npsData.customer.name
  };
  return body;
}

const getNpsCSATBody = (npsData: any) => {
  delete npsData.tenantId;
  return npsData;
}