import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ColDef,
  CellValueChangedEvent,
  RowValueChangedEvent,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import "../../styles/component/CustomAgGridCRUD/CustomAgGridCRUD.scss";
import Loader from "../../component/Loader/Loader";
import { colors } from "../../theme/colors";
import Text from "../../component/Text/Text";
import { onCellFocused } from "./gridUtils";
import toast from "react-hot-toast";
import { literals } from "../../theme/literals";
import { useDispatch } from "react-redux";
import { setAgGridQueryData } from "../../page/ClientDetails/slice/AgGridCustomSlice";

export interface GridTableProps {
  props: {
    colDefs: ColDef[];
    addEmptyRow: () => any;
    addDataMutation: any;
    updateDataMutation: any;
    deleteDataMutation: any;
    idColumnField: string;
    gridData: any;
    isLoading: boolean;
    refetch: any;
    isNavigation: boolean;
    Navigation: string;
    autoSizeType?: string;
    payload?: any;
    columnTypes?: any;
    readonly?: boolean;
    callback?: any;
    callbackWithoutParam?: any;
    validation?: any;
    pinnedBottomRowData?: any;
    updateBottomDataMutation?: any;
    refetchBottom?: any;
  };
  ref: any;
}

export interface GridComponentRef {
  addNewItems: () => void;
  deleteItems: () => void;
  searchItems: (param: string) => void;
}

interface NoRowsOverlayProps {
  onButtonClick: () => void;
}

export const SUMMARY_BOTTOM_ROW = "summary-bottom-row";

const CustomAgGridCRUD: React.FC<GridTableProps> = forwardRef<
  GridComponentRef,
  GridTableProps
>(({ props }, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<[]>([]);
  const [addData, { isLoading: addDataLoading }] = props.addDataMutation();
  const [updateData, { isLoading: updateDataLoading }] =
    props.updateDataMutation();

  const [updateBottomData, { isLoading: updateBottomDataLoading }] =
    props.pinnedBottomRowData
      ? props?.updateBottomDataMutation()
      : props.updateDataMutation();

  const [deleteData, { isLoading: deleteDataLoading }] =
    props.deleteDataMutation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isNewDataEntry, setIsNewDataEntry] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    addNewItems,
    deleteItems,
    searchItems,
  }));

  useEffect(() => {
    if (props.gridData) {
      setRowData(props.gridData.map((row: any) => ({ ...row })));
    } else {
      setRowData([]);
    }
    clearError();
  }, [props.gridData]);

  useEffect(() => {
    clearError();
  }, [props.colDefs]);

  if (!props.payload) props.payload = {};
  const clearError = () => setErrorMessage("");

  const handleCellClick = useCallback(
    (params: any) => {
      if (params?.colDef?.field === props.idColumnField && props.isNavigation) {
        navigate(props.Navigation, {
          state: {
            clientData: params.data,
          },
        });
      }
    },
    [navigate]
  );

  const addNewItems = useCallback(() => {
    //to check whether custom metric is added and selected
    if (
      props.payload.hasOwnProperty("previousData") &&
      props.payload.hasOwnProperty("metric") &&
      (props.payload?.metric === null || props.payload?.metric === undefined)
    ) {
      toast.error(literals.customMetricAddFirst);
      return;
    }
    if (
      gridRef
        .current!.api.getRenderedNodes()
        .filter((item) => item.id === "" || item.id === "undefined")?.length > 0
    ) {
      alert("New Record is already added.");
      return;
    }
    const newItems = [props.addEmptyRow()];
    gridRef.current!.api.applyTransaction({
      add: newItems,
      addIndex: 0,
    })!;
    gridRef.current!.api.setGridOption("editType", "fullRow");
    dispatch(setAgGridQueryData({ data: { editType: "fullRow" } }));
    setIsNewDataEntry(true);
    setTimeout(onStartEditing, 0);
  }, [props.colDefs, props.addEmptyRow]);

  const onStartEditing = useCallback(() => {
    var firstEditCol = props.colDefs.filter((element, index) => {
      return element.editable === true;
    })[0];
    var editableField = firstEditCol.field as string;
    gridRef.current!.api.setFocusedCell(0, editableField);
    gridRef.current!.api.startEditingCell({
      rowIndex: 0,
      colKey: editableField,
    });
  }, [props.colDefs]);

  const updateRowItems = async (params: RowValueChangedEvent) => {
    try {
      const dataToAdd = {
        ...params.data,
        ...props.payload,
      };
      if (props.validation) {
        const message = props.validation(dataToAdd);
        if (message) {
          toast.error(message);
          onStartEditing();
          return;
        }
      }
      clearError();
      if (props.callbackWithoutParam) {
        props.callbackWithoutParam();
      }
      gridRef.current!.api.setGridOption("editType", undefined);
      // this is to globally store if complete row is in edit mode or not
      dispatch(setAgGridQueryData({ data: { editType: null } }));
      const response = await addData(dataToAdd)?.unwrap();
      if (props.callback) {
        props.callback(dataToAdd, "add");
      }
      if (response) {
        setIsNewDataEntry(false);
        props.refetch();
        toast.success(literals.dataAddSuccess);
      }
    } catch (error) {
      toast.error(literals.somethingWentWrong);
      const rowNode = gridRef.current!.api.getDisplayedRowAtIndex(0);
      if (rowNode) {
        const rowData = rowNode.data;
        // Use applyTransaction to remove the row
        gridRef.current!.api.applyTransaction({
          remove: [rowData],
        });
      }
      setIsNewDataEntry(false);
      // setErrorMessage("Failed to add data");
    }
  };

  const updateCellItems = useCallback(
    async (event: CellValueChangedEvent) => {
      const originalValue = event?.oldValue;
      try {
        clearError();
        if (props.callbackWithoutParam) {
          props.callbackWithoutParam();
        }
        const idField = event.data[props.idColumnField];
        const isSummaryRow = idField === SUMMARY_BOTTOM_ROW;
        if (idField && !isNewDataEntry) {
          const updatedRow = { ...event.data, ...props.payload };
          if (isSummaryRow) {
            await updateBottomData(updatedRow)?.unwrap();
          } else {
            await updateData(updatedRow)?.unwrap();
          }
          if (props.callback) {
            props.callback(event, "update");
          }
          toast.success(literals.dataUpdateSuccess);
          if (isSummaryRow) {
            setTimeout(() => {
              props.refetchBottom();
            }, 1000);
          }
        }
      } catch (error) {
        toast.error(literals.somethingWentWrong);
        if (event.colDef.field) {
          gridRef.current!.api.applyTransaction({
            update: [{ ...event.data, [event.colDef.field]: originalValue }],
          });
        }
        // setErrorMessage("Failed to update data");
      }
    },
    [
      updateData,
      updateBottomData,
      isNewDataEntry,
      props.idColumnField,
      props.payload,
    ]
  );

  const deleteItems = async () => {
    try {
      clearError();
      if (props.callbackWithoutParam) {
        props.callbackWithoutParam();
      }
      var selectedRowData: [] = gridRef.current?.api.getSelectedRows() as [];
      selectedRowData.forEach(async (element) => {
        let id = element[props.idColumnField];
        await deleteData({ id, ...props.payload }).unwrap();
      });
      toast.success(literals.dataDeleteSuccess);
      gridRef.current?.api.applyTransaction({ remove: selectedRowData });
      if (props.callback) {
        let allRowsData: any = [];
        gridRef.current?.api.forEachNode((node) => {
          allRowsData.push(structuredClone(node.data));
        });
        props.callback(allRowsData, "delete");
      }
    } catch (error) {
      toast.error(literals.somethingWentWrong);
      // setErrorMessage("Failed to delete data");
    }
  };

  const searchItems = useCallback((searchValue: string) => {
    gridRef.current!.api?.setGridOption("quickFilterText", searchValue);
  }, []);

  const CustomNoRowsOverlay: React.FC<NoRowsOverlayProps> = () => {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <p>Nothing to show</p>
        {!props.readonly && (
          <button onClick={addNewItems} className="grid-overlay-btn">
            + Add New
          </button>
        )}
      </div>
    );
  };

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    if (props.autoSizeType === "fitGridWidth") {
      return { type: "fitGridWidth" };
    }
    return { type: "fitCellContents" };
  }, []);

  return (
    <div className="table-details-container">
      <Text
        text={errorMessage}
        styleName="caption2"
        color={colors.red}
        onClick={() => { }}
      />
      <Loader
        isLoading={
          props.isLoading ||
          addDataLoading ||
          updateDataLoading ||
          updateBottomDataLoading ||
          deleteDataLoading
        }
      />
      <div className="ag-theme-alpine data-table">
        <AgGridReact
          ref={gridRef}
          rowSelection="multiple"
          columnDefs={props.colDefs}
          rowData={rowData}
          onCellClicked={handleCellClick}
          onRowValueChanged={updateRowItems}
          onCellValueChanged={updateCellItems}
          getRowId={(item) => item.data[props.idColumnField]}
          reactiveCustomComponents={true}
          noRowsOverlayComponent={
            props.isLoading ? () => <span></span> : CustomNoRowsOverlay
          }
          autoSizeStrategy={autoSizeStrategy}
          columnTypes={props.columnTypes}
          onCellFocused={onCellFocused}
          suppressRowTransform={true}
          domLayout="normal"
          popupParent={document.body}
          pinnedBottomRowData={
            (rowData?.length > 0 && props?.pinnedBottomRowData) || []
          }
        />
      </div>
    </div>
  );
});

export default React.memo(CustomAgGridCRUD);
