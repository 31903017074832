import { months } from "../../../constants/Signals.constants";
import { numberUpto2Decimal } from "../../Home/utils/executionProjectUtil";
import { QuarterData } from "../SignalsQuery.type";

export const getFormattedCsatTrend = (data?: QuarterData[]) => {
  if (!data) return [];

  const monthlyData = [...data];

  const transformedData = monthlyData.reverse().map((item: QuarterData) => ({
    value: numberUpto2Decimal(item.score),
    category: `${months[item.month - 1]} \n${item.year}`,
  }));

  return transformedData;
};

export const getFormattedCsatTrendRelational = (data?: QuarterData[]) => {
  if (!data) return [];

  const monthlyData = [...data];

  const transformedData = monthlyData.reverse().map((item: QuarterData) => ({
    CSAT: numberUpto2Decimal(item.score),
    month: `${months[item.month - 1]} ${item.year}`,
  }));

  return transformedData;
};
