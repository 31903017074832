import React from "react";
import "../../styles/page/Home/SignalsDashboard.scss";
import "../../sass/typography.scss";
import { literals } from "../../theme/literals";
import Loader from "../../component/Loader/Loader";
import { useGetRelationalQuery, useGetTouchPointsQuery } from "./SignalsQuery";
import Text from "../../component/Text/Text";
import { colors } from "../../theme/colors";
import { useGetDetractorQuery } from "../InsightAnalysis/DetractorQuery";
import { TableHeader } from "../../component/TableHeader/TableHeader";
import WordBubbleChart from "../../component/ForcedDirectedChart/WordBubbleChart";
import WordChart from "../../component/WordChart/WordChart";
import { getFilterQueryDate } from "../InsightAnalysis/utils/ImpactAnalysisTableGrid";
import {
  FilterDurationEnum,
  InsightAnalysisFilterDuration,
} from "../InsightAnalysis/InsightAnalysis.type";
import SignalsSurveyDetailsTable from "./SignalsSurveyDetailsTable";
import SpeedoMeterChart, { SpeedoMeterChartProps } from "../../component/SpeedoMeterChart/SpeedoMeterChart";
import { InsightStackedBarChart } from "../Version2/Home/InsightColumn/InsightStackedBarChart";
import EmptyStateComp from "../Version2/Home/EmptyStateComp";
import { getFormattedSurveyResponseData } from "./utils/getFormattedSurveyResponseData";

const SignalsDashboard = () => {
  const { startDate, endDate } = getFilterQueryDate(InsightAnalysisFilterDuration[FilterDurationEnum.ONE_YEAR]);
  const { data: touchPointsData, isLoading: isTouchPointsLoading } = useGetTouchPointsQuery({ startDate, endDate });
  const { data: surveysData, isLoading: isSurveyLoading } = useGetRelationalQuery();
  const { data: detractorData, isLoading: isDetractorLoading } = useGetDetractorQuery({ startDate, endDate });
  const surveyResponseData = getFormattedSurveyResponseData(surveysData?.surveyStats)

  const SpeedoMeterItem: React.FC<SpeedoMeterChartProps> = ({ chartID, title, signalTouchPointItem }) => {
    return <div className="touchPoint-row">
      <Text text={title || ""} styleName="Heading6" />
      {signalTouchPointItem && <>
        <SpeedoMeterChart chartID={chartID} signalTouchPointItem={signalTouchPointItem} />
        <Text text={signalTouchPointItem?.metricValue || ""} styleName="Heading2 align-self-center" color={colors.black} />
      </>}
    </div>
  }

  const SurveyDataBlock: React.FC<any> = () => {
    return (
      <div className="survey-block">
        {surveysData?.relational?.surveys && surveysData?.relational?.surveys?.length > 0 ? <SignalsSurveyDetailsTable signalsSurveyData={surveysData?.relational?.surveys || []} /> : <EmptyStateComp containerMinHeight="150px" imgHeight="60px" imgWidth="80px" />}
      </div>
    );
  };

  const ResponsesDataBlock: React.FC<any> = ({ data }) => {
    return (
      <div className="response-block">
        {!surveysData?.surveyStats ? <EmptyStateComp containerMinHeight="150px" imgHeight="60px" imgWidth="80px" /> : <InsightStackedBarChart data={surveyResponseData} totalInsights={surveyResponseData[0]?.value} />}
      </div>
    );
  };

  return (
    <>
      <Loader isLoading={isTouchPointsLoading || isSurveyLoading || isDetractorLoading} />
      {!(isTouchPointsLoading || isSurveyLoading || isDetractorLoading) &&
        <div className="signals-detailed-container">
          <Text
            styleName="Page-Heading"
            text={literals.signalsReport}
            color={colors.black_gray} />

          <div className="signal-details">
            <div className="touchPoints-details">
              <Text
                text={literals.touchPoints}
                styleName="Section-Heading uppercase-text titleHeader"
                color={colors.black_gray} />

              <div className="touchPoints-details-sub">
                <SpeedoMeterItem chartID="speed-meter-relational" title={literals.relational} signalTouchPointItem={touchPointsData?.signals?.relationalMetric} />
                <SpeedoMeterItem chartID="speed-meter-transactional" title={literals.transactional} signalTouchPointItem={touchPointsData?.signals?.transactionalMetric} />
              </div>
              <div className="touchPoints-details-sub">
                <SpeedoMeterItem chartID="speed-meter-digital" title={literals.digital} signalTouchPointItem={touchPointsData?.signals?.digitalMetric} />
                <SpeedoMeterItem chartID="speed-meter-content" title={literals.content} signalTouchPointItem={touchPointsData?.signals?.contentMetric} />
              </div>

              <div className="survey-responses-details">
                <div className="survey-responses-details-sub">
                  <Text
                    text={literals.surveys}
                    styleName="Section-Heading uppercase-text titleHeader"
                    color={colors.black_gray} />
                  <SurveyDataBlock />
                </div>
                <div className="survey-responses-details-sub">
                  <Text
                    text={literals.responses}
                    styleName="Section-Heading uppercase-text titleHeader"
                    color={colors.black_gray} />
                  <ResponsesDataBlock />
                </div>
              </div>
            </div>

            <div className="feedback-details">
              <Text
                text={literals.feedback}
                styleName="Section-Heading uppercase-text titleHeader"
                color={colors.black_gray} />
              <div className="table-feedback">
                <div className="signal-detractor-wrapper">
                  <div className="table-col">
                    <TableHeader title={literals.detractorTopics}>
                      <WordBubbleChart
                        chartID="forced-directed-bubble-chart-signals"
                        data={detractorData?.detractorTopics} />
                    </TableHeader>
                  </div>
                </div>
                <div className="signal-detractor-wrapper">
                  <div className="table-col">
                    <TableHeader title={literals.detractorCommonWords}>
                      <WordChart data={detractorData?.detractorCommonWords} />
                    </TableHeader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SignalsDashboard;
