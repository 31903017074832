import React, { useEffect } from 'react';
import '../../styles/feature/Layout/Layout.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: any
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { bgColor } = useSelector((state: RootState) => state.layout);
  const { token } = useSelector((state: RootState) => state?.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  return (
    <div className='layout-container' style={{ backgroundColor: bgColor }}>
      {children}
    </div>
  )
}

export default Layout