import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";

interface LineChartProps {
  chartID: string;
  data: any;
  chartHeight?: string;
  chartPadding?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}

function LineChart(props: LineChartProps) {
  const { chartID, data, chartHeight, chartPadding } = props;

  useLayoutEffect(() => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    let root = am5.Root.new(chartID, {
      tooltipContainerBounds: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      },
    });

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: false,
        paddingLeft: chartPadding?.left || 0,
        paddingRight: chartPadding?.right || 10,
        paddingTop: chartPadding?.top || 0,
        paddingBottom: chartPadding?.bottom || 15,
      })
    );

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", true);

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 2,
      minorGridEnabled: false,
      pan: "zoom",
    });
    xRenderer.labels.template.set("visible", false);
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.5,
        baseInterval: {
          timeUnit: "month",
          count: 1,
        },
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    xAxis.get("renderer").grid.template.set("forceHidden", true);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      pan: "zoom",
    });
    yRenderer.labels.template.set("visible", false);
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: yRenderer,
      })
    );
    yAxis.get("renderer").grid.template.set("forceHidden", true);

    let series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "month",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
        stroke: am5.color(0x3185fc),
        fill: am5.color(0xbadaff),
      })
    );

    series.fills.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [{ color: am5.color(0x3185fc) }, { color: am5.color(0xbadaff) }],
        rotation: 90,
      })
    );

    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.2,
    });

    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartID, data, chartPadding]);

  return (
    <div
      id={chartID}
      style={{ width: "100%", height: chartHeight || "60px" }}
    ></div>
  );
}

export default LineChart;
