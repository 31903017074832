import { QuarterData } from "../SignalsQuery.type";

export const getFormattedCsat = (data?: QuarterData[]) => {
  if (!data) return [];

  const monthlyData = [...data];

  const transformedData = monthlyData.reverse().map((item: QuarterData) => ({
    month: new Date(item.year, item.month - 1).getTime(),
    value: item.score,
  }));

  return transformedData;
};

export const getTrendDown = (data?: QuarterData[] | any[]) => {
  if (!data || data?.length === 0) return false;
  if (data?.length === 1) return false;
  return data[0]?.score < data[1]?.score;
};

export const canShowTrend = (data?: QuarterData[] | any[]) => {
  if ((data?.length ?? 0) > 0) return true;
  return false;
};
