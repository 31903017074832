import React from 'react';
import '../../styles/component/Input/Input.scss'
import { InputProps } from './Input.type';

const Input: React.FC<InputProps> = ({
  type,
  placeholder,
  value,
  onChange,
  customStyle,
  customInputClass = '',
  required,
  autoComplete = 'on',
  disabled
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={"common-input " + customInputClass}
      required={required}
      style={customStyle}
      autoComplete={autoComplete}
      disabled={disabled}
    />
  );
};

export default Input;
