import { InsightStatusEnumType } from "../Ranking/ProjectInsights.type";

export interface InsightImpactAnalysisType {
  title: string;
  description: string;
  frictionDescription: string;
  promoted: string;
  status: InsightStatusEnumType;
  date: string;
  rank: number;
  id?: string;
  overallScore: number;
  newlyDiscovered: boolean;
  customerCentricScore: number;
  businessValue: number;
  implementationEase: number;
  label: string;
}

export enum FilterDurationEnum {
  THIRTY_DAYS = "THIRTY_DAYS",
  SIX_MONTHS = "SIX_MONTHS",
  ONE_YEAR = "ONE_YEAR",
}
export const InsightAnalysisFilterDuration = {
  [FilterDurationEnum.THIRTY_DAYS]: { label: "30 Days", value: "30days" },
  [FilterDurationEnum.SIX_MONTHS]: { label: "6 Months", value: "6month" },
  [FilterDurationEnum.ONE_YEAR]: { label: "1 Year", value: "1year" },
};

export interface InsightAnalysisFilter {
  type: "checkbox" | "radio";
  className: string;
  option: (typeof InsightAnalysisFilterDuration)[FilterDurationEnum];
}
export interface DispositionStatus {
  pending: boolean;
  completed: boolean;
  delayed: boolean;
  inflight: boolean;
}
