import { numberUpto2Decimal } from "../../Home/utils/executionProjectUtil";
import { NpsQuarterData } from "../services/Signals/Signals.type";

export const getFormattedNpsTrend = (data?: NpsQuarterData[]) => {
  if (!data) return [];

  const monthlyData = [...data];

  const transformedData = monthlyData.reverse().map((item: NpsQuarterData) => ({
    value: item.score,
    category: `Q${item.quarter} ${item.year}`,
  }));

  return transformedData;
};

export const getFormattedNpsRelational = (data?: NpsQuarterData[]) => {
  if (!data) return [];

  const monthlyData = [...data];

  const transformedData = monthlyData.reverse().map((item: NpsQuarterData) => ({
    NPS: item.score,
    quarter: `Q${item.quarter} ${item.year}`,
  }));

  return transformedData;
};

export const getFormattedNpsData = (data?: NpsQuarterData[]) => {
  if (!data) return [];

  const monthlyData = [...data];

  const transformedData = monthlyData.reverse().map((item: NpsQuarterData) => ({
    value: numberUpto2Decimal(item.score),
    quarter: `Q${item.quarter} ${item.year}`,
  }));

  return transformedData;
};
