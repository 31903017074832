import React from "react";
import { useDispatch } from "react-redux";
import { clearToken } from "../../page/Login/authSlice";
import { clearConfig } from "../Header/ConfigSlice";
import { literals } from "../../theme/literals";
import { headerHeight, profilePic } from "../../page/Admin/utils/globalUtil";
import MenuIcon from "../../assets/images/menu-open.svg";
import VistaXMLogoBlue from "../../assets/images/VistaXM_Logo_Blue.svg";
import { miscellaneousTenantMenu } from "../../AppRoute/constants";
import { HeaderMenuItem } from "./HeaderMenuItem";

interface DashboardHeaderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(clearToken());
    dispatch(clearConfig());
    dispatch({ type: "RESET" });
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className="dashboard-header" style={{ height: `${headerHeight}px` }}>
      <div className="left-div">
        <img
          className="menu-toggler pointer"
          src={MenuIcon}
          alt="menu-icon"
          onClick={() => setCollapsed(!collapsed)}
        />
        <img className="org-logo" src={VistaXMLogoBlue} alt="logo" />
      </div>
      <div className="right-div">
        <div className="miscellaneous-menu-div">
          {miscellaneousTenantMenu.map((item) => (
            <HeaderMenuItem key={item.label} item={item} />
          ))}
        </div>
        <div className="logout-div">
          <img src={profilePic} alt="logout" />
          <div className="dropdown-content pointer" onClick={logout}>
            {literals.logout}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
