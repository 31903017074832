import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";
export const TenantCsatQuery = createApi({
  reducerPath: "TenantCsatQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getCSAT: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/csat/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (response: any[]) =>
        response.map((item) => {
          const { score, ...restItem } = item;
          return {
            ...restItem,
            customer: {
              name: item.customer,
              id: item.customerId,
            },
            touchpointObj: {
              touchpoint: item.touchpoint,
              subTouchPoints: item?.subTouchPoints,
              score: score,
            },
          };
        }),
    }),
    addCSAT: builder.mutation({
      query: (csatData) => {
        return {
          url: `/admin/csat`,
          method: "POST",
          body: getCsatBody(csatData),
        };
      },
    }),
    updateCSAT: builder.mutation({
      query: (csatData) => ({
        url: `/admin/csat/${csatData.id}`,
        method: "PUT",
        body: getCsatBody(csatData),
      }),
    }),
    removeCSAT: builder.mutation({
      query: ({ id }) => ({
        url: `/admin/csat/${id}`,
        method: "DELETE",
      }),
    }),
    getCSATTouchAndSubTouchpoints: builder.query({
      query: ({ tenantId, customerId }) => ({
        url: `/admin/csat/tenants/${tenantId}/customers/${customerId}`,
        method: "GET",
      }),
      transformResponse: (response: any) => response,
    }),
  }),
});

export const {
  useGetCSATQuery,
  useAddCSATMutation,
  useUpdateCSATMutation,
  useRemoveCSATMutation,
  useLazyGetCSATTouchAndSubTouchpointsQuery,
} = TenantCsatQuery;

const getCsatBody = (csatData: any) => {
  const { touchpointObj, ...restCsatData } = csatData;
  const body = {
    ...restCsatData,
    customerId: csatData.customer.id,
    score: parseFloat(touchpointObj.score) || null,
    touchpoint: touchpointObj?.touchpoint || null,
    subTouchPoints: touchpointObj?.subTouchPoints || null,
    customer: csatData.customer.name,
  };
  return body;
};
