import Text from "../Text/Text"
import { colors } from "../../theme/colors"
import '../TableHeader/TableHeader.scss';

interface TableHeaderProps {
    title: string,
    children: any
}

export const TableHeader: React.FC<TableHeaderProps> = ({
    title,
    children
}) => {

    return <div className="tableHeader">
        <div className="titleView">
            <Text
                text={title}
                styleName="title"
                color={colors.Light_Blue_PRI} />
        </div>

        <div className="chart-container">
            {/* Chart Here */}
            {children}
        </div>
    </div>
}