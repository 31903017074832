import { colors } from "../../../theme/colors";
import { literals } from "../../../theme/literals";
import { QuarterSurvey } from "../SignalsQuery.type";

export const getRelationalSurveyChartData = (
  quarterSurvey: QuarterSurvey[] | undefined
) => {
  let surveyReturned = 0;
  let surveyNotReturned = 0;

  quarterSurvey?.forEach((survey) => {
    surveyReturned += survey.returned;
    surveyNotReturned += survey.sent - survey.returned;
  });

  return [
    {
      category: literals.returned,
      value: surveyReturned,
      colorClass: literals.loyal,
      color: colors.blueTwo,
    },
    {
      category: literals.notReturned,
      value: surveyNotReturned,
      colorClass: literals.neutral,
      color: colors.blueThree,
    },
  ];
};

export const getResponseRate = (
  quarterSurvey: QuarterSurvey[] | undefined
) => {
  let surveySent = 0;
  let surveyReturned = 0;

  quarterSurvey?.forEach((survey) => {
    surveySent += survey.sent;
    surveyReturned += survey.returned;
  });

  return surveySent > 0 ? ((surveyReturned / surveySent) * 100).toFixed(2) : 0;
};
