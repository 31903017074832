import { colors } from "../../theme/colors";
import Text from "../Text/Text";

export const InBodyItemRenderer = (props: any) => {
  const {
    data,
    isSelected,
    innerRef,
    innerProps,
    inputType,
    textColor,
    styleName,
  } = props;
  return (
    <div className="list-item-wrapper">
      {data.value === 'addNew'
        ? <div className="list-item-child-add-new" ref={innerRef} {...innerProps}>
          {data.customComponent}
        </div>
        : <div className="list-item-child-wrapper" ref={innerRef} {...innerProps}>
          {inputType && (
            <input
              type={inputType}
              name="custom-input-type"
              checked={isSelected}
              className={`select-input-tye pointer ${isSelected ? "active" : ""}`}
              onChange={() => { }} // Prevent React warning for uncontrolled input
            />
          )}
          <Text
            text={data.label}
            styleName={styleName}
            color={textColor || colors.grayTwo}
          />
        </div>
      }
    </div>
  );
};
