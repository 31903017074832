import { colors } from "../../../theme/colors";

export const ExecutionProjectColors = {
  pctComplete: colors.pctCompleteColor,
  total: colors.projectTotalColor,
};
export const getProjectProgressData = (item: any, index: number) => {
  return {
    pctComplete: item.pctComplete,
    total: 100 - item.pctComplete,
    yAxis: index + 1,
  };
};

export const numberUpto2Decimal = (value: any, percent = false, length = 2) => {
  // Remove % symbol and convert to a number
  let num = parseFloat(value);
  // Check if it's a valid number
  if (isNaN(num)) return value; // Return original if not a valid number

  const formatted = num % 1 === 0 ? num : num.toFixed(length);
  return percent ? `${formatted}%` : Number(formatted);
};
