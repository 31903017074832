import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const InterceptResponseRatesChart = ({chartID, data}: any) => {

  useLayoutEffect(() => {

    am5.array.each(am5.registry.rootElements, function(root) {
      if (root.dom.id === chartID) {
        root.dispose();
      }
    });

    let root = am5.Root.new(chartID);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        paddingLeft: 0,
        paddingRight: 0,
      })
    );

    am5xy.AxisLabel.new(root, {
      // ... config if applicable
    });

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );
    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.adapters.add('text',(text) => text + '%')
    yRenderer.labels.template.setAll({
      fontSize: "12px",
      fontWeight: "400",
      fill: am5.color(0x666666),
      maxWidth: 70,
      textAlign: "center",
      centerX: am5.p50,
      centerY: am5.p50,
      fontFamily: "Roboto",
    });

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "category",
        startLocation: 0,
        endLocation: 1,
      }),
    );
    xAxis.data.setAll(data);
    let xRenderer = xAxis.get("renderer");
    xRenderer.grid.template.set('stroke', am5.color(0xFFFFFF));

    xRenderer.labels.template.setAll({
      fontSize: "12px",
      fontWeight: "400",
      fill: am5.color(0x666666),
      maxWidth: 70,
      textAlign: "center",
      centerX: am5.p50,
      centerY: am5.p50,
      fontFamily: "Roboto",
      paddingTop: 10,
    });

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        tooltip: am5.Tooltip.new(root, {
          labelText: '{category}: {value}%',
        }),
      })
    );
    series.data.setAll(data);

    series.columns.template.set('fill', am5.color('#D5E6FE'))
    series.columns.template.set('stroke', am5.color('#D5E6FE'))

    series.columns.template.setAll({
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      width: am5.percent(15),
    })

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {}));

    chart.series.each((series) => {
      series?.get("tooltip")?.label?.setAll({
        fontSize: "12px",
        fontFamily: "Roboto",
        fill: am5.color(0x000000),
      });
    });
   
    return () => {
      root.dispose();
    };
  }, [chartID]);
  
    return (
      <div id={chartID} style={{ width: "auto", height: "226px" }}></div>
    );
  
}

export default InterceptResponseRatesChart