export const colors = {
  darkBlue: "#022550",
  Purple_Graph2: "#C5C4FF",
  LightBlue: "#D5E6FE",
  td_blue_dark: "#3D89DF",
  td_green_dark: "#40B869",
  td_green_dark_background: " #2C9D100A",
  td_orange_dark: "#F77A4A",
  td_red_dark: "#DD524D",
  td_red_dark_background: "#FF4B4B14",
  td_yellow_dark_background: " #F5B54614",
  white: "#FFF",
  black: "#000",
  black_gray: "#343434",
  grayOne: "#888",
  grayTwo: "#666",
  blackHeading: "#343434",
  grayThree: "#EEEEEE",
  grayFour: "#A2A2A2",
  grayFive: "#4A4A4A",
  lightGrayOne: "#ddd",
  whiteOne: "#f5efef",
  blueOne: "#176DE8",
  blueTwo: "#0561D1",
  blueThree: "#D5E6FE",
  Light_Blue_PRI: "#3185FC",
  footerText: "#999",
  success: "#3CC326",
  graySix: "#ABABAB",
  grayNine: "#9B9B9B",
  lightGray2: "#EAECF0",
  backgroundGray: "#E0E0E0",
  background: "#F2F3F6",
  pendingLabelColor: "#3185FC",
  delayedLabelColor: "#EB7B50",
  cancelledLabelColor: "#FF4B4B",
  completedLabelColor: "#2C9D10",
  pendingBGColor: "#F9E5C2",
  notStartedBGColor: "#f77a4a52",
  delayedBGColor: "#ff923270",
  cancelledBGColor: "#E2E0E0",
  keyRiskBGColor: "#f77a4a52",
  keyIssueBGColor: "#f5b54652",
  lowIssueBGColor: "#3185fc24",
  mediumIssueBGColor: "#3185fc5e",
  highIssueBGColor: "#3185fcc2",
  pctCompleteColor: 0xb2b5ff,
  projectTotalColor: 0xf1f5f9,
  td_orange_dark1: 0xf5b546,
  td_blue_dark1: 0x3d89df,
  hexWhite: 0xffffff,
  hexBlack: 0x000000,
  hexBlack1: 0x666666,
  td_purple_light1: 0xb2b5ff,
  td_blue_dark2: 0x5b60c9,
  td_blue_dark3: 0x83b6fd,
  td_blue_dark4: 0x0561d1,
  td_green_light: 0xd8f3d2,
  td_sky_blue1: 0xd5e6fe,
  keyInsightsColor: "#C5C7FF",
  inFlightInsightsColor: "#C9ECB2",
  speedoMeterIndicatorColor: "#D1D3D7",

  //new colors as per the new design
  primary_grey: "#222",
  secondary_grey: "#666",
  td_yellow_dark: "#F5B546",
  Green: "#5EBA47",
  red: "#FF4B4B",
  darkRed: "#E10040",
  tertiaryGreen: "#D8F3D2",
  purple: "#B2B5FF",
  darkPurple: "#8A8FFF",
  purple3: "#5B60C9",
  tertiaryGrey: "#D9D9D9",
};
