import { createApi } from "@reduxjs/toolkit/query/react";
import { authorizedBaseQuery } from "../../../api/restBaseQuery";

export const ExecutionProjectIssueQuery = createApi({
  reducerPath: "ExecutionProjectIssueQuery",
  baseQuery: authorizedBaseQuery(),
  endpoints: (builder) => ({
    getExecutionProjectIssue: builder.query({
      query: ({ tenantId }) => ({
        url: `/admin/project-issues/tenants/${tenantId}`,
        method: "GET",
      }),
      transformResponse: (
        response: any[]
      ): { resData: any[]; uniqueTags: any; uniqueAssignees: any } => {
        const resData: any[] = [];
        const uniqueTags = new Set();
        const uniqueAssignees = new Set();
        response.forEach((item) => {
          resData.push({
            ...item,
            project: item.projectName
              ? {
                  name: item.projectName,
                  id: item.projectId,
                }
              : null,
          });
          if (Array.isArray(item.tagNames)) {
            item.tagNames.forEach((tagName: string) => uniqueTags.add(tagName));
          }
          if (Array.isArray(item.assignees)) {
            item.assignees.forEach((assignee: string) =>
              uniqueAssignees.add(assignee)
            );
          }
        });

        return {
          resData,
          uniqueTags: Array.from(uniqueTags),
          uniqueAssignees: Array.from(uniqueAssignees),
        };
      },
    }),
    addExecutionProjectIssue: builder.mutation({
      query: (projectIssueData) => {
        return {
          url: `/admin/project-issues/tenants/${projectIssueData.tenantId}`,
          method: "POST",
          body: getBody(projectIssueData),
        };
      },
    }),
    updateExecutionProjectIssue: builder.mutation({
      query: (projectIssueData) => ({
        url: `/admin/project-issues/tenants/${projectIssueData.tenantId}/${projectIssueData.id}`,
        method: "PUT",
        body: getBody(projectIssueData),
      }),
    }),
    removeExecutionProjectIssue: builder.mutation({
      query: ({ tenantId, id: projectIssueId }) => ({
        url: `/admin/project-issues/tenants/${tenantId}/${projectIssueId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetExecutionProjectIssueQuery,
  useAddExecutionProjectIssueMutation,
  useUpdateExecutionProjectIssueMutation,
  useRemoveExecutionProjectIssueMutation,
} = ExecutionProjectIssueQuery;

const getBody = (bodyData: any) => {
  const { project, projectName, tenantId, ...restData } = bodyData;
  const body = {
    ...restData,
    projectId: project?.id,
  };
  if (!body.projectId) {
    delete body.projectId;
  }
  return body;
};
